<template>
    <div style="float: left; width: 100%">      
      <team-list :projectDtl="projectDtl"></team-list>
    </div>
  </template>
  
  <script>
  import TeamList from "../projectsummary/TeamList.vue";

  
  export default {
    name: "team",
    components: {
      TeamList,
   
    },
    props: {
      projectDtl: {
        // type: Object,
        required: true,
        default: null,
      },
    },
    data() {
      return {
        isshow: true,
        edit: false,
      };
    },
    mounted() {},
    methods: {
      editMethod() {
        this.isshow = false;
      },
    },
  };
  </script>
  
  <style>
  </style>