<template>
  <div>
    <validation-observer ref="defectObserver">
      <div v-if="!loader">
        <div>
          <!--filter start-->
          <div class="card-filter-section py-1">
            <div class="d-flex align-items-center flex-wrap justify-content-between">
              <div class="d-flex align-items-center">
                <button
                  class="back-button btn btn-secondary mr-2"
                  @click="back()"
                >
                  <feather-icon
                    icon="ArrowLeftIcon"
                    width="18"
                    height="18"
                  />
                  <span>Back</span>
                </button>
                <p class="filter-title ml-50">
                  {{
                    actionTypeCheck
                      ? "Details"
                      : $store.state.app.testActiveName + " Test Run"
                  }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="test-form">
          <b-form @submit.prevent>
            <b-row>
              <!-- first name -->
              <b-col cols="12">
                <b-form-group
                  label="Run Title"
                  label-for="v-run-title"
                  class="required-form-filed"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Run Title"
                    rules="required|max:256"
                  >
                    <b-form-input
                      v-if="!actionTypeCheck"
                      id="v-run-title"
                      v-model="$store.state.app.testRun.run_title"
                      type="text"
                      placeholder="Run Title"
                      :disabled="actionTypeCheck"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  <p v-if="actionTypeCheck">
                    {{ $store.state.app.testRun.run_title }}
                  </p>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <div>
                  <label for="textarea-default">Description</label>
                  <b-form-textarea
                    v-if="!actionTypeCheck"
                    id="textarea-default"
                    v-model="$store.state.app.testRun.description"
                    placeholder="Add Description"
                    :disabled="actionTypeCheck"
                  />
                  <p v-if="actionTypeCheck">
                    {{ $store.state.app.testRun.description }}
                  </p>
                </div>
              </b-col>
            </b-row>
            <b-row class="mt-1">
              <b-col
                cols="6"
                class="modal-select"
              >
                <b-form-group
                  label="Plan"
                  label-for="Plan"
                  class="required-form-filed"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Plan"
                    rules="required"
                  >
                    <v-select
                      v-if="!actionTypeCheck"
                      id="Plan"
                      v-model="$store.state.app.testRun.plan_id"
                      :options="$store.state.app.testPlan"
                      label="label"
                      class="select-size-lg"
                      placeholder="Select Plan"
                      name="Plan"
                      :reduce="(testPlan) => testPlan.id"
                      :disabled="actionTypeCheck || $store.state.app.testRun.id
                        ? true
                        : false
                      "
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  <p v-if="actionTypeCheck">
                    {{ $store.state.app.testRun.plan_name }}
                  </p>
                </b-form-group>
              </b-col>
              <b-col
                cols="6"
                class="modal-select"
              >
                <b-form-group
                  label="Environment"
                  label-for="Environment"
                  class="required-form-filed"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Environment"
                    rules="required"
                  >
                    <v-select
                      v-if="!actionTypeCheck"
                      id="Environment"
                      v-model="$store.state.app.testRun.env"
                      :options="envs"
                      label="label"
                      class="select-size-lg"
                      name="Environment"
                      placeholder="Select Environment"
                      :reduce="(env) => env.value"
                      :disabled="actionTypeCheck"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  <p v-if="actionTypeCheck">
                    {{ $store.state.app.testRun.env }}
                  </p>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
          <div
            v-if="$store.state.app.testActiveName != 'View'"
            class="test-plan mt-1"
          >
            <div class="my-2">
              <b-button
                type="submit"
                class="mr-1 save-btn"
                :disabled="test_plan_loader || loader"
                @click="validationForm()"
              >
                <b-spinner
                  v-if="test_plan_loader || loader"
                  small
                  class="spinner"
                />
                {{
                  $store.state.app.testActiveName &&
                    $store.state.app.testActiveName == "Edit"
                    ? "Update"
                    : $store.state.app.testActiveName
                }}
                Run</b-button>
              <b-button
                class="cancelBtn mr-1"
                type="reset"
                :disabled="test_plan_loader || loader"
                @click="back()"
              >
                cancel
              </b-button>
            </div>
          </div>
        </div>
      </div>
      <TestPlanLoader v-else />
    </validation-observer>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'
import TestPlanLoader from './Loader/TestPlanLoader.vue'

export default {
  name: 'StartTestRun',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    flatPickr,
    ValidationObserver,
    ValidationProvider,
    TestPlanLoader,
  },
  data() {
    return {
      task_status: null,
      test_plan_loader: false,
      loader: false,
      envs: [
        { label: 'Live', value: 'live' },
        { label: 'Local', value: 'local' },
        { label: 'Staging', value: 'staging' },
        { label: 'Testing', value: 'testing' },
      ],
    }
  },
  computed: {
    actionTypeCheck() {
      if (this.$store.state.app.testActiveName == 'View') {
        return true
      }
      return false
    },
  },
  mounted() {
    this.clear('mouted')
    this.testPlanList()
    const { app } = this.$store.state
    const {
      id4, id5, id6, id7,
    } = this.$route.params
    if (id4 == 'test-plan-run') {
      this.$store.state.app.testRun.plan_id = id7
    }
    app.testActiveName = app.testActiveName || id5
    if (app.testActiveName) {
      app.testActiveName = app.testActiveName[0].toUpperCase() + app.testActiveName.slice(1)
    }

    if (id5 == 'edit' || id5 == 'view') {
      this.getTestRun(id6)
    }
  },
  destroyed() {
    this.clear()
  },
  methods: {
    /** back */
    back() {
      if (this.$route.params.id4 == 'test-plan-run') {
        this.$store.state.app.pageShow = 'test-plan'
        this.$router.push({
          name: 'projectTask',
          params: {
            id: this.$route.params.id,
            id2: this.$route.params.id2,
            id3: this.$route.params.id3,
            id4: 'test-plan',
            id5: null,
            id6: null,

          },
          query: this.$route.query,
        }).catch(err => {
          // Ignore the NavigationDuplicated error
          if (err.name !== 'NavigationDuplicated') {
            console.log(err)
          }
        })
      } else {
        this.$store.state.app.pageShow = 'test-run'
        this.$router.push({
          name: 'projectTask',
          params: {
            id: this.$route.params.id,
            id2: this.$route.params.id2,
            id3: this.$route.params.id3,
            id4: 'test-run',
            id5: null,
            id6: null,

          },
          query: this.$route.query,
        }).catch(err => {
          // Ignore the NavigationDuplicated error
          if (err.name !== 'NavigationDuplicated') {
            console.log(err)
          }
        })
      }
    },
    async getTestRun(id) {
      this.loader = true
      const response = await this.getHTTPGetResponse(
        `project/test-run/view/${id}`,
        null,
        false,
      )
      if (response && response.status == 200) {
        const data = response.data.testRun
        const test_run = {
          plan_id: data.plan_id,
          env: data.environment,
          run_title: data.run_title,
          description: data.description,
          id: data.id,
          plan_name: data.test_plan.title,
        }
        this.$store.state.app.testRun = test_run
      }
      this.loader = false
    },
    async testPlanList() {
      this.test_plan_loader = true
      const input = {
        project_id: this.$route.params.id3,
        page: this.currentPage,
        per_page: this.perPage,
        search: this.search,
      }
      const response = await this.getHTTPPostResponse(
        'project/test-plan',
        input,
        false,
      )
      if (response && response.data) {
        const { data } = response

        data.test_plan.forEach(element => {
          element.value = element.id
          element.label = element.title
        })
        this.$store.state.app.testPlan = data.test_plan
      }
      this.test_plan_loader = false
    },

    /**
     * check the form validation
     * @returns if success true then call saveClientDtl Method
     */
    validationForm() {
      this.$refs.defectObserver.validate().then(success => {
        if (success) {
          this.createUpdateRun()
        }
      })
    },

    /**  create or update Defect */

    async createUpdateRun() {
      this.loader = true
      const input = {
        plan_id: this.$store.state.app.testRun.plan_id,
        project_id: this.$route.params.id3,
        environment: this.$store.state.app.testRun.env,
        run_title: this.$store.state.app.testRun.run_title,
        description: this.$store.state.app.testRun.description,
      }

      let response
      if (this.$store.state.app.testRun.id) {
        response = await this.getHTTPPutResponse(
          `project/test-run/update/${this.$store.state.app.testRun.id}`,
          input,
          true,
        )
      } else {
        response = await this.getHTTPPostResponse(
          'project/test-run/create',
          input,
          true,
        )
      }

      if (response && response.status == 200) {
        if (this.$route.params.id4 == 'create') {
          this.$store.state.app.testRunFilter.perPage = 25
          this.$store.state.app.testRunFilter.currentPage = 1
          this.$store.state.app.testRunFilter.search = null
        }
        setTimeout(() => {
          this.$store.state.app.test_run.id = response.data.testRun.id
          this.$store.state.app.test_run.plan_id = response.data.testRun.plan_id
          this.$store.state.app.testRun.id = null
          this.$store.state.app.pageShow = 'testCasesTableVue'
          this.$router.push({
            name: 'projectTask',
            params: {
              id: this.$route.params.id,
              id2: this.$route.params.id2,
              id3: this.$route.params.id3,
              id4: 'test-run',
              id5: response.data.testRun.id,
              id6: response.data.testRun.plan_id,
            },
            query: {
              layout: this.currentLayout,
              pageName: this.$route.query.pageName,
            },
          }).catch(err => {
            // Ignore the NavigationDuplicated error
            if (err.name !== 'NavigationDuplicated') {
              console.log(err)
            }
          })
          this.clear()
          this.loader = false
        }, 1000)
      } else {
        this.loader = false
      }
    },
    clear(id) {
      if (!id) {
        this.$store.state.app.testRun.plan_id = null
      }
      this.$store.state.app.testRun.env = null
      this.$store.state.app.testRun.run_title = null
      this.$store.state.app.testRun.description = null
      if (this.$refs.defectObserver) {
        this.$refs.defectObserver.reset()
      }
    },
  },
}
</script>
<style lang="scss" scoped>
@import "../../../assets/scss/component-css/tesing.css";
</style>
