<template>
  <b-card>
    <b-card-title class="mb-1">
      Test Run Details
    </b-card-title>
    <div class="count-list">
      <!-- <p class="count-title">Count</p> -->
      <div class="count-info">
        <div>
          <svg
            v-b-tooltip.hover.top
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            title="Passed"
          >
            <g
              fill="none"
              stroke="#23bc74"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
            >
              <path
                d="M10 20.777a8.942 8.942 0 0 1-2.48-.969M14 3.223a9.003 9.003 0 0 1 0 17.554m-9.421-3.684a8.961 8.961 0 0 1-1.227-2.592M3.124 10.5c.16-.95.468-1.85.9-2.675l.169-.305m2.714-2.941A8.954 8.954 0 0 1 10 3.223"
              />
              <path d="m9 12l2 2l4-4" />
            </g>
          </svg>
        </div>
        <p>
          <span class="pass"> {{ this.passCount }}</span>
        </p>
      </div>
      <div class="count-info">
        <div>
          <svg
            v-b-tooltip.hover.top
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            title="Pending"
          >
            <path
              fill="none"
              stroke="#2178fb"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M10 20.777a8.942 8.942 0 0 1-2.48-.969M14 3.223a9.003 9.003 0 0 1 0 17.554m-9.421-3.684a8.961 8.961 0 0 1-1.227-2.592M3.124 10.5c.16-.95.468-1.85.9-2.675l.169-.305m2.714-2.941A8.954 8.954 0 0 1 10 3.223M12 8v4m0 4v.01"
            />
          </svg>
        </div>
        <p>
          <span class="pending"> {{ this.pendingCount }}</span>
        </p>
      </div>
      <div class="count-info">
        <div>
          <svg
            v-b-tooltip.hover.top
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            title="Failed"
          >
            <path
              fill="#F97753"
              d="M13 2.03v2.02c4.39.54 7.5 4.53 6.96 8.92c-.46 3.64-3.32 6.53-6.96 6.96v2c5.5-.55 9.5-5.43 8.95-10.93c-.45-4.75-4.22-8.5-8.95-8.97m-2 .03c-1.95.19-3.81.94-5.33 2.2L7.1 5.74c1.12-.9 2.47-1.48 3.9-1.68v-2M4.26 5.67A9.885 9.885 0 0 0 2.05 11h2c.19-1.42.75-2.77 1.64-3.9L4.26 5.67M2.06 13c.2 1.96.97 3.81 2.21 5.33l1.42-1.43A8.002 8.002 0 0 1 4.06 13h-2m5.04 5.37l-1.43 1.37A9.994 9.994 0 0 0 11 22v-2a8.002 8.002 0 0 1-3.9-1.63M14.59 8L12 10.59L9.41 8L8 9.41L10.59 12L8 14.59L9.41 16L12 13.41L14.59 16L16 14.59L13.41 12L16 9.41L14.59 8Z"
            />
          </svg>
        </div>
        <p>
          <span class="defect-count"> {{ this.defectCount }}</span>
        </p>
      </div>
    </div>
    <vue-apex-charts
      type="donut"
      height="350"
      :options="chartOptions"
      :series="series"
    />
    <b-card-body class="px-0">
      <div class="test-deatil">
        <p class="rate">
          Completion rate
        </p>
        <p class="percentage">
          Completed {{ completionRate }}%
        </p>
        <div class="my-1">
          <p class="rate">
            Test Plan
          </p>
          <p class="percentage">
            {{
              testRunDetail &&
                testRunDetail.test_plan &&
                testRunDetail.test_plan.title
                ? testRunDetail.test_plan.title
                : null
            }}
          </p>
        </div>
        <div class="start-by">
          <p class="rate mb-50">
            Started by
          </p>
          <div class="d-flex align-items-center mb-1">
            <b-avatar
              :src="
                testRunDetail &&
                  testRunDetail.created_by &&
                  testRunDetail.created_by.picture
                  ? testRunDetail.created_by.picture
                  : null
              "
            />
            <p class="ml-50 name-of-tester">
              {{
                testRunDetail &&
                  testRunDetail.created_by &&
                  testRunDetail.created_by.full_name
                  ? testRunDetail.created_by.full_name
                  : null
              }}
            </p>
          </div>
          <div class="start-time">
            <p class="rate mb-50">
              Start Time
            </p>
            <div
              v-if="testRunDetail && testRunDetail.created_at"
              class="d-flex align-items-center mb-1"
            >
              <div class="d-flex align-items-center">
                <feather-icon
                  icon="CalendarIcon"
                  color="#2178FB"
                  size="24"
                />
                <p class="percentage ml-50">
                  {{ dateShow(testRunDetail.created_at) }}
                </p>
              </div>
              <div class="d-flex align-items-center ml-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M12 22.5C9.9233 22.5 7.89323 21.8842 6.16652 20.7304C4.4398 19.5767 3.09399 17.9368 2.29927 16.0182C1.50455 14.0996 1.29661 11.9884 1.70176 9.95156C2.1069 7.91476 3.10693 6.04383 4.57538 4.57538C6.04383 3.10693 7.91476 2.1069 9.95156 1.70176C11.9884 1.29661 14.0996 1.50455 16.0182 2.29927C17.9368 3.09399 19.5767 4.4398 20.7304 6.16652C21.8842 7.89323 22.5 9.9233 22.5 12C22.5 14.7848 21.3938 17.4555 19.4246 19.4246C17.4555 21.3938 14.7848 22.5 12 22.5ZM12 3C10.22 3 8.47992 3.52785 6.99987 4.51678C5.51983 5.50571 4.36628 6.91132 3.68509 8.55585C3.0039 10.2004 2.82567 12.01 3.17294 13.7558C3.5202 15.5016 4.37737 17.1053 5.63604 18.364C6.89472 19.6226 8.49836 20.4798 10.2442 20.8271C11.99 21.1743 13.7996 20.9961 15.4442 20.3149C17.0887 19.6337 18.4943 18.4802 19.4832 17.0001C20.4722 15.5201 21 13.78 21 12C21 9.61306 20.0518 7.32387 18.364 5.63604C16.6761 3.94822 14.387 3 12 3Z"
                    fill="#2178FB"
                  />
                  <path
                    d="M15.4425 16.5L11.25 12.3075V5.25H12.75V11.685L16.5 15.4425L15.4425 16.5Z"
                    fill="#2178FB"
                  />
                </svg>
                <p class="percentage ml-50">
                  {{ timeShow(testRunDetail.created_at) }}
                </p>
              </div>
            </div>
          </div>
          <div class="time-spent">
            <p class="rate mb-50">
              Time Spent
            </p>
            <div class="d-flex align-items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M12 22.5C9.9233 22.5 7.89323 21.8842 6.16652 20.7304C4.4398 19.5767 3.09399 17.9368 2.29927 16.0182C1.50455 14.0996 1.29661 11.9884 1.70176 9.95156C2.1069 7.91476 3.10693 6.04383 4.57538 4.57538C6.04383 3.10693 7.91476 2.1069 9.95156 1.70176C11.9884 1.29661 14.0996 1.50455 16.0182 2.29927C17.9368 3.09399 19.5767 4.4398 20.7304 6.16652C21.8842 7.89323 22.5 9.9233 22.5 12C22.5 14.7848 21.3938 17.4555 19.4246 19.4246C17.4555 21.3938 14.7848 22.5 12 22.5ZM12 3C10.22 3 8.47992 3.52785 6.99987 4.51678C5.51983 5.50571 4.36628 6.91132 3.68509 8.55585C3.0039 10.2004 2.82567 12.01 3.17294 13.7558C3.5202 15.5016 4.37737 17.1053 5.63604 18.364C6.89472 19.6226 8.49836 20.4798 10.2442 20.8271C11.99 21.1743 13.7996 20.9961 15.4442 20.3149C17.0887 19.6337 18.4943 18.4802 19.4832 17.0001C20.4722 15.5201 21 13.78 21 12C21 9.61306 20.0518 7.32387 18.364 5.63604C16.6761 3.94822 14.387 3 12 3Z"
                  fill="#2178FB"
                />
                <path
                  d="M15.4425 16.5L11.25 12.3075V5.25H12.75V11.685L16.5 15.4425L15.4425 16.5Z"
                  fill="#2178FB"
                />
              </svg>
              <p class="percentage ml-50">
                {{ timeSpend | secondsToTime }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import { BCard, BCardTitle, BCardSubTitle } from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import * as moment from 'moment/moment'

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardTitle,
    BCardSubTitle,
  },
  props: {
    testRunDetail: {
      required: true,
    },
  },
  data() {
    const vm = this
    return {
      series: [],
      defectCount: 0,
      passCount: 0,
      pendingCount: 0,

      chartOptions: {
        legend: {
          show: false,
          position: 'bottom',
          fontSize: '0px',
          fontFamily: 'Montserrat',
        },
        colors: ['#23BC74', '#2178FB', '#F97753'],
        fill: {
          type: 'solid',
          colors: ['#23BC74', '#2178FB', '#F97753'],
          opacity: 1,
        },
        dataLabels: {
          enabled: false,
          formatter(val) {
            // eslint-disable-next-line radix
            return `${parseInt(val)}%`
          },
        },
        tooltip: {
          y: {
            formatter(val, index) {
              const count = index.seriesIndex == 0
                ? vm.passCount
                : index.seriesIndex == 1
                  ? vm.pendingCount
                  : vm.defectCount

              return `${parseInt(val)}%`
            },
          },
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: false,
                name: {
                  fontSize: '2rem',
                  fontFamily: 'Montserrat',
                },
                value: {
                  fontSize: '1rem',
                  fontFamily: 'Montserrat',
                  // formatter(val) {
                  //   // eslint-disable-next-line radix
                  //   return `${parseInt(val)}%`;
                  // },
                },
                total: {
                  show: true,
                  fontSize: '1.5rem',
                  label: 'Operational',
                  // formatter() {
                  //   return "31%";
                  // },
                },
              },
            },
          },
        },
        labels: ['Passed', 'Pending', 'Failed'],
        markers: {
          size: 0,
          colors: undefined,
          strokeColors: '#fff',
          strokeWidth: 2,
          strokeOpacity: 0.9,
          strokeDashArray: 0,
          fillOpacity: 1,
          discrete: [],
          shape: 'circle',
          radius: 2,
          offsetX: 0,
          offsetY: 0,
          onClick: undefined,
          onDblClick: undefined,
          showNullDataPoints: true,
          hover: {
            size: undefined,
            sizeOffset: 3,
          },
        },
        responsive: [
          {
            breakpoint: 1600,
            options: {
              chart: {
                height: 300,
              },
              legend: {
                position: 'bottom',
              },
            },
          },
          {
            breakpoint: 1350,
            options: {
              chart: {
                height: 260,
              },
              plotOptions: {
                pie: {
                  donut: {
                    labels: {
                      show: false,
                      name: {
                        fontSize: '0rem',
                      },
                      value: {
                        fontSize: '0rem',
                      },
                      total: {
                        fontSize: '0rem',
                      },
                    },
                  },
                },
              },
              legend: {
                show: false,
              },
            },
          },
        ],
      },
    }
  },
  computed: {
    completionRate() {
      const testRunCases = this.testRunDetail?.test_run_cases || []

      const passCount = testRunCases.reduce(
        (count, testCase) => (testCase.status !== 'pending' ? count + 1 : count),
        0,
      )

      return testRunCases.length > 0
        ? ((passCount / testRunCases.length) * 100).toFixed(0)
        : 0
    },
    timeSpend() {
      const testRunCases = this.testRunDetail?.test_run_cases || []
      let total_time = 0
      testRunCases.forEach(element => {
        total_time += element && element.total_time ? element.total_time : 0
      })

      return total_time * 60
    },
  },
  watch: {
    testRunDetail(nv) {
      this.chartDataSet()
    },
  },
  mounted() {
    this.chartDataSet()
  },
  methods: {
    calculatePercentage(value, total) {
      return Number(((value / total) * 100).toFixed(0))
    },
    chartDataSet() {
      if (this.testRunDetail?.test_run_cases) {
        const { test_run_cases } = this.testRunDetail
        const total_Test_Case = test_run_cases.length

        let pending = 0
        let defect = 0
        let pass = 0

        for (let i = 0; i < total_Test_Case; i++) {
          const testCase = test_run_cases[i]
          if (testCase.status === 'pending') {
            pending++
          } else if (testCase.status === 'defect') {
            defect++
          } else if (testCase.status === 'passed') {
            pass++
          }
        }
        this.defectCount = defect
        this.pendingCount = pending
        this.passCount = pass
        this.series = [
          this.calculatePercentage(pass, total_Test_Case),
          this.calculatePercentage(pending, total_Test_Case),
          this.calculatePercentage(defect, total_Test_Case),
        ]
      }
    },
    dateShow(date) {
      return moment(date).format('DD-MM-YYYY')
    },
    timeShow(date) {
      return moment(date).format('HH:mm')
    },
  },
}
</script>
<style>
@import "../../../assets/scss/component-css/tesing.css";
</style>
