<template>
  <div>

    <TimeLogSummaryShimmer
      v-if="loader"
      class="shimmer"
    />
    <div
      v-else
      class="aq-summary-card"
    >
      <div class="header d-flex justify-content-between mb-1">
        <h3 class="">
          Time Log Summary
        </h3>
        <div class="time-log-report">
          <custom-calender
            :start="startDate"
            :end="endDate"
            :active-btn="active"
            from="timeLogSummary"
            @date="updateDate"
          />
        </div>
      </div>
      <div class="">
        <div
          class="task-sum-report"
          style="min-height: 140px;"
        >
          <div
            v-if="objectKeys && objectKeys.length"
            class="task-sum-content"
          >
            <div class="task-sum-header">
              <p>
                Name
              </p>
              <p>Duration</p>
            </div>
            <div
              v-if="objectKeys && objectKeys.length"
              class="collapse-task-data"
            >
              <b-collapse
                v-for="(task, index) in objectKeys"
                id="collapse-all"
                :key="index"
                class="task-sum-detail time-log-summary"
                visible
              >
                <div
                  v-if="projectUsers && task && projectUsers[task] && projectUsers[task].length && projectUsers[task][0] && projectUsers[task][0].users.user_tasks"
                  v-b-toggle="`${index}`"
                  :class="projectUsers[task][0].users.user_tasks && !projectUsers[task][0].users.user_tasks.length ? 'main-collapse cursor-default' : 'main-collapse cursor-pointer'"
                  @click="reDirect(projectUsers[task][0].users.id)"
                >
                  <div class="person-detail">
                    <b-badge variant="light-primary">
                      {{ projectUsers[task][0].users.user_tasks.length }}
                    </b-badge>
                    <p>
                      {{ projectUsers[task][0].users.full_name }}
                    </p>
                  </div>
                  <p class="total-duration-hr">
                    {{ getTotalTime(projectUsers[task][0].users.user_tasks) }}
                  </p>
                </div>

              </b-collapse>
            </div>
          </div>
          <div
            v-if="objectKeys && !objectKeys.length && !loader"
            class="d-flex align-items-center singleNote"
          >
            <div>
              <div class="d-flex justify-content-between" />
              <p>No User Allocated</p>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import * as moment from 'moment/moment'
import { eventBus } from '../../../main'
import TimeLogSummaryShimmer from '../../shimmer/TimeLogSummaryShimmer.vue'

export default {
  name: 'TaskSummaryReport',
  components: {
    TimeLogSummaryShimmer,
  },

  data() {
    return {
      projectUsers: [],
      objectKeys: [],
      loader: false,
      startDate: (this.$route.query && this.$route.query.startDate) || moment().startOf('month').format('YYYY-MM-DD'),
      endDate: (this.$route.query && this.$route.query.endDate) || moment().format('YYYY-MM-DD'),
      active: null,
    }
  },

  mounted() {
    this.projectUserTaskLog()

    /* if in query have startdate & end date the set */
    if (this.$route.query) {
      this.startDate = this.$route.query.startDate || this.startDate
      this.endDate = this.$route.query.endDate || this.endDate
    }
    // date update call api
    eventBus.$on('callProjectUserTimeLogApi', () => {
      this.projectUserTaskLog()
    })
  },
  destroyed() {
    eventBus.$off('callProjectUserTimeLogApi')
  },
  methods: {

    reDirect(userId) {
      this.$router.push({
        name: 'summary-report',
        query: {
          summaryStartDate: this.startDate,
          summaryEndDate: this.endDate,
          summaryProjects: [this.$route.params.id3],
          summaryMemberList: [userId],
        },
      })
    },

    // Get all notification list
    async projectUserTaskLog() {
      this.loader = true
      const input = {
        project_id: [this.$route.params.id3],
        start_date: this.startDate,
        end_date: this.endDate,
      }
      const response = await this.getHTTPPostResponse(
        'project/user/get-project-user-with-time-log',
        input,
        false,
      )
      if (response && response.data) {
        this.projectUsers = response.data.project_users
        this.objectKeys = Object.keys(response.data.project_users)
      }
      this.loader = false
    },

    getTotalTime(arr) {
      let time = 0

      if (arr && arr.length) {
        arr.forEach(element => {
          time += element.total_time
        })
      }

      return this.secondsToTime(time * 60)
    },

    updateDate(obj) {
      this.startDate = obj && obj.startDate ? obj.startDate : null
      this.endDate = obj && obj.endDate ? obj.endDate : null
      this.active = obj && obj.active ? obj.active : null
      const query = {
        startDate: this.startDate, endDate: this.endDate,
      }
      this.routeQueryUpdate(query)
      setTimeout(() => {
        eventBus.$emit('callProjectUserTimeLogApi', true)
      }, 500)
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/component-css/taskSummaryReport.scss";
.task-sum-report .task-sum-detail .main-collapse{
  cursor: pointer;
}
.shimmer {
  margin-top: 24px;
  border-radius: 24px;
}

.singleNote {
  padding: 10px;
  background-color: #e9f1ff;
  border-radius: 8px;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }

  svg {
    stroke: $primary-color;
    cursor: pointer;
  }
}

.time-log-summary {
  font-size: 16px;
  font-weight: 400;
  color: #6e6b7b;

  &:hover {
    background-color: $blue !important;
    color: $primary-color !important;

    .person-detail .person-detail {
      color: $primary-color !important;
    }
  }
}
</style>
