var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"custom-project-form"},[_c('validation-observer',{ref:"simpleRules"},[_c('b-sidebar',{staticClass:"sidebar-form add-sprint",attrs:{"id":"addMilestone","bg-variant":"white","right":"","backdrop":"","shadow":""},on:{"hidden":function($event){return _vm.sidebarToggle()}},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('div',{staticClass:"form-button d-flex my-2"},[_c('button',{staticClass:"btn side-bar-btn",attrs:{"type":"submit","disabled":_vm.btnLoader},on:{"click":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[(_vm.btnLoader)?_c('b-spinner',{staticClass:"spinner",attrs:{"small":""}}):_vm._e(),_vm._v(" Add ")],1),_c('button',{staticClass:"btn side-bar-btn",on:{"click":function($event){return _vm.clearData()}}},[_vm._v("Clear")])])]},proxy:true}])},[_c('h3',{staticClass:"form-title"},[_vm._v("Add Milestone")]),_c('div',{staticClass:"b-sidebar-header"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{staticClass:"required-form-filed",attrs:{"label":"Title","label-for":"Title"}},[_c('validation-provider',{attrs:{"name":"Title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text","name":"milestoneTitle","id":"Title","placeholder":"Milestone Title"},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-form-group',{staticClass:"required-form-filed",attrs:{"label":"Description","label-for":"Description"}},[_c('validation-provider',{attrs:{"name":"Description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"Description","size":"sm","rows":"5","placeholder":"Description","name":"Description"},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-row',[_c('b-col',{attrs:{"xl":"6","md":"12"}},[_c('b-form-group',[_c('label',{attrs:{"for":"estimated_start_date"}},[_vm._v("Estimated start date "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"Estimated start date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('flat-pickr',{staticClass:"form-control",attrs:{"config":_vm.configEstimatedStartDate,"placeholder":"Estimated start date","name":"estimated_start_date"},on:{"on-change":_vm.onEstimatedStartDateChange},model:{value:(_vm.estimated_start_date),callback:function ($$v) {_vm.estimated_start_date=$$v},expression:"estimated_start_date"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"CalendarIcon"}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"xl":"6","md":"12"}},[_c('b-form-group',[_c('label',{attrs:{"for":"estimated end date"}},[_vm._v("Estimated end date "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"Estimated end date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('flat-pickr',{staticClass:"form-control",attrs:{"config":_vm.configEstimatedEndDate,"placeholder":"Estimated end date","name":"estimated_end_date"},on:{"on-change":_vm.onEstimatedEndDateChange},model:{value:(_vm.estimated_end_date),callback:function ($$v) {_vm.estimated_end_date=$$v},expression:"estimated_end_date"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"CalendarIcon"}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }