<template>
  <div class="pb-5">
    <!--filter start-->
    <div class="card-filter-section">
      <div class="d-flex align-items-center flex-wrap justify-content-between">
        <div class="d-flex align-items-center justify-content-start flex-wrap">
          <p class="filter-title">
            Filters:
          </p>

          <div class="">
            <custom-dropdown
              v-model="searchTags"
              :options="tags"
              label="Tag"
              icon="EditIcon"
              multiple
            />
          </div>
        </div>
        <div class="d-flex align-items-center">
          <b-input-group
            class="input-group-merge search-project"
            style="min-width: 250px; max-width: 250px; width: 250px"
          >
            <b-form-input
              v-model="debouncedSearch"
              placeholder="Search by Note Title"
            />

            <b-input-group-append is-text>
              <feather-icon
                v-if="debouncedSearch"
                icon="XIcon"
                @click="debouncedSearch = null ,search = null"
              />
              <feather-icon icon="SearchIcon" />
            </b-input-group-append>
          </b-input-group>

          <div class="ml-1">
            <button
              class="apply-filter filterRedesign mr-1"
              @click="applyFilter()"
            >
              Apply Filter
            </button>
            <button
              class="apply-filter-clear filterRedesign"
              @click="clearFilter()"
            >
              Clear
            </button>

            <button
              v-b-modal.notepopup
              class="add-note filterRedesign ml-1"
              @click="callEditor()"
            >
              Add Note
            </button>
          </div>
        </div>
      </div>
    </div>

    <!--filter end-->
    <!-- <div class="newNote my-2" v-b-modal.notepopup @click="callEditor()">
      <div class=""> -->
    <!-- <div class="pinIcon"><img src="../../assets/images/icons/pinicon.svg" ></div> -->
    <!-- <div><p>Take a note...</p></div>
        <NotesEditors id="notepopup" :tags="tags" ref="" :data="data" />
      </div>
    </div> -->

    <div>
      <NotesEditors
        id="notepopup"
        ref=""
        :tags="tags"
        :data="data"
      />
    </div>
    <div class="masonry-with-columns">
      <div
        v-for="(note, index) in notes"
        v-if="!load_project"
        :key="index"
        class="grid-items"
      >
        <NotesCards
          :id="index"
          class=""
          :project-dtl="projectDtl"
          :tags="tags"
          :data="note"
          @noteList="noteList()"
        />
      </div>
      <div
        v-if="load_project"
        class="grid-item"
      >
        <notecard-shimmer v-if="load_project" />
      </div>

    </div>
    <div v-if="!load_project && notes.length == 0">
      <img
        src="../../assets/images/nodatafound/nodata.svg"
        alt="no-data"
        class="no-data-img"
      >
    </div>
  </div>
</template>

<script>
import customDropdown from '@/components/dropdown/customDropdown.vue'
import { eventBus } from '@/main'
import debounce from 'lodash/debounce'
import NotesCards from '../../components/notes/NotesCrad.vue'
import NotesEditors from '../../components/notes/NotesEditors.vue'
import NotecardShimmer from '../shimmer/NotecardShimmer.vue'

export default {
  name: 'Notes',
  components: {
    NotesCards,
    NotesEditors,
    NotecardShimmer,
    customDropdown,
  },
  props: {
    projectDtl: {
      // type: Object,
      required: true,
      default: null,
    },
  },
  data() {
    return {
      load_project: false,
      notes: [],
      reporting_to_users: [],
      data: null,
      tags: [],
      searchTags: [],
      search: null,
    }
  },
  watch: {
    searchTags() {
      this.noteList()
    },
  },
  computed: {
    debouncedSearch: {
      get() {
        return this.search
      },
      set: debounce(function (value) {
        this.search = value
        this.noteList()
      }, 750),
    },
  },
  mounted() {
    this.getTagData()
    this.noteList()
    this.filterFunctionCallFromMixin()
    eventBus.$on('noteList', data => {
      if (data) {
        this.noteList()
      }
    })
  },
  methods: {
    async getTagData() {
      const input = {
        is_active: true,
      }
      const response = await this.getHTTPPostResponse(
        'admin/master/tag',
        input,
        false,
      )
      if (response?.data?.tags) {
        this.tags = response.data.tags.map(element => ({
          label: element.name,
          value: element.name,
        }))
      }
    },
    callEditor() {
      eventBus.$emit('createNote', true)
    },
    /*  filter data */
    async filterFunctionCallFromMixin() {
      this.reporting_to_users = await this.reportingtoUserList()
    },
    async noteList(filters = null) {
      this.load_project = true
      const input = {
        sort_field: 'created_at',
        sort_order: 'desc',
        project_id: this.$route.params.id3,
        tags: this.searchTags,
        search: this.search,
      }
      const response = await this.getHTTPPostResponse(
        'project/note',
        input,
        false,
      )
      if (response && response.data) {
        this.notes = response.data.notes
      }
      this.load_project = false
    },
    applyFilter() {
      this.noteList()
    },
    clearFilter() {
      this.search = null
      this.searchTags = []
      this.noteList()
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/variables/_variables.scss";

.fixHight {
  display: -webkit-box;
  -webkit-line-clamp: 9;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.fixHightCard {
  // min-height: 500px;
}
.card-filter-section {
  background-color: $white;
  margin: 10px 0px 10px 0px;
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
  padding: 10px 15px;
  .filter-title {
    color: $logo-color;
    font-size: 2.25 * $rem;
    font-weight: $fw500;
  }
  .apply-filter {
    background-color: $secondary-color;
    border: 1px solid $secondary-color;
    color: $white;
    padding: 6px 11px;
    border-radius: 5px;
    font-size: 2 * $rem;
    font-weight: $fw600;
    @media (max-width: map-get($mediaSizes , "xxl")) {
      font-size: 2 * $rem;
    }
    @media (max-width: map-get($mediaSizes , "xl")) {
      font-size: 1.75 * $rem;
    }
    @media (max-width: map-get($mediaSizes , "lg")) {
      font-size: 1.5 * $rem;
    }
  }
  .apply-filter-clear {
    border: 1px solid $secondary-color;
    color: $secondary-color;
    padding: 6px 20px;
    border-radius: 5px;
    font-size: 2 * $rem;
    font-weight: $fw600;
    background-color: transparent;
    @media (max-width: map-get($mediaSizes , "xxl")) {
      font-size: 2 * $rem;
    }
    @media (max-width: map-get($mediaSizes , "xl")) {
      font-size: 1.75 * $rem;
    }
    @media (max-width: map-get($mediaSizes , "lg")) {
      font-size: 1.5 * $rem;
    }
  }
  .add-note {
    background-color: #2178fb;
    border: 1px solid #2178fb;
    color: $white;
    padding: 6px 11px;
    border-radius: 5px;
    font-size: 2 * $rem;
    font-weight: $fw600;
    @media (max-width: map-get($mediaSizes , "xxl")) {
      font-size: 2 * $rem;
    }
    @media (max-width: map-get($mediaSizes , "xl")) {
      font-size: 1.75 * $rem;
    }
    @media (max-width: map-get($mediaSizes , "lg")) {
      font-size: 1.5 * $rem;
    }
  }
  .search-project {
    border-radius: 5px;
    color: $dark-gray;
    &:focus-visible {
      outline: $primary-color;
    }
    .form-control {
      border-color: $input-outline;
      padding: 6px 0 6px 20px;
      color: $logo-color !important;
      height: 33px;
      &::placeholder {
        color: $dark-gray;
        font-size: 1.5 * $rem;
        font-weight: $fw500;
      }
    }
  }
}
</style>
