<template>
  <div>
    <div
      class="accordion"
      role="tablist"
    >
      <b-card
        no-body
        class="mb-1"
      >
        <b-card-header
          header-tag="header"
          class="d-flex mile-stone-header"
          role="tab"
        >
          <div>
            <!-- <b-button block class="title-button d-flex align-items-center" @click="demo()" v-b-toggle="data.id">
                                    <feather-icon icon="ChevronRightIcon" size="50  " :class="[collapseIcon ? 'collapseicon' : 'collapsedemo']"></feather-icon><span class="milestone-title">{{ data.title }}
                                    <input
                  type="text"
                  name="title"
                  id="title"
                  v-show="!isshow"
                  v-model="title"
                  class="input-group-merge"

                /></span></b-button> -->
            <div class="d-flex align-items-center justify-conente-between">
              <b-button
                v-b-toggle="data.id"
                block
                class="title-button"
              ><span class="featherspan">
                <b-icon
                  icon="Caret-right-fill"
                  size="50"
                  class="collapseicon"
                /></span></b-button>
              <div>
                <p
                  v-show="isshow"
                  v-b-toggle="data.id"
                  class="milestone-title"
                >
                  {{ data.title }}
                </p>

                <!-- <validation-provider
                #default="{ errors }"
                name="title"
                rules="required"
              > -->
                <input
                  v-show="!isshow"
                  id=""
                  v-model="title"
                  type="text"
                  name="milestonetitle"
                  class="form-input-type"
                >
                <!-- <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider> -->
              </div>
            </div>
          </div>

          <div>
            <button class="milestone-total">
              {{ count }}
            </button>
            <button class="sprint-total milestone-total">
              {{ compeltedSprint }}
            </button>
            <button
              v-if="data.status === 'active'"
              class="mile-stone-status active-status"
              
              :style="!rights ?'cursor: default':null"
              :disabled="!rights"
              @click="changeConfirmation('complete')"
            >
              Active
            </button>
            <button
              v-if="data.status === 'complete'"
              class="mile-stone-status complete-status"
              style="cursor: default"
              :disabled="true"
            >
              Completed
            </button>
            <button
              v-if="data.status === 'not_started'"
              class="mile-stone-status not-started-status"
              :style="!rights ?'cursor: default':null"
              :disabled="!rights"
              @click="changeConfirmation('active')"
            >
              Not Started
            </button>
          </div>
        </b-card-header>
        <b-collapse
          :id="data.id"
          ref="collapse"
          accordion="my-accordion"
          role="tabpanel"
          class="collapseactive"
        >
          <validation-observer ref="mileStoneTasksRules">
            <b-card-body>
              <div class="des-body">
                <div
                  v-if="this.rights === true || this.userInfo.role == 'SA'"
                  class="d-flex justify-content-end"
                >
                  <!-- project title -->

                  <div class="icons">
                    <!-- edit button -->
                    <span
                      v-if="edit === false"
                      @click="(edit = true), editMethod()"
                    ><feather-icon
                      icon="EditIcon"
                      size="18"
                      style="color: #5e5873"
                      role="button"
                    /></span>
                    <span
                      v-if="edit === true"
                      @click="(edit = false), (isshow = true),cancelMethod()"
                    ><feather-icon
                      icon="XIcon"
                      size="18"
                      style="color: #5e5873"
                      class="mr-50"
                      role="button"
                    /></span>
                    <!-- success button -->
                    <span
                      v-if="edit === true"
                      @click="update()"
                    ><feather-icon
                      role="button"
                      icon="CheckIcon"
                      size="18"
                      style="color: #5e5873"
                    /></span>
                  </div>
                </div>
                <div>
                  <div class="mileston-des-section">
                    <p class="milestone-item-labal">
                      Description
                    </p>
                    <span
                      v-show="isshow"
                      class="milestone-item"
                    >{{ data.description }}
                    </span>
                    <validation-provider
                      #default="{ errors }"
                      name="Description"
                      rules="required"
                    >
                      <textarea
                        v-show="!isshow"
                        id=""
                        v-model="description"
                        name="milestonedes"
                        rows="10"
                        class="milestone-des"
                        required
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </div>
                </div>
                <!-- timeestimation -->
                <div class="d-flex flex-wrap justify-content-between mt-1">
                  <div class="mr-2">
                    <p class="milestone-item-labal">
                      Estimated Start Date
                    </p>
                    <span
                      v-show="isshow"
                      class="milestone-item"
                    >{{ dayName(data.estimated_start_date) }}
                    </span>
                    <validation-provider
                      #default="{ errors }"
                      name="Estimated start date"
                      rules="required"
                    >
                      <b-input-group
                        v-show="!isshow"
                        id=""
                        class="input-group-merge"
                        name="eaststartdate"
                      >
                        <flat-pickr
                          v-model="estimated_start_date"
                          :config="configEstimatedStartDate"
                          class="form-control"
                          @on-change="onEstimatedStartDateChange"
                        />
                        <b-input-group-append is-text>
                          <feather-icon icon="CalendarIcon" />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </div>

                  <div>
                    <p class="milestone-item-labal">
                      Estimated End Date
                    </p>
                    <span
                      v-show="isshow"
                      class="milestone-item"
                    >{{
                      dayName(data.estimated_end_date)
                    }}</span>
                    <validation-provider
                      #default="{ errors }"
                      name="Estimated end date"
                      rules="required"
                    >
                      <b-input-group
                        v-show="!isshow"
                        id=""
                        class="input-group-merge"
                        name="eastenddate"
                      >
                        <flat-pickr
                          v-model="estimated_end_date"
                          :config="configEstimatedEndDate"
                          class="form-control"
                          @on-change="onEstimatedStartDateChange"
                        />
                        <b-input-group-append is-text>
                          <feather-icon icon="CalendarIcon" />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </div>

                  <div class="mb-1">
                    <div>
                      <p class="milestone-item-labal">
                        Actual Start Date
                      </p>
                      <span
                        v-show="isshow"
                        class="milestone-item"
                      >{{
                        dayName(data.actual_start_date)
                      }}</span>
                      <b-input-group
                        v-show="!isshow"
                        id=""
                        class="input-group-merge"
                        name="actualstartdate"
                      >
                        <flat-pickr
                          v-model="actual_start_date"
                          :config="configActualStartDate"
                          class="form-control"
                          @on-change="onActualStartDateChange"
                        />
                        <b-input-group-append is-text>
                          <feather-icon icon="CalendarIcon" />
                        </b-input-group-append>
                      </b-input-group>
                    </div>
                  </div>

                  <div>
                    <p class="milestone-item-labal">
                      Actual End Date
                    </p>
                    <span
                      v-show="isshow"
                      class="milestone-item"
                    >{{
                      dayName(data.actual_end_date)
                    }}</span>
                    <b-input-group
                      v-show="!isshow"
                      id=""
                      class="input-group-merge"
                      name="actualenddate"
                    >
                      <flat-pickr
                        v-model="actual_end_date"
                        :config="configActualEndDate"
                        class="form-control"
                        @on-change="onActualEndDateChange"
                      />
                      <b-input-group-append is-text>
                        <feather-icon icon="CalendarIcon" />
                      </b-input-group-append>
                    </b-input-group>
                  </div>
                </div>
              </div>

              <div class="mt-1 sprint-section">
                <sprint
                  :data="data"
                  :index="this.index"
                  :count="count"
                  :rights="rights"
                  @count="count=$event"
                  @sprintList="refreshList()"
                />
              </div>
            </b-card-body>
          </validation-observer>
        </b-collapse>
        <!-- data table complete -->
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  BCollapse,
  BCard,
  BCardHeader,
  BCardText,
  BCardBody,
  VBToggle,
  BRow,
  BCol,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import FeatherIcon from '../../../@core/components/feather-icon/FeatherIcon.vue'
import Sprint from './Sprint.vue'

export default {
  name: 'Milestone',
  components: {
    BCollapse,
    BCard,
    BCardHeader,
    BCardText,
    BCardBody,
    FeatherIcon,
    BRow,
    BCol,
    ToastificationContent,
    vSelect,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    flatPickr,
    Sprint,
    ValidationObserver,
    ValidationProvider,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  props: ['id', 'data', 'index', 'rights'],
  data() {
    return {
      status: 'not started',
      count: null,
      compeltedSprint: null,
      cost: null,
      projectType: null,
      title: this.data.title,
      description: this.data.description,
      estimated_start_date: this.data.estimated_start_date,
      estimated_end_date: this.data.estimated_end_date,
      actual_end_date: this.data.actual_end_date,
      actual_start_date: this.data.actual_start_date,
      configEstimatedStartDate: {
        // minDate: new Date(),
        maxDate: null,
        dateFormat: 'Y/m/d',
      },
      configEstimatedEndDate: {
        // minDate: new Date(),
        minDate: null,
        dateFormat: 'Y/m/d',
      },
      configActualStartDate: {
        // minDate: new Date(),
        maxDate: null,
        dateFormat: 'Y/m/d',
      },
      configActualEndDate: {
        // minDate: new Date(),
        minDate: null,
        dateFormat: 'Y/m/d',
      },
      collapseIcon: false,
      isshow: true,
      edit: false,
    }
  },
  mounted() {
    // by Defualt collapse open with active
    if (this.data.status == 'active') {
      this.$refs.collapse.show = true
    }
    this.spintcount(this.data)
  },
  methods: {
    /* day's name */
    dayName(date) {
      if (date === null || date === '') {
        return 'N/A'
      }
      const d = new Date(date)
      const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d)
      const mo = new Intl.DateTimeFormat('en', { month: 'long' }).format(d)
      const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d)
      const dayName = `${da} ${mo}, ${ye}`
      return dayName
    },
    onEstimatedStartDateChange(selectedDates, dateStr, instance) {
      this.$set(this.configEstimatedEndDate, 'minDate', dateStr)
    },
    onEstimatedEndDateChange(selectedDates, dateStr, instance) {
      this.$set(this.configEstimatedStartDate, 'maxDate', dateStr)
    },
    onActualStartDateChange(selectedDates, dateStr, instance) {
      this.$set(this.configActualEndDate, 'minDate', dateStr)
    },
    onActualEndDateChange(selectedDates, dateStr, instance) {
      this.$set(this.configActualStartDate, 'maxDate', dateStr)
    },

    refreshList() {
      this.$emit('updatemilestone', true)
    },
    update() {
      this.$refs.mileStoneTasksRules.validate().then(success => {
        if (success) {
          this.updateMilestone()
        }
      })
    },
    // mileStoneTasksRules
    async updateMilestone() {
      const input = {
        status: this.data.status,
        project_id: this.$route.params.id3,
        type: 'milestone',
        title: this.title,
        description: this.description,
        estimated_start_date: this.estimated_start_date,
        estimated_end_date: this.estimated_end_date,
        actual_start_date: this.actual_start_date,
        actual_end_date: this.actual_end_date,
        cost: this.cost,
      }

      let response = null

      response = await this.getHTTPPutResponse(
        `project/plan/update/${this.data.id}`,
        input,
        true,
      )

      if (response && response.status == 200) {
        this.data.estimated_start_date = this.estimated_start_date
        this.data.estimated_end_date = this.estimated_end_date
        this.data.actual_start_date = this.actual_start_date
        this.data.actual_end_date = this.actual_end_date
        this.data.description = this.description
        this.data.title = this.title
        this.isshow = true
        this.edit = false
        this.$emit('milestone', true)
      } else {
        this.estimated_start_date = this.data.estimated_start_date
        this.estimated_end_date = this.data.estimated_end_date
        this.actual_start_date = this.data.actual_start_date
        this.actual_end_date = this.data.actual_end_date
        this.description = this.data.description
        this.isshow = true
        this.title = this.data.title
        this.edit = false
      }
    },
    changeConfirmation(status) {
      this.$swal({
        title: status
          ? `Are you sure to update status to ${status}.`
          : 'Are you sure?',
        icon: 'info',
        html: '<p>Once you change status you will not recover this status again.</p>',
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: 'Update',
        confirmButtonAriaLabel: 'Thumbs up, great!',
        cancelButtonAriaLabel: 'Thumbs down',
        customClass: {
          confirmButton: 'btn confirm',
          cancelButton: 'btn cancel ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.changeStatus()
        }
      })
    },
    async changeStatus() {
      if (this.rights || this.userInfo.role === 'SA') {
        let status
        switch (this.data.status) {
          case 'not_started':
            status = 'active'
            break
          case 'active':
            // code block
            status = 'complete'
            break
          default:
            status = 'complete'
          // code block
        }
        const input = {
          id: this.data.id,
          status,
          project_id: this.$route.params.id3,
        }
        let response = null
        if (this.data.status == 'complete') {
        } else {
          response = await this.getHTTPPostResponse(
            'project/plan/status',
            input,
            true,
          )

          if (response && response.status == 200) {
            this.$emit('milestone', true)
            this.data.status = status
          }
        }
      } else {

      }
    },
    spintcount(data) {
      this.count = data.sprints.length
      const a = []
      data.sprints.forEach(element => {
        if (element.status === 'complete') a.push(element)
      })
      this.compeltedSprint = a.length
    },
    editMethod() {
      this.isshow = false
      this.description = this.data.description
      this.title = this.data.title,
      this.estimated_start_date = this.data.estimated_start_date
      this.estimated_end_date = this.data.estimated_end_date
      this.actual_end_date = this.data.actual_end_date
      this.actual_start_date = this.data.actual_start_date
    },
    cancelMethod() {
      this.$refs.mileStoneTasksRules.reset()
    },
    showToast(variant, position) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text: 'successfuly Updated',
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}
</script>

<style></style>
