var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loader)?_c('loader',{attrs:{"row-num":1}}):_vm._e(),(_vm.projectDtl && !_vm.loader)?_c('div',{staticClass:"new-summary-detail select-value"},[_c('validation-observer',{ref:"projectDetailsObserver"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex justify-content-between mb-1"},[_c('div',[_c('p',{staticClass:"projectOverview"},[_vm._v(" Project Overview ")])]),_c('div',[(!_vm.isEdit && _vm.editRights)?_c('feather-icon',{staticClass:"edit-icons-summary",attrs:{"icon":"Edit3Icon","size":"30"},on:{"click":function($event){_vm.isEdit = true}}}):_vm._e(),(_vm.isEdit)?_c('feather-icon',{staticClass:"edit-icons-summary mr-50",attrs:{"icon":"CheckIcon","size":"30"},on:{"click":function($event){return _vm.updateDetails()}}}):_vm._e(),(_vm.isEdit)?_c('feather-icon',{staticClass:"edit-icons-summary",attrs:{"icon":"XIcon","size":"30"},on:{"click":function($event){return _vm.getDetails()}}}):_vm._e()],1)])]),_c('b-col',{attrs:{"cols":"12","lg":"4"}},[_c('p',{staticClass:"new-smmarydetail-title"},[_vm._v(" Project Description "),(_vm.isEdit)?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e()]),(!_vm.isEdit)?_c('p',{staticClass:"new-smmarydetail-desc"},[_vm._v(" "+_vm._s(_vm.projectDtl.description)+" ")]):_c('validation-provider',{attrs:{"name":"Project description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{staticClass:"my-1 mb-0 textboxProjectedit",attrs:{"id":"textarea-rows","placeholder":"Description","rows":"8"},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2132679847)}),_c('div',{staticClass:"mt-2 mb-1"},[_c('p',{staticClass:"new-smmarydetail-title mb-50"},[_vm._v(" Project Scope "),(_vm.isEdit)?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e()]),(!_vm.isEdit)?_c('div',_vm._l((_vm.projectDtl.project_scopes),function(scope,sindex){return _c('b-badge',{key:sindex,staticClass:"newproject-card-tag"},[_c('span',{staticClass:"tag_name"},[_vm._v(_vm._s(scope.name))])])}),1):_c('validation-provider',{attrs:{"name":"Project scope","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"select-size-lg project-scope-detail",attrs:{"id":"project_scope_id","hide-details":"","name":"project_scope_id","label":"name","options":_vm.$store.state.app.projectScope,"reduce":function (scope) { return scope.id; },"placeholder":"Select project scope","multiple":"","close-on-select":false},model:{value:(_vm.project_scope_id),callback:function ($$v) {_vm.project_scope_id=$$v},expression:"project_scope_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,263161301)})],1),_c('div',[_c('p',{staticClass:"newsummary-editableinput-title"},[_vm._v(" Project Status "),(_vm.isEdit)?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e()]),(_vm.isEdit)?_c('validation-provider',{attrs:{"name":"Project status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"mb-50",attrs:{"id":"status","hide-details":"","name":"status","label":"label","options":_vm.projectStatusList,"reduce":function (status) { return status.value; },"placeholder":"Select status","clearable":true},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,975121806)}):_vm._e(),(!_vm.isEdit)?_c('div',{staticClass:"newproject-timeline-section"},[_c('b-badge',{class:("newproject-card-status " + _vm.projectStatusClass)},[_vm._v(" "+_vm._s(_vm.projectStatusName)+" ")])],1):_vm._e()],1)],1),_c('b-col',{staticClass:"mt-lg-0 mt-1",attrs:{"cols":"12","lg":"4"}},[(_vm.isEdit)?_c('b-row',{staticClass:"mb-lg-3 mb-2"},[_c('b-col',{attrs:{"cols":"4"}},[_c('p',{staticClass:"newsummary-editableinput-title"},[_vm._v(" Project Name "),(_vm.isEdit)?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e()]),_c('validation-provider',{attrs:{"name":"Project name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"project name","placeholder":"Name","name":"project name"},model:{value:(_vm.projectName),callback:function ($$v) {_vm.projectName=$$v},expression:"projectName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1430375117)})],1),_c('b-col',{staticClass:"input-group color-picker-input",attrs:{"cols":"4"}},[_c('p',{staticClass:"newsummary-editableinput-title"},[_vm._v(" Project Color "),(_vm.isEdit)?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e()]),_c('validation-provider',{attrs:{"name":"Project color","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"color-picker-input"},[_c('b-form-input',{attrs:{"id":"projectcolor","type":"text","name":"projectcolor","placeholder":"Select any color"},model:{value:(_vm.projectColor),callback:function ($$v) {_vm.projectColor=$$v},expression:"projectColor"}}),_c('b-input-group-append',{staticClass:"projectColorpicker",attrs:{"is-text":""}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.projectColor),expression:"projectColor"}],staticClass:"color-picker",attrs:{"type":"color","name":"projectcolor"},domProps:{"value":(_vm.projectColor)},on:{"input":function($event){if($event.target.composing){ return; }_vm.projectColor=$event.target.value}}})])],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3629455065)})],1)],1):_vm._e(),_c('b-row',[(_vm.projectDtl.client)?_c('b-col',{attrs:{"cols":"4"}},[_c('p',{staticClass:"newsummary-editableinput-title",attrs:{"id":"basicInput"}},[_vm._v(" Client name "),(_vm.isEdit)?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e()]),(_vm.isEdit)?_c('validation-provider',{attrs:{"name":"Client name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"client_id","hide-details":"","name":"client_id","label":"name","options":_vm.$store.state.app.clientList,"reduce":function (client) { return client.id; },"placeholder":"Select Client"},model:{value:(_vm.clientID),callback:function ($$v) {_vm.clientID=$$v},expression:"clientID"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1486557931)}):_c('p',{staticClass:"project-input"},[_vm._v(" "+_vm._s(_vm.projectDtl.client.name)+" ")])],1):_vm._e(),_c('b-col',{attrs:{"cols":"4"}},[_c('p',{staticClass:"newsummary-editableinput-title"},[_vm._v(" Project Type "),(_vm.isEdit)?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e()]),(_vm.isEdit)?_c('validation-provider',{attrs:{"name":"Project type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"projecttype","hide-details":"","name":"projecttype","label":"label","options":_vm.projectTypeList,"reduce":function (type) { return type.value; },"placeholder":"Select type","clearable":true},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,143011429)}):_c('p',{staticClass:"project-input"},[_vm._v(" "+_vm._s(_vm.projectTypeName)+" ")])],1)],1),_c('b-row',{staticClass:"newproject-date-section"},[_c('b-col',{staticClass:"pl-0",attrs:{"cols":"4"}},[_c('div',{staticClass:"total-spent time-log-section projectSpent"},[_c('p',{staticClass:"userhour-log text-left"},[_vm._v(" Time Spent ")]),_c('p',{staticClass:"user-totalhour text-left"},[_vm._v(" "+_vm._s(_vm.projectTotalTimeSpend)+" ")])])]),_c('b-col',{staticClass:"pr-0",attrs:{"cols":"4"}},[(_vm.isEdit)?_c('div',{staticClass:"total-spent projectSpent"},[_c('p',{staticClass:"newsummary-editableinput-title text-left"},[_vm._v(" Time Estimated "),(_vm.isEdit)?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e()]),(_vm.isEdit)?_c('validation-provider',{attrs:{"name":"Time estimated ","rules":{ required: true, numeric: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"newsummary-editableinput-input",attrs:{"id":"basicInput","type":"number","value":"ZT","placeholder":"Estimated time.."},model:{value:(_vm.time_estimated),callback:function ($$v) {_vm.time_estimated=$$v},expression:"time_estimated"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3784877346)}):_vm._e()],1):_vm._e(),(!_vm.isEdit)?_c('div',{staticClass:"total-spent projectSpent",class:_vm.projectDtl.time_spent_minutes / 60 >
                    _vm.projectDtl.time_estimated
                    ? 'maxTime-log'
                    : 'minTime-log'},[_c('p',{staticClass:"userhour-log text-left"},[_vm._v(" Max Time Planned ")]),_c('p',{staticClass:"user-totalhour text-left"},[_vm._v(" "+_vm._s(_vm._f("hoursInTimeFormatForSummary")(_vm.projectDtl.time_estimated))+" ")])]):_vm._e()])],1),_c('b-row',{staticClass:"newproject-date-section"},[_c('b-col',{staticClass:"total-spent time-log-section projectSpent",attrs:{"cols":"4"}},[_c('div',{staticClass:"total-spent projectSpent"},[_c('p',{staticClass:"userhour-log text-left"},[_vm._v(" Time Billable ")]),_c('p',{staticClass:"user-totalhour text-left"},[_vm._v(" "+_vm._s(_vm.projectTimeBillable)+" ")])])]),_c('b-col',{staticClass:"total-spent projectSpent minTime-log",attrs:{"cols":"4"}},[_c('p',{staticClass:"newsummary-editableinput-title"},[_vm._v(" Project Code "),(_vm.isEdit)?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e()]),(_vm.isEdit)?_c('validation-provider',{attrs:{"name":"Project code","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-input',{staticClass:"newsummary-editableinput-input",attrs:{"id":"basicInput","value":"ZT","placeholder":"Enter code","readonly":""},model:{value:(_vm.code),callback:function ($$v) {_vm.code=$$v},expression:"code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2722160488)}):_c('p',{staticClass:"project-input"},[_vm._v(" "+_vm._s(_vm.projectDtl.code)+" ")])],1)],1)],1),_c('b-col',{attrs:{"cols":"12","lg":"2"}},[_c('div',{attrs:{"id":"chart"}},[_c('apexchart',{attrs:{"type":"pie","width":"280","options":_vm.chartOptions,"series":_vm.series}})],1)])],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }