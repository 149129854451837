<template>
  <div>
    <div class="addtask defect-modal">
      <b-modal
        :id="id"
        cancel-variant="outline-secondary"
        centered
        size="sm"
        class="addtask-modal"
        :no-close-on-backdrop="true"
        hide-header-close
        @hidden="closeModal"
      >
        <template #modal-title>
          <h3 class="form-title">
            <span> {{ $store.state.app.testActiveName }} Suite</span>
          </h3>
        </template>

        <validation-observer ref="groupObserver">
          <b-row class="addtask-content">
            <b-col cols="12">
              <b-form-group
                label="Suite Name"
                label-for="groupname"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Suite Name"
                  rules="required|min:3|max:256"
                >
                  <b-form-input
                    id="groupname"
                    v-model="suiteName"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Suite Name"
                    name="Suite name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Group Member -->
            <b-col
              cols="12"
              class="modal-select"
            >
              <b-form-group
                label="Parent Suite"
                label-for="ParentSuite"
              >
                <validation-provider name="ParentSuite">
                  <v-select
                    id="ParentSuite"
                    v-model="parent_suite_id"
                    class="select-size-lg"
                    name="suite_name"
                    placeholder="Select parent suite"
                    :options="suiteListOption"
                    :reduce="(s) => s.id"
                  />
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
        <div class="text-right">
          <b-button
            class="save-btn mr-1"
            :disabled="loader"
            @click="validationForm"
          >
            <b-spinner
              v-if="loader"
              small
              class="spinner"
            />
            {{ editId ? "Edit" : "Create" }}
          </b-button>
          <b-button
            variant="secondary"
            class="cancelBtn "
            @click="$bvModal.hide(id)"
          >
            Cancel
          </b-button>

        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import {
  BButton,
  BModal,

  BFormInput,
  BFormGroup,
  BRow,
  BCol,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { eventBus } from '../../../main'

export default {
  name: 'CreateSuiteForm',
  components: {
    ValidationProvider,
    ValidationObserver,
    BButton,
    BModal,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
  },
  props: ['id'],
  data() {
    return {
      suiteName: null,
      parent_suite_id: null,
      btnLoader: false,
      suiteList: null,
      loader: false,
      editId: null,
    }
  },
  computed: {
    suiteListOption() {
      let { suiteList } = this.$store.state.app
      suiteList.forEach(element => {
        element.label = element.suite_name
        element.value = element.id
      })
      if (!this.$store.state.app.testSuite.parent_suite_id) {
        suiteList = suiteList.filter(
          e => e.id != this.$store.state.app.testSuite.id,
        )
      }
      return suiteList
    },
  },
  mounted() {
    if (this.$store.state.app.testSuite) {
      const res = this.$store.state.app.testSuite
      this.editId = res.edit_id
      this.suiteName = res.suite_name
      this.parent_suite_id = res.parent_suite_id ? res.parent_suite_id : null
    }
  },
  methods: {
    /**
     *  clear from detail
     */
    clearForm() {
      this.suiteName = null
      this.btnLoader = false
      this.suiteList = []
      this.parent_suite_id = null
      this.$store.state.app.suiteId = null
      this.editId = null
      this.$store.state.app.testSuite.id = null
      this.$store.state.app.testSuite.suite_name = null
      this.$store.state.app.testSuite.parent_suite_id = null

      this.$store.state.app.suiteId = null
    },
    /**
     *  check the form validation
     * @returns if success true then call saveGroupDtl Method
     */
    validationForm() {
      this.$refs.groupObserver.validate().then(success => {
        if (success) {
          this.editId ? this.updateSuites() : this.createNewSuites()
        }
      })
    },

    /**
     * Save the suites
     */
    async createNewSuites() {
      this.loader = true
      const input = {
        suite_name: this.suiteName,
        project_id: this.$route.params.id3,
        parent_id: this.parent_suite_id ? this.parent_suite_id : null,
      }
      const response = await this.getHTTPPostResponse(
        'project/test-suite/create',
        input,
        true,
      )

      if (response && response.status == 200) {
        this.$store.state.app.testSuite.search = null
        this.$root.$emit('bv::toggle::modal', this.id)
        eventBus.$emit('clearSuiteSearch', true)
        eventBus.$emit('reloadSuiteList', true)
      }
      this.loader = false
    },

    /**
     * Update the suites
     */
    async updateSuites() {
      this.loader = true
      const input = {
        suite_name: this.suiteName,
        project_id: this.$route.params.id3,
        parent_id: this.parent_suite_id ? this.parent_suite_id : null,
      }
      const response = await this.getHTTPPutResponse(
        `project/test-suite/update/${this.editId}`,
        input,
        true,
      )

      if (response && response.status == 200) {
        this.$root.$emit('bv::toggle::modal', this.id)
        eventBus.$emit('reloadSuiteList', true)
      }
      this.loader = false
    },

    /** closeModal */
    closeModal() {
      this.clearForm()
    },
  },
}
</script>
<style lang="scss" scoped>
@import "../../../assets/scss/component-css/tesing.css";
</style>
