<template>
  <div>
    <div
      :class="$store.state.appConfig.layout.type == 'full'
        ? 'card-filter-section board-filter-parent m-0'
        : 'card-filter-section board-filter-parent'
      "
    >
      <div class="d-flex align-items-center flex-wrap justify-content-end">
        <div class="d-flex align-items-center justify-content-end w-100 flex-wrap">
          <div class="d-flex flex-wrap align-items-center mr-4">
            <custom-dropdown
              v-if="!nonBoard"
              v-model="projectId"
              :options="$store.state.app.productiveProjectList"
              :label="projectDtl.name"
            />
            <Layoutchange
              v-if="!nonBoard"
              :type="'board'"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      style="    display: flex;
    height: calc(100vh - 150px);"
    >
      <img
        src="@/assets/images/nodatafound/nodata.svg"
        alt="no-data"
        class="no-data-img"
      >
    </div>
  </div>
</template>

<script>
import customDropdown from '@/components/dropdown/customDropdown.vue'
import Layoutchange from '@/components/layOutChange/LayOutChange.vue'

export default {
  name: 'BoardFilter',
  components: {
    customDropdown,
    Layoutchange,
  },
  props: {
    projectDtl: {
      required: true,
      default: null,
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/variables/_variables.scss";

.card-filter-section {
    background-color: $white;
    margin: 16px 0px 6px 0px;
    box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
    padding: 10px 15px;

    .filter-title {
        color: $logo-color;
        font-size: 2.25 * $rem;
        font-weight: $fw500;
    }

    .selectmember {
        cursor: pointer;
        margin-left: 20px;
        background-color: transparent;
        border-color: transparent;
        color: $logo-color;
        display: flex;
        align-items: center;
        position: relative;

        @media (max-width: map-get($mediaSizes , "xl")) {
            margin-left: 18px;
        }

        @media (max-width: map-get($mediaSizes , "lg")) {
            margin-left: 0;
            margin: 7px 0;
        }

        span {
            font-size: 1.75 * $rem;
            font-weight: $fw500;
            text-transform: capitalize;
            line-height: 23px;
        }

        .feather-edit {
            margin-right: 8px;
        }

        .feather-chevron-down {
            margin-left: 8px;
        }

        .badge-pill-filter {
            position: absolute;
            top: -12px;
            right: 13px;
            display: flex;
            height: 20px;
            width: 20px;
            align-items: center;
            justify-content: center;
            padding: 0;
            background-color: rgba(255, 159, 67, 0.7);
        }
    }

    .radio-dropdown {
        position: absolute;
        z-index: 111;
    }

    .apply-filter {
        background-color: $secondary-color;
        border: 1px solid $secondary-color;
        color: $white;
        padding: 8px 11px;
        border-radius: 5px;
        font-size: 2 * $rem;
        font-weight: $fw600;

        @media (max-width: map-get($mediaSizes , "xxl")) {
            font-size: 2 * $rem;
        }

        @media (max-width: map-get($mediaSizes , "xl")) {
            font-size: 1.75 * $rem;
        }

        @media (max-width: map-get($mediaSizes , "lg")) {
            font-size: 1.5 * $rem;
        }
    }

    .apply-filter-clear {
        border: 1px solid $secondary-color;
        color: $secondary-color;
        padding: 8px 20px;
        border-radius: 5px;
        font-size: 2 * $rem;
        font-weight: $fw600;
        background-color: transparent;

        @media (max-width: map-get($mediaSizes , "xxl")) {
            font-size: 2 * $rem;
        }

        @media (max-width: map-get($mediaSizes , "xl")) {
            font-size: 1.75 * $rem;
        }

        @media (max-width: map-get($mediaSizes , "lg")) {
            font-size: 1.5 * $rem;
        }
    }

    .search-project {
        border-radius: 5px;
        color: $dark-gray;

        &:focus-visible {
            outline: $primary-color;
        }

        .form-control {
            border-color: $input-outline;
            padding: 6px 0 6px 20px;
            color: $logo-color !important;
            height: 33px;

            &::placeholder {
                color: $dark-gray;
                font-size: 1.5 * $rem;
                font-weight: $fw500;
            }
        }
    }
}

.calendar {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: map-get($mediaSizes , "lg")) {
        flex-wrap: wrap;
        margin-top: 20px;
        width: 100%;
        margin-bottom: 5px;
    }

    .date-select {
        display: flex;
        align-items: center;
        margin-right: 55px;

        @media all and (max-width: 1320px) {
            margin-right: 0px;
        }

        .calender-prev-next {
            margin-right: 10px;
            background-color: rgba(33, 120, 251, 0.07);
            color: #005eff;
            cursor: pointer;
            font-weight: $fw600;
            height: 40px;
            width: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 5px;

            @media (max-width: map-get($mediaSizes , "md")) {
                margin-bottom: 10px;
            }
        }

        .btn.dropdown-toggle {
            color: #000 !important;
        }

        .calendar-next {
            margin-left: 10px;
        }

        .date-dropdown {
            position: relative;

            .date-picker {
                background-color: rgba(33, 120, 251, 0.07);
                padding: 12px 22px 12px 22px;
                cursor: pointer;
                display: flex;
                align-items: center;
                border-radius: 5px;
                font-size: 1.75 * $rem;
                line-height: 24px;
                font-weight: $fw600;
                position: relative;
                width: 320px;

                @media (max-width: map-get($mediaSizes , "md")) {
                    margin-bottom: 5px;
                    width: 320px !important;
                }

                button {
                    text-align: center !important;

                    &.dropdown-toggle {
                        color: $black-color !important;
                        size: 1.75 * $rem;
                        font-weight: $fw600;
                        line-height: 24px;
                        border: none !important;
                    }
                }

                .dropdown-menu {
                    .calendar-icon {
                        display: block;
                    }
                }
            }

            .calendar-icon {
                color: #005eff;
                position: absolute;
                top: 16px;
                right: 0px;
                margin-right: 14px;
            }

            .report-date-picker {
                width: 350px;

                @media (max-width: map-get($mediaSizes , "md")) {
                    width: 250px;
                }
            }
        }
    }

    .timesheet-select-project {
        padding: 2px;

        .vs__dropdown-toggle {
            border: 1px solid $input-outline;
        }

        .vs__open-indicator {
            fill: $logo-color;
        }

        .vs__selected {
            background-color: $secondary-color;
            color: $white;
            font-weight: $fw500;
            border-color: $secondary-color;
            font-size: 12px;
        }

        .vs__deselect {
            fill: $white;
        }

        .vs__search {
            &::placeholder {
                color: $font-color;
            }
        }

        .vs__dropdown-menu {
            border-radius: 10px;
            scroll-behavior: smooth;
            scrollbar-width: thin;

            &::-webkit-scrollbar {
                width: 5px;
                height: 5px;
                background-color: transparent;

                & ::-webkit-scrollbar-track {
                    box-shadow: inset 0 0 2px rgba(184, 184, 184, 0.3);
                    background-color: transparent;
                    border-radius: 10px;
                }
            }

            &::-webkit-scrollbar-thumb {
                // box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
                box-shadow: inset 0 0 5px rgba(184, 184, 184, 0.3);
                border-radius: 10px;
                background-color: $scroller-color;
            }

            .vs__dropdown-option {
                text-transform: capitalize;
                font-weight: $fw500;
                line-height: 28px;
                width: 237px;
                display: inline-block;
                white-space: nowrap;
                overflow: hidden !important;
                text-overflow: ellipsis;

                &:hover {
                    color: $primary-color;
                    background-color: $blue;
                }
            }
        }
    }

    .timesheet-select-user {
        padding: 2px;

        .vs__open-indicator {
            fill: $logo-color;
        }

        .vs__clear {
            fill: $logo-color;
        }

        .vs__dropdown-toggle {
            border: 1px solid $input-outline;
        }

        .vs__dropdown-menu {
            border-radius: 10px;
            scroll-behavior: smooth;
            scrollbar-width: thin;

            &::-webkit-scrollbar {
                width: 5px;
                height: 5px;
                background-color: transparent;

                & ::-webkit-scrollbar-track {
                    box-shadow: inset 0 0 2px rgba(184, 184, 184, 0.3);
                    background-color: transparent;
                    border-radius: 10px;
                }
            }

            &::-webkit-scrollbar-thumb {
                // box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
                box-shadow: inset 0 0 5px rgba(184, 184, 184, 0.3);
                border-radius: 10px;
                background-color: $scroller-color;
            }

            .vs__dropdown-option {
                text-transform: capitalize;
                font-weight: $fw500;
                line-height: 28px;

                &:hover {
                    color: $primary-color;
                    background-color: $blue;
                }
            }
        }

        .vs__selected {
            color: $logo-color;
            font-weight: $fw400;
        }

        .vs__search {
            &::placeholder {
                color: $font-color;
            }
        }
    }
}

.calendar-custom-view {
    border-radius: 10px;
    position: absolute;
    top: 10px;
    left: -37px;
    z-index: 11111111;
    width: 700px;
    box-shadow: -4px -4px 12px rgba(0, 0, 0, 0.1), 4px 4px 12px rgba(0, 0, 0, 0.1) !important;

    @media (max-width: map-get($mediaSizes , "md")) {
        width: calc(100vw - 80%);
        left: 0px;
    }

    @media (max-width: map-get($mediaSizes , "xs")) {
        width: 300px;
    }

    &.timesheet-calendar-view {
        width: 468px;
    }

    .btn {
        &.day-btn {
            width: 193px;
            margin-bottom: 10px;
            text-align: left;
            border-radius: 5px;
            font-size: 1.75 * $rem;
            line-height: 24px;
            font-weight: $fw500;
            color: $black-color !important;
            border-color: $input-outline !important;

            @media (max-width: map-get($mediaSizes , "lg")) {
                width: 100%;
                margin-right: 10px;
                font-size: 1.5 * $rem;
                padding: 10px;
                height: 40px;
                line-height: 14px;
                margin-bottom: 15px;
            }

            &:focus {
                background-color: $primary-color;
                color: $white !important;
            }
        }
    }

    // .btn-outline-secondary {
    //   &:active {
    //     background-color: $primary-color !important;
    //     color: $white !important;
    //   }
    // }
    .submit-button {
        .btn {
            &.side-bar-fillter-calendar {
                border-color: $secondary-color !important;
                margin-right: 10px;
                padding: 9px 25px;
                color: $white !important;
                font-size: 1.75 * $rem;
                font-weight: $fw600;
                background-color: $secondary-color !important;

                &:focus {
                    background-color: $secondary-color !important;
                    color: $white !important;
                }
            }
        }

        .side-bar-clear-calendar {
            background-color: transparent !important;
            padding: 9px 25px;
            color: $secondary-color !important;
            border: 1px solid $secondary-color !important;
            border-radius: 5px;
            font-size: 1.75 * $rem;
            font-weight: $fw600;

            @media (max-width: map-get($mediaSizes , "lg")) {
                // margin-bottom: 10px;
            }
        }
    }

    .flatpickr-calendar {
        border-radius: 10px;
        border: 1px solid $input-outline;
        box-shadow: none !important;
        font-size: 1.875 * $rem;
        color: $black-color !important;
        font-weight: $fw500 !important;
        line-height: 25px;
        width: 422px;
        padding: 30px 30px 30px 20px;

        @media (max-width: map-get($mediaSizes , "lg")) {
            width: 100%;
            padding: 12px 12px 12px 10px;
        }

        @media (max-width: map-get($mediaSizes , "xs")) {
            width: 270px;
            padding: 0;
        }

        .flatpickr-innerContainer {
            width: 100%;
        }

        .flatpickr-rContainer {
            width: 100%;
        }

        .dayContainer {
            width: 372px !important;
            max-width: 372px;
            min-width: 372px;

            @media (max-width: map-get($mediaSizes , "xs")) {
                width: 270px;
                max-width: 270px;
                min-width: 270px;
            }

            @media (max-width: map-get($mediaSizes , "lg")) {
                width: 100%;
                max-width: 100%;
                min-width: 100%;
            }
        }

        .flatpickr-days {
            width: 372px;
            font-weight: $fw500 !important;

            @media (max-width: map-get($mediaSizes , "xs")) {
                width: 270px;
            }

            @media (max-width: map-get($mediaSizes , "lg")) {
                width: 100%;
            }
        }

        .flatpickr-day {
            width: 14.28%;
            max-width: unset;
            font-size: 1.875 * $rem;
            font-weight: $fw500;
            height: 46px;
            line-height: 45px;
            flex-basis: 13.285714%;

            &:hover {
                background: $blue;
                border-color: $blue;
            }
        }

        .selected {
            background: $primary-color;
            color: $white !important;
            border-color: $primary-color;
            font-weight: $fw600 !important;

            // box-shadow: -10px 0 0 $primary-color !important;
            &:hover {
                // box-shadow: -5px 0 0 $primary-color, 5px 0 0 $primary-color;
                border-color: $primary-color;
                background-color: $primary-color;
            }
        }

        .today {
            border-color: $dark-gray;
            background-color: $dark-gray;
            color: $white;

            &:hover {
                color: $white;
                background-color: $dark-gray;
            }
        }

        .inRange {
            background-color: $primary-color !important;
            color: $white !important;
            box-shadow: -5px 0 0 $primary-color, 5px 0 0 $primary-color;
            border-color: $primary-color;
            font-weight: $fw600 !important;

            &:hover {
                box-shadow: -5px 0 0 $primary-color, 5px 0 0 $primary-color;
                border-color: $primary-color;
                background-color: $primary-color !important;
            }
        }

        .endRange {
            background: $primary-color;
            color: $white !important;
            border-color: $primary-color !important;
            box-shadow: -10px 0 0 $primary-color !important;

            &:hover {
                // box-shadow: -5px 0 0 $primary-color, 5px 0 0 $primary-color;
                border-color: $primary-color;
                background-color: $primary-color;
            }
        }

        .startRange {
            background: $primary-color;
            color: $white !important;
            border-color: $primary-color;
            box-shadow: none !important;

            &:hover {
                // box-shadow: -5px 0 0 $primary-color, 5px 0 0 $primary-color;
                border-color: $primary-color;
                background-color: $primary-color !important;
            }
        }

        .flatpickr-current-month {
            .flatpickr-monthDropdown-months {
                font-weight: 700;
                padding: 0;
                font-size: 2.25 * $rem;
                line-height: 28px;
                text-align: center;

                &:hover {
                    background-color: transparent;
                }
            }

            input {
                &.cur-year {
                    font-weight: 700;
                    padding: 0;
                    font-size: 2.25 * $rem;
                    line-height: 28px;
                }
            }

            .numInputWrapper:hover {
                background-color: transparent;
            }
        }

        .flatpickr-prev-month {
            margin-left: 50px;

            @media (max-width: map-get($mediaSizes , "xs")) {
                margin-top: 0 !important;
                margin-left: 20px;
            }
        }

        .flatpickr-next-month {
            margin-right: 50px;

            @media (max-width: map-get($mediaSizes , "xs")) {
                margin-top: 0 !important;
                margin-right: 20px;
            }
        }
    }
}

.flatpickr-input {
    display: none !important;
}

.flatpickr-innerContainer {
    margin-top: 20px;

    span.flatpickr-weekday {
        font-weight: 400;
        font-size: 12px;
        text-transform: uppercase;
        line-height: 18px;
        color: #2178fb;
    }
}

.flatpickr-calendar .flatpickr-day.flatpickr-disabled,
.flatpickr-calendar .flatpickr-day.prevMonthDay,
.flatpickr-calendar .flatpickr-day.nextMonthDay {
    color: #bdbdbd;
}

.flatpickr-calendar .flatpickr-day {
    color: #333333;
}

.calendar-custom-view .flatpickr-calendar .flatpickr-day {
    width: 40px;
    height: 40px;
    line-height: 40px;
}
</style>
