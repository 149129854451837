<template>
  <div>
    <div
      v-if="project_dtl"
      v-show="$store.state.appConfig.layout.type != 'full'"
      class="summary-header"
    >
      <div class="d-flex align-items-center summary-projectnew">
        <b-avatar
          v
          :text="project_dtl.name | avatarText"
          size="40"
          class="summary-projectnew-projectlogo"
          :style="{ 'background-color': '#' + project_dtl.color }"
        />
        <p
          class="summary-projectnew-title"
          :style="{ color: '#' + project_dtl.color }"
        >
          {{ project_dtl.name }}
        </p>
        <custom-dropdown
          v-model="test_project_id"
          :options="$store.state.app.productiveProjectList"
          :label="''"
          :hide-close="true"
          :show-count="false"
        />
        <!-- icon="EditIcon" -->
      </div>
      <div class="button-groups align-items-center d-flex">
        <!-- Summary -->
        <a
          :class="[pTab == 'summary' ? 'summary-tabs active' : 'summary-tabs']"
          class="btn"
          role="button"
          data-bs-toggle="button"
          :href="newTabUrl('summary')"
          @click.prevent="reDirectTab('summary')"
        >

          <feather-icon
            icon="FileTextIcon"
            class="summary-icon"
          />
          <span class="summary-item">Summary</span>
        </a>

        <!-- Team -->
        <a
          :class="[pTab == 'team' ? 'summary-tabs active' : 'summary-tabs']"
          :href="newTabUrl('team')"
          @click.prevent="reDirectTab('team')"
        >
          <feather-icon
            icon="UsersIcon"
            size="15"
            class="summary-icon"
          />
          <span class="summary-item">Team</span>
        </a>

        <!-- <span class="line"></span> -->
        <!-- Task List -->
        <a
          class="summary-tabs"
          :class="[pTab == 'tasklist' ? 'summary-tabs active' : 'summary-tabs']"
          :href="newTabUrl('tasklist')"
          target="_blank"
          @click.prevent="reDirectTab('tasklist')"
        >
          <feather-icon
            icon="ListIcon"
            class="summary-icon"
          />
          <span class="summary-item"> List</span>
        </a>
        <!-- Board -->
        <a
          v-if="project_dtl && project_dtl.active_sprint"
          :class="[
            pTab == 'board'
              ? 'summary-tabs-activeboard active'
              : 'summary-tabs-board',
          ]"
          class="summary-tabs"
          :href="newTabUrl('board')"
          @click.prevent="reDirectTab('board')"
        >
          <span class="summary-item">Board</span>
        </a>
        <!-- Master Plan List -->
        <a
          :class="[
            pTab == 'master-plan' ? 'summary-tabs active' : 'summary-tabs',
          ]"
          :href="newTabUrl('master-plan')"
          @click.prevent="reDirectTab('master-plan')"
        >
          <feather-icon
            icon="AlignLeftIcon"
            class="summary-icon"
          />
          <span class="summary-item">Master Plan</span>
        </a>
        <a
          :class="[pTab == 'qassure' ? 'summary-tabs active' : 'summary-tabs']"
          :href="newTabUrl('qassure')"
          @click.prevent="reDirectTab('qassure')"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path
              fill="currentColor"
              fill-rule="evenodd"
              d="m15.65 4.263l.813.101a3.75 3.75 0 0 1 3.287 3.721v10.49a3.641 3.641 0 0 1-3.191 3.613c-3.028.377-6.09.377-9.118 0a3.641 3.641 0 0 1-3.191-3.613V8.085a3.75 3.75 0 0 1 3.287-3.72l.813-.102A2.751 2.751 0 0 1 11 2.25h2a2.75 2.75 0 0 1 2.65 2.013Zm-7.4 1.524l-.528.066A2.25 2.25 0 0 0 5.75 8.085v10.49c0 1.08.805 1.991 1.876 2.125a35.39 35.39 0 0 0 8.747 0a2.141 2.141 0 0 0 1.877-2.125V8.085a2.25 2.25 0 0 0-1.972-2.232l-.528-.066V7a.75.75 0 0 1-.75.75H9A.75.75 0 0 1 8.25 7V5.787ZM9.75 5c0-.69.56-1.25 1.25-1.25h2c.69 0 1.25.56 1.25 1.25v1.25h-4.5V5Z"
              clip-rule="evenodd"
            />
            <path
              fill="currentColor"
              d="M15.75 11.75A.75.75 0 0 0 15 11H9a.75.75 0 0 0 0 1.5h6a.75.75 0 0 0 .75-.75Zm-1 3A.75.75 0 0 0 14 14H9a.75.75 0 0 0 0 1.5h5a.75.75 0 0 0 .75-.75Z"
            />
          </svg>

          <span class="summary-item">QAssure</span>
        </a>

        <a
          class="summary-tabs"
          :class="[
            pTab == 'note' ? 'summary-tabs-note active' : 'summary-tabs-notes',
          ]"
          :href="newTabUrl('note')"
          @click.prevent="reDirectTab('note')"
        >
          <span class="summary-item">Notes</span>
        </a>
        <button
          class="back-button btn btn-secondary mx-2"
          @click="Back()"
        >
          <feather-icon
            icon="ArrowLeftIcon"
            width="18"
            height="18"
          />
          <span>Back</span>
        </button>
        <Help
          v-show="$store.state.app.userManualList"
          ref="childComponentRef"
          :code="helpCode"
        />
      </div>
    </div>
    <loader
      v-if="loader"
      :row-num="3"
    />
    <div v-if="project_dtl && !loader">
      <!-- project Summary -->
      <project-summary-view
        v-if="pTab == 'summary'"
        :project-dtl="project_dtl"
        :spend-hours-detail="spendHoursDetail"
      />
      <!-- project task list -->
      <list
        v-if="pTab == 'tasklist'"
        :project-dtl="project_dtl"
      />
      <!-- project task board -->
      <board
        v-if="pTab == 'board' && project_dtl.active_sprint"
        :project-dtl="project_dtl"
        :from="'projectDetail'"
      />

      <BoardNotFound
        v-if="pTab == 'board' && (project_dtl && !project_dtl.active_sprint) && $store.state.appConfig.layout.type == 'full'"
        :project-dtl="project_dtl"
      />
      <notes
        v-if="pTab == 'note'"
        :project-dtl="project_dtl"
      />
      <!-- Master Plan List -->
      <project-master-plan
        v-if="pTab == 'master-plan'"
        :project-dtl="project_dtl"
      />

      <!--PROJECT MEMBER LIST-->
      <project-member-list-vue
        v-if="pTab == 'team'"
        :project-dtl="project_dtl"
      />
      <Testing
        v-if="pTab == 'qassure'"
        :project-dtl="project_dtl"
      />
    </div>
    <img
      v-if="!project_dtl && !loader"
      src="@/assets/images/nodatafound/nodata.svg"
      alt="no-data"
      class="no-data-img w-100"
    >
  </div>
  <!-- </div> -->
</template>

<script>
import Loader from '@/components/loaders/timesheet-loader.vue'
import { eventBus } from '@/main'
import Help from '@/components/HelpDesk/Help.vue'
import customDropdown from '@/components/dropdown/customDropdown.vue'
import ProjectSummaryView from './projectsummary/ProjectSummaryView.vue'
import ProjectMasterPlan from './Masterplan/ProjectMasterPlan.vue'
import List from './List/list.vue'
import Board from './Board/board.vue'
import projectMemberListVue from './ProjectMember/projectMemberList.vue'
import Testing from './TestingModule/index.vue'
import Notes from '../notes/Notes.vue'
import BoardNotFound from './BoardNotFound.vue'

export default {
  name: 'ProjectSummaryHeader',
  components: {
    Loader,
    ProjectSummaryView,
    ProjectMasterPlan,
    List,
    Board,
    projectMemberListVue,
    Notes,
    Testing,
    Help,
    BoardNotFound,
    customDropdown,
  },
  data() {
    return {
      parPage: this.$route.params.perPage,
      loader: false,
      project_dtl: null,
      pTab: '',
      id: this.$route.params.id,
      summary: false,
      masterplan: false,
      tasklist: false,
      board: false,
      helpCode: 'Summary',
      test_project_id: this.$route.params.id3 ?? null,
      spendHoursDetail: {
        billable_total: 0,
        non_billable_total: 0,
        total_time: 0,
      },
    }
  },
  watch: {
    // Main layout color blank pass eventbas layoutContentRenderDefault
    pTab(val) {
      if (val !== 'board') {
        eventBus.$emit('backgroundColor', 'backgroundColor')
      }
      switch (this.pTab) {
        case 'summary':
          this.helpCode = 'Summary'
          break
        case 'team':
          this.helpCode = 'Team'
          break
        case 'tasklist':
          this.helpCode = 'List'
          break
        case 'board':
          this.helpCode = 'PB'
          break
        case 'master-plan':
          this.helpCode = 'MasterPlan'
          break
        case 'qassure':
          this.helpCode = 'QAssure'
          break
        case 'note':
          this.helpCode = 'Notes'
          break
        default:
          this.helpCode = 'Summary'
          break
      }

      if (this.$refs.childComponentRef) this.$refs.childComponentRef.currentPageDisplay(this.helpCode)
      this.$store.state.app.backAction = null
      this.$store.state.app.pageShow = 'test-suite'
    },

    '$route.params.id': function () {
      this.pTab = this.$route.params.id
    },

    test_project_id(nv) {
      if (nv) {
        this.getProjectDetail(nv)
      }
    },
  },
  mounted() {
    if (this.$route.params.id3) {
      this.$store.commit('app/UPDATE_PROJECT_ID', this.$route.params.id3)
    }

    eventBus.$on('reloadProjectDtl', data => {
      if (data) {
        this.loader = true
        this.getProjectDetail(this.$route.params.id3)
      }
    })

    eventBus.$on('callProjectDetailApi', data => {
      if (data) {
        this.loader = true
        this.projectId = this.$route.params.id3
        this.getProjectDetail(this.$route.params.id3)

        if (this.$route.params && this.$route.params.id4 == 'test-suite') {
          eventBus.$emit('callTestSuiteApi', true)
        }
      }
    })

    eventBus.$on('activeListOpen', data => {
      if (data) {
        this.pTab = 'tasklist'
      }
    })

    eventBus.$on('qassureTabOpen', data => {
      if (data) {
        this.pTab = this.$route.params.id
      }
    })
    this.pTab = this.$route.params.id
    this.getProjectsData()
    this.loader = true
    this.getProjectDetail(this.$route.params.id3)
    /** on refresh same fulllayout in board & qassure* */
    if (
      (this.$route.params
        && this.$route.params.id
        && (this.$route.params.id == 'board'
          || this.$route.params.id == 'qassure'))
    ) {
      if (
        this.$route.query
        && this.$route.query.layout
        && this.$route.query.layout == 'full'
      ) {
        const navbar = {
          type: 'full',
        }
        this.$store.commit('appConfig/UPDATE_LAYOUT_TYPE', 'full')
        this.$store.commit('appConfig/UPDATE_NAVBAR_CONFIG', navbar)
      }
    }
  },
  // Main layout color blank pass eventbas layoutContentRenderDefault
  beforeRouteLeave(to, from, next) {
    setTimeout(() => {
      eventBus.$emit('backgroundColor', 'backgroundColor')
    }, 300)
    next()
  },

  destroyed() {
    eventBus.$off('reloadProjectDtl')
    eventBus.$off('activeListOpen')
    eventBus.$off('qassureTabOpen')
  },
  methods: {
    reDirectTab(id) {
      this.pTab = id
      this.$router.push({
        name: 'projectTask',
        params: {
          id: this.pTab,
          id2: this.project_dtl.slug,
          id3: this.$route.params.id3,
          id4: id == 'qassure' ? 'test-suite' : null,
          page_name: this.$route.params.page_name,
        },
      }).catch(err => {
        // Ignore the NavigationDuplicated error
        if (err.name !== 'NavigationDuplicated') {
          console.log(err)
        }
      })
    },

    /** Open in New tab */
    newTabUrl(tab) {
      const { params } = this.$route
      let href = this.$route.path.replace(params.id, tab)

      if (tab === 'qassure' && !params.id4) {
        href += '/test-suite'
      } else if (tab !== 'qassure' && params.id4) {
        href = href.replace(`/${params.id4}`, '').replace(`/${params.id5}`, '').replace(`/${params.id6}`, '').replace(`/${params.id7}`, '')
      }

      return href || null
    },

    async getProjectDetail(id) {
      this.loader = true
      const slug = this.$route.params.id2

      const projectId = id || slug
      const response = await this.getHTTPPostResponse(
        `project/view/${projectId}`,
        {},
        false,
      )
      if (!response) {
        setTimeout(() => {
          this.$router.push({
            name: 'projects',
          }).catch(err => {
            // Ignore the NavigationDuplicated error
            if (err.name !== 'NavigationDuplicated') {
              console.log(err)
            }
          })
        }, 1000)
        return
      }
      if (response && response.status == 200 && response.data) {
        this.project_dtl = response.data.project
        this.$store.commit('app/UPDATE_PROJECT_DETAIL', this.project_dtl)

        this.test_project_id = this.project_dtl.id

        this.getProjectUser(this.project_dtl.id)
        this.getBillableAndTimeSpend(this.project_dtl.id)

        /** Set project id & slug into current route parmas */
        const { params, query } = this.$route
        params.id2 = this.project_dtl.slug
        params.id3 = this.project_dtl.id
        this.$router.replace({
          params, query,
        }).catch(err => {
          // Ignore the NavigationDuplicated error
          if (err.name !== 'NavigationDuplicated') {
            console.log(err)
          }
        })

        /** if on refresh open board then check project not active sprint not then show summary */
        if (
          this.pTab == 'board'
          && this.project_dtl
          && !this.project_dtl.active_sprint
          && this.$store.state.appConfig.layout.type != 'full'
        ) {
          const { params } = this.$route
          params.id = 'summary'
          this.$router.replace({
            params,
          }).catch(err => {
          // Ignore the NavigationDuplicated error
            if (err.name !== 'NavigationDuplicated') {
              console.log(err)
            }
          })
          this.pTab = 'summary'
        }
        // setTimeout(() => {
        //   this.$store.state.app.projectUsers = this.project_dtl.project_user
        //   this.loader = false
        // }, 300)
      } else {
        // If data not get show summary page with no data found image
        this.pTab = 'summary'
        this.loader = false
      }
    },
    Back() {
      if (this.$route.params.page_name == 'dashboard' || this.$route.query.pageName == 'dashboard') {
        this.$router.push('/').catch(err => {
        // Ignore the NavigationDuplicated error
          if (err.name !== 'NavigationDuplicated') {
            console.log(err)
          }
        })
      } else if (this.$route.params.page_name == 'q-dashboard' || this.$route.query.pageName == 'q-dashboard') {
        this.$router.push('/q-assure-dashboard').catch(err => {
        // Ignore the NavigationDuplicated error
          if (err.name !== 'NavigationDuplicated') {
            console.log(err)
          }
        })
      } else {
        this.$router.push({
          name: 'projects',
          params: {
            page_number: this.$route.params.pageNumber,
            per_page: this.$route.params.perPage,
            list_icon: this.$route.params.listIcon,
          },
        }).catch(err => {
        // Ignore the NavigationDuplicated error
          if (err.name !== 'NavigationDuplicated') {
            console.log(err)
          }
        })
      }
      eventBus.$emit('per_pages', this.parPage)
    },

    async getProjectUser(id) {
      this.loader = true
      const response = await this.getHTTPPostResponse(
        'project/user/sort-project-user-list',
        {
          project_id: id,
        },
        false,
      )
      if (response && response.status === 200) {
        const { data } = response
        this.project_dtl.project_user = data.project_users
        this.project_dtl.time_plan = data.time_plan
        this.$store.state.app.projectUsers = data.project_users
        this.$store.commit('app/UPDATE_PROJECT_DETAIL', this.project_dtl)
      }
      this.loader = false
    },

    async getBillableAndTimeSpend(id) {
      this.projectTimeLoader = true
      const input = {
        project_ids: [id],
      }
      const response = await this.getHTTPPostResponse(
        'project/project-time',
        input,
        false,
      )
      if (response && response.data) {
        const { data } = response

        if (data.projects && data.projects.length) {
          this.spendHoursDetail = {
            billable_total: data.projects[0].billable_total ?? 0,
            non_billable_total: data.projects[0].non_billable_total ?? 0,
            total_time: data.projects[0].total_time ?? 0,
          }
        }
      }
      this.projectTimeLoader = false
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/component-css/projectsummary.css";
</style>
