<template>
  <div class="task-list">
    <b-modal
      :id="id"
      cancel-variant="outline-secondary"
      centered
      size="lg"
      @hidden="closeModal"
      @show="getProjectPlanLists"
    >
      <template #modal-title>
        <h3 class="sprint-from-title">
          complete sprint
        </h3>
        <div class="complete-underline" />
      </template>
      <div>
        <validation-observer ref="sprintCompleteRules">
          <p class="incomplete mb-0">
            <span>{{ incompleteTasks }}</span>issues were incomplete
          </p>
          <p class="complete-issue mb-0">
            <span>{{ completeTasks }}</span>issues were done
          </p>
          <!-- <div
            v-if="sprints && sprints.length == 0 && !incompleteTasks"
          >
            <b-form-group

              label="Move to"
              label-for="sprintID"
              class="newsprintmove"
            >
              <validation-provider
                #default="{ errors }"
                name="Sprint"
                rules=""
              >
                <v-select
                  id="sprintID"
                  v-model="sprint_id"
                  label="title"
                  :options="sprints"
                  class="select-size-lg select-task-option"
                  name="sprint_id"
                  :reduce="(sprint) => sprint.id"
                  placeholder="Select Sprint"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div> -->
          <div
            v-if="incompleteTasks"
            class="mt-2"
          >
            <b-row>
              <!-- <b-col md="6" cols="12">
              <b-form-group>
                <label for="sprinttitle">Sprint title</label>
                <b-form-input id="sprinttitle" type="text" placeholder="Title" />
              </b-form-group>
            </b-col> -->
              <b-col
                md="6"
                cols="12"
              >
                <b-form-group
                  label="Milestone"
                  label-for="milestone"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Milestone"
                    rules="required"
                  >
                    <v-select
                      id="milestone"
                      v-model="newMilestone"
                      label="title"
                      :options="allMilestones"
                      class="select-size-lg milestone-select"
                      placeholder="Select Milestone "
                      name="milestone"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                md="6"
                cols="12"
              >
                <b-form-group
                  label="Sprint"
                  label-for="sprintID"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Sprint"
                    rules="required"
                  >
                    <v-select
                      id="sprintID"
                      v-model="sprint_id"
                      label="title"
                      :options="sprints"
                      placeholder="Select Sprint "
                      class="select-size-lg milestone-select"
                      name="sprint_id"
                      :reduce="(sprint) => sprint.id"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
          <p class="note mb-0">
            Note : Complete the task or move to the next sprint
          </p>
        </validation-observer>
      </div>

      <template #modal-footer>
        <b-button
          class="complete-btn"
          :disabled="loader"
          @click="validationForm"
        >
          <b-spinner
            v-if="loader"
            small
            class="spinner mr-25"
          /> Complete
        </b-button>
        <b-button
          class="cancel-btn"
          @click="closeModal"
        >
          Cancel
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BModal,
  BFormGroup,
  VBModal,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { eventBus } from '@/main'

export default {
  name: 'CompleteSprint',
  components: {
    BButton,
    BModal,
    BFormGroup,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    'b-modal': VBModal,
  },
  props: {
    projectDtl: {
      type: Object,
      required: true,
      default: null,
    },
    id: {
      type: String,
      required: true,
      default: 'completesprint',
    },
    incompleteTasks: {
      type: [String, Number],
      required: true,
      default: '0',
    },
    completeTasks: {
      type: [String, Number],
      required: true,
      default: '0',
    },
  },
  data() {
    return {
      loader: false,
      milestone: null,
      sprints: [],
      sprint_id: null,
      newMilestone: null,
      allMilestones: [],
    }
  },
  watch: {
    newMilestone(nv) {
      if (nv && nv.sprints) {
        this.sprint_id = null
        this.sprints = nv.sprints.length ? nv.sprints : []
      }
    },
  },
  mounted() {
    this.getProjectPlanLists()
  },
  methods: {
    async getProjectPlanLists() {
      const input = {
        project_id: this.projectDtl.id,
      }
      const response = await this.getHTTPPostResponse(
        'project/plan/milestone-sprint-lists',
        input,
        false,
      )
      if (response && response.data) {
        const data = response.data.project_plans && response.data.project_plans.length ? response.data.project_plans : []
        const sprints = []

        this.allMilestones = data

        if (this.projectDtl.active_milestone && this.projectDtl.active_sprint) {
          const milestone = data.find(m => m.id === this.projectDtl.active_milestone.id)
          if (milestone) {
            this.milestone = milestone

            if (milestone.sprints && milestone.sprints.length) {
              this.newMilestone = milestone
              milestone.sprints.forEach(element => {
                if (element.id != this.projectDtl.active_sprint.id && element.status != 'complete') {
                  sprints.push(element)
                }
              })
            }
          }
        }
        this.sprints = sprints
      }
    },
    validationForm() {
      this.$refs.sprintCompleteRules.validate().then(success => {
        if (success) {
          this.completeSprint()
        }
      })
    },
    /**
     * Move to next sprint
     */
    async completeSprint() {
      this.loader = true
      const input = {
        project_id: this.projectDtl.id,
        milestone_id: this.projectDtl.active_milestone.id,
        old_sprint_id: this.projectDtl.active_sprint.id,
        new_sprint_id: this.sprint_id,
        new_milestone_id: this.newMilestone && this.newMilestone.id ? this.newMilestone.id : null,
      }
      const response = await this.getHTTPPostResponse(
        'project/task/moveSprint',
        input,
        true,
      )
      if (response && response.status == 200) {
        eventBus.$emit('reloadProjectDtl', true)
        this.$root.$emit('bv::toggle::modal', this.id)
        this.sprint_id = null
        this.$refs.sprintCompleteRules.reset()
        this.loader = false
      }
      this.loader = false
    },

    closeModal() {
      this.newMilestone = null
      this.sprint_id = null
      this.$root.$emit('bv::hide::modal', this.id)
    },
  },
}
</script>

<style>
@import "../../../assets/scss/component-css/list.css";
</style>
