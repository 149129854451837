var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"defectObserver"},[(!_vm.loader)?_c('div',[_c('div',[_c('div',{staticClass:"card-filter-section py-1"},[_c('div',{staticClass:"d-flex align-items-center flex-wrap justify-content-between"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('button',{staticClass:"back-button btn btn-secondary mr-2",on:{"click":function($event){return _vm.back()}}},[_c('feather-icon',{attrs:{"icon":"ArrowLeftIcon","width":"18","height":"18"}}),_c('span',[_vm._v("Back")])],1),_c('p',{staticClass:"filter-title ml-50"},[_vm._v(" "+_vm._s(_vm.actionTypeCheck ? "Details" : _vm.$store.state.app.testActiveName + " Test Run")+" ")])])])])]),_c('div',{staticClass:"test-form"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{staticClass:"required-form-filed",attrs:{"label":"Run Title","label-for":"v-run-title"}},[_c('validation-provider',{attrs:{"name":"Run Title","rules":"required|max:256"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(!_vm.actionTypeCheck)?_c('b-form-input',{attrs:{"id":"v-run-title","type":"text","placeholder":"Run Title","disabled":_vm.actionTypeCheck},model:{value:(_vm.$store.state.app.testRun.run_title),callback:function ($$v) {_vm.$set(_vm.$store.state.app.testRun, "run_title", $$v)},expression:"$store.state.app.testRun.run_title"}}):_vm._e(),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1801519268)}),(_vm.actionTypeCheck)?_c('p',[_vm._v(" "+_vm._s(_vm.$store.state.app.testRun.run_title)+" ")]):_vm._e()],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('div',[_c('label',{attrs:{"for":"textarea-default"}},[_vm._v("Description")]),(!_vm.actionTypeCheck)?_c('b-form-textarea',{attrs:{"id":"textarea-default","placeholder":"Add Description","disabled":_vm.actionTypeCheck},model:{value:(_vm.$store.state.app.testRun.description),callback:function ($$v) {_vm.$set(_vm.$store.state.app.testRun, "description", $$v)},expression:"$store.state.app.testRun.description"}}):_vm._e(),(_vm.actionTypeCheck)?_c('p',[_vm._v(" "+_vm._s(_vm.$store.state.app.testRun.description)+" ")]):_vm._e()],1)])],1),_c('b-row',{staticClass:"mt-1"},[_c('b-col',{staticClass:"modal-select",attrs:{"cols":"6"}},[_c('b-form-group',{staticClass:"required-form-filed",attrs:{"label":"Plan","label-for":"Plan"}},[_c('validation-provider',{attrs:{"name":"Plan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(!_vm.actionTypeCheck)?_c('v-select',{staticClass:"select-size-lg",attrs:{"id":"Plan","options":_vm.$store.state.app.testPlan,"label":"label","placeholder":"Select Plan","name":"Plan","reduce":function (testPlan) { return testPlan.id; },"disabled":_vm.actionTypeCheck || _vm.$store.state.app.testRun.id
                      ? true
                      : false},model:{value:(_vm.$store.state.app.testRun.plan_id),callback:function ($$v) {_vm.$set(_vm.$store.state.app.testRun, "plan_id", $$v)},expression:"$store.state.app.testRun.plan_id"}}):_vm._e(),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3280115131)}),(_vm.actionTypeCheck)?_c('p',[_vm._v(" "+_vm._s(_vm.$store.state.app.testRun.plan_name)+" ")]):_vm._e()],1)],1),_c('b-col',{staticClass:"modal-select",attrs:{"cols":"6"}},[_c('b-form-group',{staticClass:"required-form-filed",attrs:{"label":"Environment","label-for":"Environment"}},[_c('validation-provider',{attrs:{"name":"Environment","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [(!_vm.actionTypeCheck)?_c('v-select',{staticClass:"select-size-lg",attrs:{"id":"Environment","options":_vm.envs,"label":"label","name":"Environment","placeholder":"Select Environment","reduce":function (env) { return env.value; },"disabled":_vm.actionTypeCheck},model:{value:(_vm.$store.state.app.testRun.env),callback:function ($$v) {_vm.$set(_vm.$store.state.app.testRun, "env", $$v)},expression:"$store.state.app.testRun.env"}}):_vm._e(),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2025606974)}),(_vm.actionTypeCheck)?_c('p',[_vm._v(" "+_vm._s(_vm.$store.state.app.testRun.env)+" ")]):_vm._e()],1)],1)],1)],1),(_vm.$store.state.app.testActiveName != 'View')?_c('div',{staticClass:"test-plan mt-1"},[_c('div',{staticClass:"my-2"},[_c('b-button',{staticClass:"mr-1 save-btn",attrs:{"type":"submit","disabled":_vm.test_plan_loader || _vm.loader},on:{"click":function($event){return _vm.validationForm()}}},[(_vm.test_plan_loader || _vm.loader)?_c('b-spinner',{staticClass:"spinner",attrs:{"small":""}}):_vm._e(),_vm._v(" "+_vm._s(_vm.$store.state.app.testActiveName && _vm.$store.state.app.testActiveName == "Edit" ? "Update" : _vm.$store.state.app.testActiveName)+" Run")],1),_c('b-button',{staticClass:"cancelBtn mr-1",attrs:{"type":"reset","disabled":_vm.test_plan_loader || _vm.loader},on:{"click":function($event){return _vm.back()}}},[_vm._v(" cancel ")])],1)]):_vm._e()],1)]):_c('TestPlanLoader')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }