<template>  
  <div class="board-shimmer-flex">
    <div class="board-card-shimmer" v-for="n in 6" :key="n">
      <div class="d-flex align-items-center justify-content-between">
        <b-skeleton class="assign"></b-skeleton>
        <b-skeleton class="tag"></b-skeleton>
      </div>
      <div class="board-shimmer" v-for="n in 4" :key="n">
        <div></div>
        <div>
          <div>
            <b-skeleton class="assign"></b-skeleton>
          </div>
  
          <div class=" ">
            <div class="teamuserName d-flex align-items-center">
              <b-skeleton class="tag"></b-skeleton>
              <b-skeleton class="tag"></b-skeleton>
              <b-skeleton class="tag"></b-skeleton>
              <b-skeleton class="tag"></b-skeleton>
              <b-skeleton class="tag"></b-skeleton>
              <b-skeleton class="tag"></b-skeleton>
            </div>
          </div>
          <b-skeleton class="assign"></b-skeleton>
          <div class="d-flex align-items-center justify-content-between">
            <div>
              <skeleton-loader-vue
                type="circle"
                :width="30"
                :height="30"
                animation="wave"
                class="mr-2"
              />
            </div>
            <div>
              <b-skeleton class="assign mb-0"></b-skeleton>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
</template>

<script>
import TextShimmer from "../../views/shimmer/TextShimmer.vue";
import LongTextShimmer from "../../views/shimmer/LongTextShimmer.vue";
import SkeletonLoaderVue from "skeleton-loader-vue";
export default {
  name: "MyteamdtlShimmer",
  components: {
    LongTextShimmer,
    SkeletonLoaderVue,
    TextShimmer,
  },
};
</script>

<style>
.tag {
  width: 40px;
  margin-right: 5px;
}
.assign {
  width: 100px;
}
.board-shimmer {
  max-width: 280px;
  padding: 16px;
  margin-bottom: 12px;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.2509803922),
    0px 0px 1px rgba(9, 30, 66, 0.3098039216);
}
.board-card-shimmer {
  background-color: #f1f2f4;
  border-radius: 12px;
  padding: 12px;
  max-width: 290px;
}
.board-shimmer-flex{
  display: flex;
  gap: 12px;
  margin-top: 20px;
}
</style>
