<template>
  <div>
    <b-col class="total-hours-dashboard">
      <div class="d-flex align-items-start justify-content-between">
        <div>
          <h3 class="mb-0 total-hours-time">
            <short-text /><short-text />
          </h3>
          <p class="time-title-dashboard">
            <short-text /></p>
        </div>

        <p><short-text /><short-text /></p>
      </div>
      <b-row
        v-for="n in 5"
        :key="n"
        class="total-hour-log"
      >
        <b-col
          cols="9"
          class="pl-0 total-hour-log-content"
        >
          <div class="d-flex">
            <text-shimmer />
          </div>
        </b-col>
        <b-col
          cols="3"
          class="total-hour-log-content text-right p-0"
        ><short-text /></b-col>

      </b-row>
    </b-col>
  </div>
</template>

<script>
import ShortText from './shortText.vue'
import TextShimmer from './TextShimmer.vue'

export default {
  name: 'TotalHourShimmer',
  components: {
    ShortText,
    TextShimmer,
  },
}
</script>

  <style>
  </style>
