<template>
  <div>
    <div
      v-if="data && (data.testCases && data.testCases.length || hasTestCasesInChildrens(data.children))"
      class="right-list"
    >
      <div class="custom-accordien">
        <b-card
          no-body
          class="mb-1"
        >
          <div>
            <div class="upper-header">
              <div
                v-b-toggle="`collapse-${id}`"
                class="custome-btn"
                @click="collapse(`collapse-${id}`)"
              >
                <img
                  src="../../../assets/images/icons/Arrow-Side.svg"
                  class="img-1"
                  style="width: 22px"
                >
                <img
                  src="../../../assets/images/icons/Arrow-Down.svg"
                  class="img-2"
                  style="width: 22px"
                >
              </div>

              <p class="custome-btn">
                {{ data.suite_name }}
              </p>
            </div>
            <div>
              <b-collapse :id="`collapse-${id}`">
                <b-card-body class="p-0">
                  <b-row>
                    <b-col
                      cols="12"
                      class="test-plan-table"
                    >
                      <b-table
                        v-if="data && data.testCases && data.testCases.length"
                        responsive
                        :items="data.testCases"
                        :fields="fields"
                        show-empty
                      >
                        <template #cell(title)="data">
                          <p
                            v-b-tooltip.hover.left
                            class="tableData cursor-pointer data-hover"
                            :title="data.item.title"
                            @click="openTestCase(data.item)"
                          >
                            {{ data.item.title }}
                          </p>
                        </template>

                        <template #cell(status)="data">
                          <div>
                            <b-button
                              :class="
                                data.item.status == 'passed'
                                  ? 'passed'
                                  : data.item.status == 'defect'
                                    ? 'defect'
                                    : 'in-progress'
                              "
                              style="cursor: default"
                            >{{
                              data.item.status
                                ? data.item.status == "defect"
                                  ? "failed"
                                  : data.item.status
                                : null
                            }}</b-button>
                          </div>
                        </template>

                        <template #cell(defects_count)="data">
                          <div>
                            {{ data.item.defects_count }}
                          </div>
                        </template>

                        <template #cell(start_time)="data">
                          <div>
                            {{
                              data.item.start_time | DateFormatFormnowOrLocal
                            }}
                          </div>
                        </template>
                        <template #cell(end_time)="data">
                          <div>
                            {{ data.item.end_time | DateFormatFormnowOrLocal }}
                          </div>
                        </template>

                        <template #cell(action1)="data">
                          <div class="d-flex align-items-center">
                            <feather-icon
                              v-if="data.item.start_time && data.item.end_time"
                              v-b-tooltip.hover.top
                              class="mr-25"
                              size="18"
                              icon="EditIcon"
                              role="button"
                              title="Edit Status"
                              @click="updateTime(data.item)"
                            />
                            <div
                              v-if="!data.item.start_time"
                              v-b-tooltip.hover.top
                              class="play-btn"
                              role="button"
                              title="Start"
                              @click="updateTime(data.item, 'start')"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="25"
                                height="24"
                                viewBox="0 0 25 24"
                                fill="none"
                              >
                                <path
                                  d="M6.22113 19.1995L6.22113 4.79911C6.22159 4.65331 6.2618 4.5104 6.33744 4.38576C6.41308 4.26111 6.52127 4.15946 6.65039 4.09173C6.7795 4.02401 6.92464 3.99278 7.07018 4.0014C7.21573 4.01003 7.35616 4.05819 7.47637 4.14069L17.8767 11.3409C18.3079 11.6393 18.3079 12.3577 17.8767 12.6569L7.47637 19.8571C7.35641 19.9405 7.21591 19.9894 7.07012 19.9984C6.92434 20.0075 6.77885 19.9765 6.64946 19.9087C6.52007 19.8409 6.41173 19.739 6.33622 19.614C6.26071 19.4889 6.2209 19.3456 6.22113 19.1995Z"
                                  fill="#5E5873"
                                />
                              </svg>
                            </div>
                            <div
                              v-if="data.item.start_time && !data.item.end_time"
                              v-b-tooltip.hover.top
                              class="pause-btn"
                              role="button"
                              title="Stop"
                              @click="updateTime(data.item)"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="25"
                                height="24"
                                viewBox="0 0 25 24"
                                fill="none"
                              >
                                <path
                                  d="M7.70007 5.25H16.7001C17.2968 5.25 17.8691 5.48705 18.2911 5.90901C18.713 6.33097 18.9501 6.90326 18.9501 7.5V16.5C18.9501 17.0967 18.713 17.669 18.2911 18.091C17.8691 18.5129 17.2968 18.75 16.7001 18.75H7.70007C7.10334 18.75 6.53104 18.5129 6.10908 18.091C5.68713 17.669 5.45007 17.0967 5.45007 16.5V7.5C5.45007 6.90326 5.68713 6.33097 6.10908 5.90901C6.53104 5.48705 7.10334 5.25 7.70007 5.25Z"
                                  fill="#5E5873"
                                />
                              </svg>
                            </div>

                            <feather-icon
                              v-b-tooltip.hover.top="'Delete'"
                              icon="Trash2Icon"
                              role="button"
                              class="text-danger ml-1"
                              size="18"
                              @click="deleteConfirmation(data.item.id)"
                            />
                          </div>
                        </template>
                      </b-table>
                    </b-col>
                  </b-row>
                </b-card-body>
                <TestRunCaseAccordion
                  v-for="child in data.children"
                  :id="child.id"
                  :key="child.id"
                  :data="child"
                  :filter-status="filterStatus"
                  :search="search"
                />
              </b-collapse>
            </div>
          </div>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import {
  BRow, BCol, BButton, BTable,
} from 'bootstrap-vue'
import { eventBus } from '@/main'

export default {
  name: 'TestRunCaseAccordion',
  components: {
    BRow,
    BCol,
    BButton,
    BTable,
    AppCollapseItem,
  },
  props: {
    data: {
      type: Object,
      required: false,
      default: [],
    },
    id: {
      type: String,
    },
    accordionId: {
      type: String,
    },
    filterStatus: {
      type: Array,
      required: false,
    },
    search: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      showSuite: false,
      fields: [
        {
          key: 'title',
          label: 'Title',
          thStyle: { minWidth: '300px', textAlign: 'start' },
        },
        {
          key: 'status',
          label: 'Status',
          thStyle: { minWidth: '200px', textAlign: 'start' },
        },
        {
          key: 'defects_count',
          label: 'Defect',
          thStyle: { minWidth: '150px', textAlign: 'start' },
        },
        {
          key: 'start_time',
          label: 'Start Date & Time',
          thStyle: { minWidth: '200px', textAlign: 'start' },
        },

        {
          key: 'end_time',
          label: 'End Date & Time',
          thStyle: { minWidth: '200px', textAlign: 'start' },
        },
        {
          key: 'action1',
          label: 'Action',
          thStyle: { minWidth: '150px', textAlign: 'start' },
        },
      ],
      testCaseDetail: null,
      loader: false,
    }
  },
  mounted() {
    this.Accordion(`collapse-${this.id}`)
  },
  methods: {
    Accordion(id) {
      if (this.$store.state.app.testCaseAccordionIds.indexOf(id) !== -1) {
        this.$root.$emit('bv::toggle::collapse', id)
      }
    },
    collapse(id) {
      if (this.$store.state.app.testCaseAccordionIds.indexOf(id) !== -1) {
        this.$store.state.app.testCaseAccordionIds.splice(
          this.$store.state.app.testCaseAccordionIds.indexOf(id),
          1,
        )
      } else {
        this.$store.state.app.testCaseAccordionIds.push(id)
      }
    },

    /**
     *  Take delete defect confirmation
     */
    deleteConfirmation(id) {
      this.$swal({
        title: 'Are you sure?',
        icon: 'info',
        html: '<p>Once you delete you will not able to recover this record.</p>',
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: 'Delete',
        confirmButtonAriaLabel: 'Thumbs up, great!',
        cancelButtonAriaLabel: 'Thumbs down',
        customClass: {
          confirmButton: 'btn confirm',
          cancelButton: 'btn cancel ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.deleteTestCase(id)
        }
      })
    },
    /**
     * Delete Defect
     */
    async deleteTestCase(id) {
      const response = await this.getHTTPDeleteResponse(
        `project/test-run/delete-test-run-case/${id}`,
        {},
        true,
      )
      if (response && response.status == 200) {
        eventBus.$emit('reloadTestCaseList')
      }
    },

    async updateTime(data) {
      eventBus.$emit('updateTestRunTime', data)
    },

    /** Check test suite have test-cases to end of parent child data */
    hasTestCasesInChildrens(children) {
      if (!children || !children.length) {
        return false
      }
      for (const child of children) {
        if (child.testCases && child.testCases.length) {
          return true // Exits test cases in current child
        }
        if (child.children && this.hasTestCasesInChildrens(child.children)) {
          return true // Exits test cases in children of current child
        }
      }
      return false // No test cases found in current child or its descendants
    },

    openTestCase(data) {
      this.$store.commit('app/UPDATE_TEST_CASE_ID_OF_RUN', data)
      this.$store.commit(
        'app/UPDATE_BACK_ACTION',
        this.$store.state.app.pageShow,
      )

      this.$store.commit('app/UPDATE_TEST_CASE_ID', data.test_case_id)

      this.$store.state.app.testActiveName = 'View'

      this.$store.state.app.pageShow = 'test-run-case'

      const params = {
        id: this.$route.params.id,
        id2: this.$route.params.id2,
        id3: this.$route.params.id3,
        id4: 'test-run',
        id5: this.$route.params.id5,
        id6: this.$route.params.id6,
        id7: data.id,
      }

      this.$router.push({
        name: 'projectTask',
        params,
        query: {
          currentPage: this.$route.query.currentPage,
          search: this.$route.query.search,
          testCaseSearch: this.search,
          status: this.filterStatus,
          layout: this.$route.query.layout,
          pageIndex: this.$route.query.pageIndex,
          test_case_id: data.test_case_id,
          pageName: this.$route.query.pageName,
        },
      }).catch(err => {
        // Ignore the NavigationDuplicated error
        if (err.name !== 'NavigationDuplicated') {
          console.log(err)
        }
      })
    },

  },
}
</script>
<style lang="scss" scoped>
@import "../../../assets/scss/component-css/tesing.css";
</style>
