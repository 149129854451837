<template>
  <!-- this form is use in project table  -->
  <div class="custom-project-form">
    <validation-observer ref="simpleRules">
      <b-sidebar
        v-model="showModal"
        id="sprintfrom"
        bg-variant="white"
        right
        backdrop
        shadow
        class="sidebar-form add-sprint"
        @hidden="sidebarToggle()"
        @change="$emit('closeAddSprint', true)"
      >
        <!--  form start -->

        <h3 class="form-title mb-1">{{ !sprintID ? "Add" : "Edit" }} Sprint</h3>

        <!-- <p class="project-name mb-1">HPV Project</p> -->
        <p class="sprint-title mb-1">
          <!-- Sprint -->
          <!--  <span class="milestone mb-1 ml-1">Milestone-1</span> -->
        </p>

        <div class="b-sidebar-header">
          <b-form-group label="Title" label-for="name">
            <b-form-input
              id="name"
              size="sm"
              rows="1"
              placeholder="Title"
              name="name"
              v-model="sprint"
            >
            </b-form-input>
          </b-form-group>
          <b-form-group label="Description" label-for="Description">
            <validation-provider #default="{ errors }" name="Description">
              <b-form-textarea
                id="Description"
                size="sm"
                rows="5"
                placeholder="Description"
                name="Description"
                v-model="description"
              >
              </b-form-textarea>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-row>
            <!-- Estimated start date input -->
            <b-col xl="6" md="12">
              <b-form-group>
                <label for="estimated_start_date">Estimated start date <span class="text-danger">*</span></label>
                <validation-provider
                  #default="{ errors }"
                  name="Estimated start date"
                  rules="required"
                >
                  <b-input-group class="input-group-merge">
                    <flat-pickr
                      class="form-control"
                      v-model="estimated_start_date"
                      placeholder="Estimated start date"
                      name="estimated_start_date"
                      @on-change="onEstimatedStartDateChange"
                      :config="configEstimatedStartDate"
                    />
                    <b-input-group-append is-text>
                      <feather-icon icon="CalendarIcon" />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Estimated end date input -->
            <b-col xl="6" md="12">
              <b-form-group>
                <label for="estimated end date">Estimated end date <span class="text-danger">*</span></label>
                <validation-provider
                  #default="{ errors }"
                  name="Estimated end date"
                  rules="required"
                >
                  <b-input-group class="input-group-merge">
                    <flat-pickr
                      class="form-control"
                      :config="configEstimatedEndDate"
                      v-model="estimated_end_date"
                      placeholder="Estimated end date"
                      @on-change="onEstimatedEndDateChange"
                      name="estimated_end_date"
                    />
                    <b-input-group-append is-text>
                      <feather-icon icon="CalendarIcon" />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
        <!-- form footer -->
        <template #footer>
          <div class="form-button d-flex my-2">
            <button
              class="btn side-bar-btn"
              @click.prevent="validationForm"
              type="submit"
              :disabled="btnLoader"
            >
              <b-spinner small v-if="btnLoader" class="spinner"></b-spinner>
              {{ !sprintID ? "ADD" : "Update" }}
            </button>
            <button class="btn side-bar-btn" @click="clearData()">Clear</button>
          </div>
        </template>
      </b-sidebar>
    </validation-observer>
  </div>
</template>

<script>
import {
  BFormGroup,
  BFormInput,
  BSidebar,
  BCol,
  BRow,
  BFormTextarea,
  BInputGroupAppend,
  BInputGroup,
  BInputGroupPrepend,
  BBadge,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import { eventBus } from "../../../main";
export default {
  name: "AddSprint",
  components: {
    BFormGroup,
    BFormInput,
    BSidebar,
    ValidationProvider,
    ValidationObserver,
    BCol,
    BRow,
    flatPickr,
    BFormTextarea,
    vSelect,
    BInputGroup,
    BInputGroupAppend,
  },
  data() {
    return {
      required,
      showModal: true,
      btnLoader: false,
      sprint: null,
      sprintID: null,
      name: "",
      title: null,
      statusValue: [{ title: "Active" }],
      message: "",
      description: null,
      estimated_start_date: null,
      estimated_end_date: null,
      actual_start_date: null,
      actual_end_date: null,
      configEstimatedStartDate: {
        // minDate: new Date(),
        maxDate: this.milestoneEndDate,
        minDate: this.milestoneStartDate,
        dateFormat: "Y/m/d",
      },
      configEstimatedEndDate: {
        //minDate: new Date(),
        minDate: this.milestoneStartDate,
        maxDate: this.milestoneEndDate,
        dateFormat: "Y/m/d",
      },
      configActualStartDate: {
        minDate: new Date(),
        maxDate: null,
        dateFormat: "Y/m/d",
      },
      configActualEndDate: {
        //minDate: new Date(),
        minDate: null,
        dateFormat: "Y/m/d",
      },
    };
  },
  props: [
    "fromtitle",
    "id",
    "milstoneid",
    "countNum",
    "milestoneStartDate",
    "milestoneEndDate",
    "count",
  ],
  mounted() {
    setTimeout(() => {
      if (!this.sprintID) {
        this.sprint = this.countNum
          ? `Sprint-${this.countNum + 1}`
          : `Sprint-1`;
      }
    }, 200);
  },
  destroyed() {
    eventBus.$off("edit-sprint");
  },
  methods: {
    onEstimatedStartDateChange(selectedDates, dateStr, instance) {
      this.$set(this.configEstimatedEndDate, "minDate", dateStr);
    },
    onEstimatedEndDateChange(selectedDates, dateStr, instance) {
      this.$set(this.configEstimatedStartDate, "maxDate", dateStr);
    },
    onActualStartDateChange(selectedDates, dateStr, instance) {
      this.$set(this.configActualEndDate, "minDate", dateStr);
    },
    onActualEndDateChange(selectedDates, dateStr, instance) {
      this.$set(this.configActualStartDate, "maxDate", dateStr);
    },

    sidebarToggle() {
      this.sprintID = null;
      this.description = null;
      this.estimated_start_date = null;
      this.estimated_end_date = null;
      // this.actual_start_date = null;
      // this.actual_end_date = null;
      this.$refs.simpleRules.reset();
    },
    /**
     * Clear form details
     */
    clearData() {
      if (!this.sprintID) {
        this.sprint = this.countNum
          ? `Sprint-${this.countNum + 1}`
          : `Sprint-1`;
      }
      this.description = null;
      this.estimated_start_date = null;
      this.estimated_end_date = null;
      // this.actual_start_date = null;
      // this.actual_end_date = null;
      this.$refs.simpleRules.reset();
    },

    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          this.saveSpintdata();
        }
      });
    },
    async saveSpintdata() {
      this.btnLoader = true;
      let input = {
        title: this.sprint,
        project_id: this.$route.params.id3,
        type: "sprint",
        parent_id: this.milstoneid,
        description: this.description,
        estimated_start_date: this.estimated_start_date,
        estimated_end_date: this.estimated_end_date,
        // actual_start_date: this.actual_start_date,
        // actual_end_date: this.actual_end_date,
      };
      // console.log(input);
      let response = null;

      if (this.sprintID) {
        response = await this.getHTTPPutResponse(
          `project/plan/update/${this.sprintID}`,
          input,
          true
        );
        eventBus.$emit("milestoneList",true);
      } else {
        response = await this.getHTTPPostResponse(
          "project/plan/create",
          input,
          true
        );
      }
      if (response && response.status == 200) {
        this.clearData();
        // console.log('sprint add', response);

        var dataObj = null;
        if (response.data && response.data.project_plans) {
          let data = response.data.project_plans;
          var dataObj = {
            actual_end_date: null,
            actual_start_date: null,
            cost: null,
            created_at: data.created_at,
            created_by: data.created_by,
            deleted_at: data.deleted_at,
            description: data.description,
            estimated_end_date: data.estimated_end_date,
            estimated_start_date: data.estimated_start_date,
            id: data.id,
            number: data.number,
            parent_id: data.parent_id,
            project_id: data.project_id,
            status: data.status,
            title: data.title,
            type: data.type,
            updated_at: data.updated_at,
            updated_by: data.updated_by,
          };
          this.btnLoader = false;
        }
        this.$root.$emit("bv::toggle::collapse", "sprintfrom");
        this.$emit("sprintList", dataObj);

        this.btnLoader = false;
      } else {
        this.btnLoader = false;
      }
    },
  },
};
</script>

<style>
@import "../../../assets/scss/component-css/masterplan.css";
</style>
