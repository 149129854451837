<template>
  <div>
    <div class="pb-25">
      <div class="card-filter-section">
        <div
          class="d-flex align-items-center flex-wrap justify-content-between"
        >
          <div
            class="d-flex align-items-center flex-wrap justify-content-between"
          >
            <div class="d-flex align-items-center">
              <button
                class="back-button btn btn-secondary mr-2"
                @click="$store.state.app.pageShow = 'create'"
              >
                <feather-icon
                  icon="ArrowLeftIcon"
                  width="18"
                  height="18"
                />
                <span>Back</span>
              </button>
              <p class="filter-title ml-50">
                Select test cases
              </p>
            </div>
          </div>
          <div class="d-flex align-items-center">
            <b-input-group
              class="input-group-merge search-project"
              style="min-width: 250px; max-width: 250px; width: 250px"
            >
              <b-form-input
                v-model="search"
                placeholder="Search"
              />

              <b-input-group-append is-text>
                <feather-icon
                  v-if="search"
                  icon="XIcon"
                  @click="search = null"
                />
                <feather-icon icon="SearchIcon" />
              </b-input-group-append>
            </b-input-group>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-3 col-lg-4">
        <div class="select-case-left-side">
          <div class="case-list">
            <!-- <ul class="main-list">
              <li>
                <div class="list-desc">
                  <input type="checkbox" />
                  <label>Functional Tests</label>
                </div>
              </li>
              <li class="sub-list">
                <div class="list-desc">
                  <input type="checkbox" />
                  <label>Non-Functional Tests</label>
                </div>
                <ul>
                  <li>
                    <div class="list-desc">
                      <input type="checkbox" />
                      <label>Child Suite</label>
                    </div>
                  </li>
                </ul>
              </li>
              <li>
                <div class="list-desc">
                  <input type="checkbox" />
                  <label>Pre Test</label>
                </div>
              </li>
            </ul> -->

            <b-form-checkbox-group
              v-model="selectedSuiteId"
              :disabled="actionTypeCheck"
            >
              <NestedSuiteTestCase
                v-for="item in suiteData"
                :key="item.id"
                :suite-data="item"
              />
            </b-form-checkbox-group>
          </div>
        </div>
      </div>
      <!-- <NestedSuiteTestCase v-for="item in suiteData" :key="item.id" :suiteData="item"/> -->
      <div
        v-if="testCaseList.length"
        class="col-xl-9 col-lg-8"
      >
        <div class="select-case-right-side">
          <div class="header-part">
            <!-- ----{{ suiteNameDisplay }}--- -->
            <p>{{ suiteNameDisplay }}</p>
            <div
              v-if="
                $store.state.app.testActiveName != 'View' &&
                  isSelectedSuiteTestCase
              "
            >
              <b-button
                type="submit"
                class="mr-1 save-btn"
                @click="toggleSelection('selectAll')"
              >Select All</b-button>
              <b-button
                class="cancelBtn mr-1"
                @click="toggleSelection()"
              >Unselect all</b-button>
            </div>
          </div>
          <div class="middel-content">
            <div class="test-case-detail">
              <div
                v-for="(item, index) in testCaseList"
                :key="index"
                class="right-container mt-1"
              >
                <div
                  v-if="item.suite_id == $store.state.app.suiteId"
                  class="right-flex"
                >

                  <b-form-checkbox-group v-model="selectedTestCaseId">
                    <b-form-checkbox
                      :value="item.id"
                      :disabled="actionTypeCheck"
                    >
                      <label>{{ suiteData.suite_name }}</label>
                    </b-form-checkbox>
                  </b-form-checkbox-group>
                  <div class="app-item">
                    <div class="align-items-center d-flex">
                      <!-- <div class="addIcon mr-50">
                      <feather-icon
                        icon="PlusIcon"
                        width="30"
                        height="30"
                        color="#FFFFFF"
                      ></feather-icon>
                    </div> -->
                      <div>
                        <p class="app-name">
                          {{ item.title }}
                        </p>
                        <p
                          v-if="item.last_tested_date"
                          class="app-time"
                        >
                          Last run:
                          {{
                            item.last_tested_date
                              ? DDMMYYYYHHMMSSformat(item.last_tested_date)
                              : null
                          }}
                        </p>
                        <p
                          v-else
                          class="mr-3"
                        >
                          Not Tested Yet
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="!isSelectedSuiteTestCase"
              class="col-xl-9 col-lg-8 mx-auto"
            >
              <div class="d-flex">
                <img
                  src="../../../assets/images/nodatafound/nodata.svg"
                  alt="no-data"
                  class="no-data-img no-data-dashboard w-50"
                >
              </div>
            </div>
            <div
              v-if="
                $store.state.app.testActiveName != 'View' &&
                  isSelectedSuiteTestCase
              "
              class="test-plan mt-1"
            >
              <div>
                <b-button
                  class="mr-1 save-btn"
                  @click="storeTestCase"
                >Done</b-button>
                <b-button
                  class="cancelBtn mr-1"
                  @click="$store.state.app.pageShow = 'create'"
                >
                  cancel
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-else
        class="col-xl-9 col-lg-8"
      >
        <div class="d-flex justify-content-center">
          <img
            src="../../../assets/images/nodatafound/nodata.svg"
            alt="no-data"
            class="data-not-found no-data-dashboard"
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'
import NestedSuiteTestCase from './NestedSuiteTestCase.vue'

export default {
  name: 'Selecttestcases',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BFormCheckbox,
    NestedSuiteTestCase,
    NestedSuiteTestCase,
  },
  data() {
    return {
      search: null,
      selectedSuiteId: [],
      testCaseList: [],
      selectedTestCaseId: [],
      tempSelectedTestCaseId: [],
    }
  },
  computed: {
    suiteData() {
      const SuiteList = this.convertToParentChildData(
        this.$store.state.app.suiteList,
      )
      return SuiteList
    },
    // filteredSelectedTestCaseId() {
    //   if (this.selectedTestCaseId && this.selectedTestCaseId.length > 0) {
    //     return this.selectedTestCaseId.filter(elementId => {
    //       return this.testCaseList.find(item => item.id === elementId);
    //     });
    //   } else {
    //     return [];
    //   }
    // },
    actionTypeCheck() {
      if (this.$store.state.app.testActiveName == 'View') {
        return true
      }
      return false
    },
    suiteNameDisplay() {
      const suiteName = this.$store.state.app.suiteList.filter(
        item => item.id == this.$store.state.app.suiteId,
      )
      return suiteName[0].suite_name
    },
    isSelectedSuiteTestCase() {
      const testcase = this.testCaseList.find(
        item => item.suite_id === this.$store.state.app.suiteId,
      )
      if (testcase) {
        return true
      }
      return false
    },
  },
  watch: {
    selectedSuiteId(val) {
      this.$store.state.app.suiteId = val[val.length - 1]
      if (this.selectedSuiteId && this.selectedSuiteId.length) {
        this.getSelectedSuiteTestCaseData()
        // if(this.testCaseList && this.testCaseList.length>0) {
        //     this.selectedTestCaseId = this.filteredSelectedTestCaseId;
        //  }
      } else {
        this.testCaseList = []
        this.selectedTestCaseId = []
      }
    },
    search(nv) {
      if (nv && nv.length > 3) {
        this.tempSelectedTestCaseId = this.selectedTestCaseId // clear selected testcase when search so first store in temp variable
        this.getSelectedSuiteTestCaseData()
      } if (!nv) {
        this.getSelectedSuiteTestCaseData()
        this.selectedTestCaseId = this.tempSelectedTestCaseId // after clear filter get selected testcase
      }
    },
  },
  mounted() {
    const { app } = this.$store.state
    const { id4, id5 } = this.$route.params

    app.backAction = app.backAction || id4
    app.testActiveName = app.testActiveName || id5

    if (app.testActiveName) {
      app.testActiveName = app.testActiveName[0].toUpperCase() + app.testActiveName.slice(1)
    }

    if (
      this.$store.state.app.testCaseId
      && this.$store.state.app.testCaseId.length > 0
    ) {
      this.selectedTestCaseId = this.$store.state.app.testCaseId
      this.selectedSuiteId = this.$store.state.app.testPlanFormData.suiteId
      // this.getSelectedSuiteTestCaseData();
    }
  },

  destroyed() {
    if (this.$store.state.app.pageShow !== 'create') {
      this.$store.commit('app/UPDATE_TEST_CASE_ID', [])
      this.$store.commit('app/UPDATE_TEST_PLAN_FORM_DATA', [])
      
    }
    this.$store.state.app.suiteId = null
  },
  methods: {

    /**
     * get selected suite test cases
     */
    async getSelectedSuiteTestCaseData() {
      const input = {
        project_id: this.$route.params.id3 ? this.$route.params.id3 : null,
        suite_id:
          this.selectedSuiteId && this.selectedSuiteId.length
            ? this.selectedSuiteId
            : [],
        search: this.search ? this.search : null,
      }
      const response = await this.getHTTPPostResponse(
        'project/test-case',
        input,
        false,
      )

      if (response && response.status == 200) {
        const data = response.data.test_case
        this.testCaseList = Object.values(data).flat()

        if (this.selectedTestCaseId && this.selectedTestCaseId.length > 0) {
          this.selectedTestCaseId = this.selectedTestCaseId.filter(
            elementId => this.testCaseList.find(item => item.id === elementId),
          )
        }
      }
    },

    /** Selecte and unselect all test case's */
    toggleSelection(selecAll) {
      if (selecAll) {
        this.selectedTestCaseId = this.selectedTestCaseId.filter(id => {
          const testCase = this.testCaseList.find(item => item.id === id)
          return (
            testCase && testCase.suite_id !== this.$store.state.app.suiteId
          )
        })

        this.testCaseList
          .filter(item => item.suite_id === this.$store.state.app.suiteId)
          .forEach(element => {
            this.selectedTestCaseId.push(element.id)
          })
      } else {
        this.selectedTestCaseId = this.selectedTestCaseId.filter(id => {
          const testCase = this.testCaseList.find(item => item.id === id)
          return (
            testCase && testCase.suite_id !== this.$store.state.app.suiteId
          )
        })
      }

      this.tempSelectedTestCaseId = this.selectedTestCaseId
    },

    /**
     * Store test case data
     */
    storeTestCase() {
      this.$store.state.app.testPlanFormData.suiteId = this.selectedSuiteId
      this.$store.commit('app/UPDATE_TEST_CASE_ID', this.selectedTestCaseId)
      this.$store.state.app.pageShow = 'create'
    },
  },
}
</script>
<style lang="scss" scoped>
@import "../../../assets/scss/component-css/tesing.css";
</style>
