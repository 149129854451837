<template>
  <div>
    <b-row>
      <b-col cols="9">
        <div class="card-filter-section py-1">
          <div
            class="d-flex align-items-center flex-wrap justify-content-between"
          >
            <div class="d-flex align-items-center">
              <button
                class="back-button btn btn-secondary mr-2"
                @click="back()"
              >
                <feather-icon
                  icon="ArrowLeftIcon"
                  width="18"
                  height="18"
                />
                <span>Back</span>
              </button>
              <p
                v-b-tooltip.hover.top
                class="filter-title ml-50 test-run-title"
                :title="testRunDetail && testRunDetail.run_title"
              >
                Test Cases of {{ testRunDetail && testRunDetail.run_title }}
              </p>
            </div>
            <div
              class="d-flex align-items-center flex-wrap justify-content-between"
            >
              <div class="">
                <custom-dropdown
                  v-model="filterStatus"
                  :options="statusOptions"
                  label="status"
                  icon="EditIcon"
                  multiple
                />
              </div>
              <!-- <OptionDropdown v-model="perPage" /> -->
              <div class="d-flex align-items-center ml-2">
                <b-input-group
                  class="input-group-merge search-project"
                  style="min-width: 250px; max-width: 250px; width: 250px"
                >
                  <b-form-input
                    v-model="debouncedSearch"
                    placeholder="Search"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      v-if="debouncedSearch"
                      icon="XIcon"
                      @click="debouncedSearch = null ,search = null"
                    />
                    <feather-icon icon="SearchIcon" />
                  </b-input-group-append>
                </b-input-group>
              </div>
            </div>
          </div>
        </div>
        <div>
          <TestCaseRunShimmer v-if="test_plan_loader && !testCaseParentChildData.length" />
          <div
            v-else
            ref="scrollContainer"
            :class="
              groupBySuiteData && groupBySuiteData.length > 2
                ? $store.state.appConfig.layout.type == 'full'
                  ? 'right-side test-case-full-screen'
                  : 'right-side test-case-fix-height'
                : 'right-side'
            "
            @scroll="updateScrollbarValue"
          >
            <div
              v-for="(item, key) in testCaseParentChildData"
              :key="key"
            >
              <TestRunCaseAccordion
                :id="item.id"
                :data="item"
                :filter-status="filterStatus"
                :search="search"
              />
            </div>
          </div>
          <img
            v-if="items && items.length == 0 && !test_plan_loader"
            src="@/assets/images/nodatafound/nodata.svg"
            alt="no-data"
            style="min-width: 40%;"
            class="no-data-img"
          >
        </div>
      </b-col>
      <b-col
        cols="3"
        class="pt-1"
      >
        <TestingChartVue :test-run-detail="testRunDetail" />
      </b-col>
    </b-row>

    <div class="addtask defect-modal">
      <b-modal
        id="play_modal"
        cancel-variant="outline-secondary"
        centered
        size="sm"
        class="addtask-modal"
        :no-close-on-backdrop="true"
      >
        <template #modal-title>
          <h3 class="form-title">
            <span> Test Case Status</span>
          </h3>
        </template>

        <validation-observer ref="testCase">
          <b-row class="addtask-content">
            <!-- Group Member -->
            <b-col
              cols="12"
              class="modal-select"
            >
              <b-form-group
                label="Status"
                label-for="Status"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Status"
                  rules="required"
                >
                  <v-select
                    id="Status"
                    v-model="status"
                    class="select-size-lg"
                    name="Status"
                    placeholder="Select Status"
                    :options="testCaseStatusList"
                  />
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
        <div class="text-right">
          <b-button
            variant="secondary"
            class="cancelBtn mr-1"
            @click="$bvModal.hide('play_modal'), (status = null)"
          >
            Cancel
          </b-button>
          <b-button
            v-if="status && status.value == 'defect' && testCaseDetail && testCaseDetail.defects_count == 0"
            class="save-btn"
            :disabled="loader"
            @click="rediretToDefectPage(testCaseDetail)"
          >
            Add Defect
          </b-button>
          <b-button
            v-else
            class="save-btn"
            :disabled="loader"
            @click="validationForm"
          >
            Update
          </b-button>

        </div>
      </b-modal>
    </div>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BTable,
  BAvatar,
} from 'bootstrap-vue'
import TableLoader from '@/components/loaders/table-loading.vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import customDropdown from '@/components/dropdown/customDropdown.vue'
import moment from 'moment'
import { eventBus } from '@/main'
import debounce from 'lodash/debounce'
import TestingChartVue from './TestingChart.vue'
import OptionDropdown from '../../../components/optiondropdown/OptionDropdown.vue'
import TestRunCaseAccordion from './TestRunCaseAccordion.vue'
import TestCaseRunShimmer from './Loader/TestCaseRunShimmer.vue'

export default {
  name: 'TestCasesTable',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BTable,
    BAvatar,
    TableLoader,
    TestingChartVue,
    ValidationObserver,
    ValidationProvider,
    customDropdown,
    OptionDropdown,
    TestRunCaseAccordion,
    TestCaseRunShimmer,
  },
  data() {
    return {
      loader: false,
      test_plan_loader: true,
      testCaseDetail: null,
      status: null,
      totalCount: 0,
      currentPage: 1,
      perPage: 25,
      loading: false,
      sortBy: 'created_at',
      sortDesc: true,
      is_active: 'active',
      testRunDetail: null,
      search: null,
      fields: [
        {
          key: 'title',
          label: 'Title',
          thStyle: { minWidth: '300px', textAlign: 'start' },
        },
        {
          key: 'status',
          label: 'Status',
          thStyle: { minWidth: '200px', textAlign: 'start' },
        },
        {
          key: 'defects_count',
          label: 'Defect',
          thStyle: { minWidth: '150px', textAlign: 'start' },
        },
        {
          key: 'suite_name',
          label: 'Suite',
          thStyle: { minWidth: '150px', textAlign: 'start' },
        },
        {
          key: 'start_time',
          label: 'Start Date & Time',
          thStyle: { minWidth: '200px', textAlign: 'start' },
        },

        {
          key: 'end_time',
          label: 'End Date & Time',
          thStyle: { minWidth: '200px', textAlign: 'start' },
        },
        {
          key: 'action1',
          label: 'Action',
          thStyle: { minWidth: '150px', textAlign: 'start' },
        },
      ],
      items: [],
      testCaseStatusList: [
        { label: 'passed', value: 'passed' },
        { label: 'failed', value: 'defect' },
      ],
      filterStatus: [],
      groupBySuiteData: [],
      suiteList: [],
      pageIndex: 0,
    }
  },
  computed: {
    statusOptions() {
      return [
        { value: 'passed', label: 'Passed' },
        { value: 'defect', label: 'Failed' },
        { value: 'pending', label: 'Pending' },
      ]
    },

    testCaseParentChildData() {
      const groupByData = this.convertToParentChildData(this.groupBySuiteData)
      return groupByData
    },
    debouncedSearch: {
      get() {
        return this.search
      },
      set: debounce(function (value) {
        this.search = value 
        this.testCaseList()
        this.queryDataUpdate()
      }, 750),
    },
  },

  watch: {
    filterStatus() {
      this.testCaseList()
      this.queryDataUpdate()
    },
  },
  mounted() {
    const { id5, id6 } = this.$route.params
    const routeQuery = this.$route.query
    if (id5 && id6) {
      this.currentPage = routeQuery.testCurrentPage || this.currentPage
      this.search = routeQuery.testCaseSearch || this.search
      this.perPage = routeQuery.testPerPage || this.perPage
      this.filterStatus = routeQuery.status && routeQuery.status.length
        ? typeof routeQuery.status === 'string'
          ? [routeQuery.status]
          : routeQuery.status
        : this.filterStatus

      this.getSuiteList()
      this.getTestRun(id5)

      eventBus.$on('updateTestRunTime', data => {
        if (data && data.start_time == null) {
          this.updateTime(data, 'start')
        } else {
          this.testCaseDetail = data
          this.$bvModal.show('play_modal')
        }
      })

      eventBus.$on('reloadTestCaseList', () => {
        this.testCaseList()
        this.getTestRun(this.$route.params.id5)
        this.setScrollbarValue()
      })
      this.setScrollbarValue()
    }
  },
  destroyed() {
    if (this.$route.params && this.$route.params.id5 == null) {
      this.$store.state.app.testCaseAccordionIds = []
    }
    eventBus.$off('updateTestRunTime')
    eventBus.$off('reloadTestCaseList')
  },
  methods: {
    queryDataUpdate() {
      this.$router.replace({
        query: {
          currentPage: this.$route.query.currentPage,
          perPage: this.$route.query.perPage,
          search: this.$route.query.search,
          testCaseSearch: this.search,
          testCurrentPage: this.currentPage,
          testPerPage: this.perPage,
          status: this.filterStatus,
          layout: this.currentLayout,
          pageIndex: this.pageIndex ? this.pageIndex : this.$router.query && this.$router.query.pageIndex ? this.$router.query.pageIndex : 0,
          pageName: this.$route.query.pageName,
        },
      }).catch(err => {
        // Ignore the NavigationDuplicated error
        if (err.name !== 'NavigationDuplicated') {
          console.log(err)
        }
      })
    },

    /** back */
    back() {
      this.$store.state.app.pageShow = 'test-run'
      const { query } = this.$route.query
      this.$router.push({
        name: 'projectTask',
        params: {
          id: this.$route.params.id,
          id2: this.$route.params.id2,
          id3: this.$route.params.id3,
          id4: 'test-run',
          id5: null,
        },
        query: {
          currentPage:
            this.$route.query && this.$route.query.currentPage
              ? this.$route.query.currentPage
              : 1,
          perPage:
            this.$route.query && this.$route.query.perPage
              ? this.$route.query.perPage
              : 25,
          search:
            this.$route.query && this.$route.query.search
              ? this.$route.query.search
              : null,
          layout: this.currentLayout,
          pageName: this.$route.query.pageName,
        },
      }).catch(err => {
        // Ignore the NavigationDuplicated error
        if (err.name !== 'NavigationDuplicated') {
          console.log(err)
        }
      })
    },
    openTestCase(data) {
      this.$store.commit('app/UPDATE_TEST_CASE_ID_OF_RUN', data)
      this.$store.commit(
        'app/UPDATE_BACK_ACTION',
        this.$store.state.app.pageShow,
      )

      this.$store.commit('app/UPDATE_TEST_CASE_ID', data.test_case_id)

      this.$store.state.app.testActiveName = 'View'
      this.$store.commit('app/UPDATE_TEST_RUN_FILTER', {
        status: this.filterStatus,
        perPage: this.perPage,
        search: this.search,
      })

      this.$store.state.app.pageShow = 'test-run-case'
      this.$router.push({
        name: 'projectTask',
        params: {
          id: this.$route.params.id,
          id2: this.$route.params.id2,
          id3: this.$route.params.id3,
          id4: this.$route.params.id4,
          id5: this.$route.params.id5,
          id6: data.id,
        },
        query: {
          currentPage: this.$route.query.currentPage,
          perPage: this.$route.query.perPage,
          search: this.$route.query.search,
          testCaseSearch: this.search,
          testCurrentPage: this.currentPage,
          testPerPage: this.perPage,
          status: this.filterStatus,
          layout: this.currentLayout,
          pageName: this.$route.query.pageName,
        },
      }).catch(err => {
        // Ignore the NavigationDuplicated error
        if (err.name !== 'NavigationDuplicated') {
          console.log(err)
        }
      })
    },
    /**
     *  check the form validation
     * @returns if success true then call saveGroupDtl Method
     */
    validationForm() {
      this.$refs.testCase.validate().then(success => {
        if (success) {
          this.updateTime(this.testCaseDetail, 'end')
        }
      })
    },
    async getTestRun(id) {
      this.loader = true
      const response = await this.getHTTPGetResponse(
        `project/test-run/view/${id}`,
        null,
        false,
      )
      if (response && response.status == 200) {
        this.testRunDetail = response.data.testRun
      }
      this.loader = false
    },

    async testCaseList() {
      this.test_plan_loader = true
      const input = {
        project_id: this.$route.params.id3,
        test_run_id: this.$route.params.id5,
        test_plan_id: this.$route.params.id6,
        search: this.search,
        sort_field: this.sortBy,
        sort_order: this.sortDesc ? 'desc' : 'asc',
        status:
          this.filterStatus && this.filterStatus.length
            ? this.filterStatus
            : [],
      }
      const response = await this.getHTTPPostResponse(
        'project/test-run/list-test-run-case',
        input,
        false,
      )
      if (response && response.data) {
        const { data } = response
        // data.testRuns.forEach((element) => {
        //   element.value = element.id;
        //   element.label = element.title;
        // });
        this.items = data.testRuns

        const routeQuery = this.$route.query
        if (routeQuery && routeQuery.testRunDefect && routeQuery.testRunDefect == 'defect') {
          this.$bvModal.show('play_modal')
          // Flatten the array of test cases into a single array
          const allTestCases = Object.values(this.items).flatMap(testCases => testCases)

          // Find the test case with the matching id
          this.testCaseDetail = allTestCases.find(item => item.id === routeQuery.test_case)

          this.status = { label: 'failed', value: 'defect' }
        }

        this.mapTestSuiteAndTestCases()
      }
      this.test_plan_loader = false
    },

    /**
     *  Take delete defect confirmation
     */
    deleteConfirmation(id) {
      this.$swal({
        title: 'Are you sure?',
        icon: 'info',
        html: '<p>Once you delete you will not able to recover this record.</p>',
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: 'Delete',
        confirmButtonAriaLabel: 'Thumbs up, great!',
        cancelButtonAriaLabel: 'Thumbs down',
        customClass: {
          confirmButton: 'btn confirm',
          cancelButton: 'btn cancel ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.deleteTestCase(id)
        }
      })
    },
    /**
     * Delete Defect
     */
    async deleteTestCase(id) {
      const response = await this.getHTTPDeleteResponse(
        `project/test-run/delete-test-run-case/${id}`,
        {},
        true,
      )
      if (response && response.status === 200) {
        this.testCaseList()
        this.getTestRun(this.$route.params.id5)
        this.search = null
      }
    },

    /**
     * Delete Defect
     */
    async updateTime(data, type) {
      this.status = type === 'start' ? null : this.status
      const { id } = data
      const input = {
        plan_id: data.plan_id,
        project_id: data.project_id,
        title: data.title,
        status: this.status?.value,
        test_case_id: data.test_case_id,
        test_run_id: data.test_run_id,

        start_time:
          type === 'start'
            ? moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
            : moment(data.start_time, 'YYYY-MM-DD HH:mm:ss').format(
              'YYYY-MM-DD HH:mm:ss',
            ),
        end_time:
          type === 'start'
            ? null
            : moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
      }
      const response = await this.getHTTPPutResponse(
        `project/test-run/update-test-run-case/${id}`,
        input,
        false,
      )
      if (response && response.status === 200) {
        this.testCaseList()
        this.search = null

        if (type === 'end') {
          this.queryDataUpdate()
          this.getTestRun(this.$route.params.id5)
          this.setScrollbarValue()
          this.$bvModal.hide('play_modal')
        } else {
          this.setScrollbarValue()
        }
        this.status = null
      }
    },

    /** Get Project Suite list */
    async getSuiteList() {
      const input = {
        project_id: this.$route.params.id3 ? this.$route.params.id3 : null,
        search: this.search,
      }
      const response = await this.getHTTPPostResponse(
        'project/defect/test-suite-list',
        input,
        false,
      )

      if (response && response.status == 200) {
        const data = response.data && response.data.test_suite
          ? response.data.test_suite
          : []
        this.suiteList = data
        this.testCaseList()
      }
    },

    /** Map test cases to their respective test suites */
    mapTestSuiteAndTestCases() {
      this.groupBySuiteData = this.suiteList.map(suite => {
        const testCases = this.items[suite.id] || []
        return { ...suite, testCases }
      })
    },

    updateScrollbarValue() {
      this.pageIndex = this.$refs.scrollContainer && this.$refs.scrollContainer.scrollTop ? this.$refs.scrollContainer.scrollTop : 0
      this.queryDataUpdate()
    },

    setScrollbarValue() {
      if (this.$store.state.app.testCaseAccordionIds.length) {
        this.pageIndex = this.$route.query.pageIndex
        setTimeout(() => {
          this.$refs.scrollContainer.scrollTop = this.$route.query.pageIndex
        }, 1200)
      } else {
        this.queryDataUpdate()
      }
    },

    rediretToDefectPage(data) {
      if (this.status?.value === 'defect') {
        this.$store.state.app.backAction = this.$store.state.app.pageShow
        this.$store.state.app.testActiveName = 'Create'
        this.$store.state.app.defect.id = null
        this.$store.state.app.pageShow = 'create'
        const { params } = this.$route
        this.$router.push({
          name: 'projectTask',
          params: {
            id: this.$route.params.id,
            id2: this.$route.params.id2,
            id3: this.$route.params.id3,
            id4: 'defects',
            id5: 'create',
            id6: null,
            id7: null,
          },
          query: {
            test_case: data.id,
            suite_id: data.test_case.test_suite.id,
            layout: this.currentLayout,
            pageIndex: this.pageIndex,
            testRunDefect: this.status?.value,
            pageName: this.pageName,
            page: params.id4,
            testRunId: params.id5,
            testRunCaseId: params.id6,
          },
        }).catch(err => {
        // Ignore the NavigationDuplicated error
          if (err.name !== 'NavigationDuplicated') {
            console.log(err)
          }
        })
      }
    },
  },
}
</script>
<style lang="scss" scoped>
@import "../../../assets/scss/component-css/tesing.css";

.test-case-full-screen {
  max-height: calc(100vh - 210px);
  overflow-y: auto;
  overflow-x: hidden;
}

.test-case-fix-height {
  max-height: calc(100vh - 310px);
  overflow-y: auto;
  overflow-x: hidden;
}

.cancelBtn {
  padding: 8px 24px !important;
}
</style>
