<template>
  <div
    v-if="projectDtl"
    class="task-list"
  >
    <div>
      <filter-section
        :assignees="
          this.projectDtl && this.projectDtl.project_user.length
            ? this.projectDtl.project_user
            : []
        "
      />
    </div>
    <div class="sprint-deatil">
      <div class="d-flex align-items-center">
        <p
          v-if="projectDtl.active_milestone"
          class="sprint-title"
        >
          <feather-icon
            icon=" FileTextIcon"
            size="15"
          />
          {{ projectDtl.active_milestone.title }} <span class="devider">|</span>
        </p>
        <p
          v-if="projectDtl.active_sprint"
          class="sprint-title sprint"
        >
          {{ projectDtl.active_sprint.title }}
        </p>
        <p
          v-if="projectDtl.active_sprint"
          class="sprint-date"
        >
          <span>{{ projectDtl.active_sprint.actual_start_date | timeDayFormat }}
          </span>

          <span v-if=" projectDtl.active_sprint.actual_end_date">
            - {{ projectDtl.active_sprint.actual_end_date | timeDayFormat }}</span>
        </p>
      </div>
      <div class="d-flex align-items-center flex-wrap position-relative">
        <!-- <div v-if="projectDtl.active_sprint">
          <feather-icon
            icon="ClockIcon"
            size="20"
            class="day-count-icon"
          ></feather-icon>
          <span class="day-count" v-if="projectDtl.active_sprint" >
            <span>{{ projectDtl.active_sprint.estimated_end_date | convertInDays }}</span>
          </span>
        </div> -->
        <!-- <b-button
          class="startsprint"
          v-b-modal.startsprint
          v-if="!projectDtl.active_sprint"
          >start Sprint</b-button
        >
        <b-button class="startsprint" @click="showCompleteSprint()" v-else
          >complete</b-button
        > -->
        <b-button
          v-if="projectDtl.active_sprint"
          style="cursor:default"
          class="activesprint"
        >active</b-button>
        <b-button
          v-else
          v-b-modal.startsprint
          class="startsprint"
          :disabled="!addEditRights"
        >start Sprint</b-button>
        <div
          v-if="projectDtl.active_sprint && addEditRights"
          class="chervon-right"
        >
          <b-dropdown right>
            <template #button-content>
              <feather-icon
                icon="MoreHorizontalIcon"
                size="20"
                class="edit-dropdown"
              />
            </template>
            <!-- <b-dropdown-item>
              <span class="d-flex align-items-enter">
                <feather-icon
                  icon="EditIcon"
                  size="18"
                  class="edit-sprint"
                ></feather-icon
                >Edit
              </span>
            </b-dropdown-item> -->
            <b-dropdown-item>
              <span
                class="d-flex align-items-enter"
                @click="showCompleteSprint()"
              >
                <feather-icon
                  icon="CheckCircleIcon"
                  size="18"
                  class="edit-sprint"
                />
                Mark as done
              </span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
    </div>
    <task-loader
      v-if="loader"
      :row-num="3"
    />
    <div v-else>
      <newlist-view
        v-for="(status, index) in $store.state.app.projectTaskStatus"
        :id="statusname + '-' + index"
        :key="index"
        :task-list="getTaskList(status.id)"
        :project-dtl="projectDtl"
        :task="status.name"
        :status="status.name"
      >
        <!-- :color="status.color" -->
      </newlist-view>
    </div>

    <!-- start sprint Modal -->
    <complete-sprint
      id="completesprint"
      :project-dtl="projectDtl"
      :incomplete-tasks="incompleteTasks"
      :complete-tasks="completeTasks"
    />
    <!-- complete sprint Modal -->
    <start-sprint
      id="startsprint"
      :project-dtl="projectDtl"
    />
  </div>
</template>

<script>
import {
  BButton, VBModal
} from 'bootstrap-vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import { eventBus } from '@/main'
import TaskLoader from '@/components/loaders/timesheet-loader.vue'
import StartSprint from './StartSprint.vue'
import CompleteSprint from './completeSprint.vue'
import NewlistView from './NewlistView.vue'
import FilterSection from './Filter.vue'

export default {
  name: 'List',
  components: {
    FeatherIcon,
    BButton,
    StartSprint,
    CompleteSprint,
    NewlistView,
    FilterSection,
    TaskLoader,
  },
  directives: {
    'b-modal': VBModal,
  },
  props: {
    projectDtl: {
      // type: Object,
      required: true,
      default: null,
    },
  },
  data() {
    return {
      items: [],
      milestone: [{ title: 'Zig Sprint 01' }],
      project_plans: [],
      incompleteTasks: 0,
      completeTasks: 0,
      projectTasks: [],
      applyFilter: false,
      loader: false,
      allTasksStatus: [],
      statusname: 'status',
    }
  },
  computed: {
    addEditRights() {
      if (this.userInfo && this.userInfo.role == 'SA') {
        return true
      } if (
        this.projectDtl
        && this.projectDtl.project_user
        && this.projectDtl.project_user.length
      ) {
        const user = this.projectDtl.project_user.find(
          pu => pu.id === this.userInfo.id,
        )
        if (
          user
          && (user.access_rights === 'project_owner'
            || user.access_rights === 'co_ordinator')
        ) {
          return true
        }
        // access_rights = project_owner, co_ordinator, member
      }
      return false
    },
    // incompleteTasks(){
    //   return 0;
    // }
  },
  mounted() {
    this.getAllTaskStatus()
    eventBus.$on('projectTaskFilters', data => {
      if (data) {
        this.applyFilter = true
        this.getProjectTaskLists(data)
      }
    })
    eventBus.$on('reloadCompleteTaskList', data => {
      this.getProjectTaskLists()
    })
  },
  destroyed() {
    eventBus.$off('projectTaskFilters')
    eventBus.$off('reloadCompleteTaskList')
  },
  methods: {
    // Get All project task status (type = project)
    async getAllTaskStatus() {
      const input = {
        sort_field: 'display_order',
        sort_order: 'asc',
        type: 'project',
        is_active: true,
        project_id: this.projectDtl.id ? this.projectDtl.id : this.$route.params.id3,

      }
      const response = await this.getHTTPPostResponse(
        'project/task-status',
        input,
        false,
      )
      if (response && response.data) {
        this.allTasksStatus = response.data.task_statuses
        this.$store.state.app.projectTaskStatus = response.data.task_statuses
        this.getProjectTaskLists()
      }
    },

    showCompleteSprint() {
      this.getProjectTaskLists()
      setTimeout(() => {
        this.$root.$emit('bv::toggle::modal', 'completesprint')
      }, 700)
    },
    async getProjectTaskLists(filters = null) {
      if (
        !this.projectDtl
      || !this.projectDtl.active_milestone
      || !this.projectDtl.active_sprint
      ) {
        return
      }
      if (this.projectDtl.active_sprint) {
        this.loader = true
        const input = {
          project_id: this.projectDtl.id,
          milestone_id: this.projectDtl.active_milestone.id,
          sprint_id: this.projectDtl.active_sprint.id,
          search: filters && filters.search ? filters.search : null,
          tags: filters && filters.tags.length ? filters.tags : [],
          user_id: filters && filters.user ? filters.user : null,
          type: filters && filters.type.length ? filters.type : [],
          priority: filters && filters.priority ? filters.priority : null,
        }
        const response = await this.getHTTPPostResponse(
          'project/task',
          input,
          false,
        )
        if (response && response.data) {
          this.projectTasks = response.data.project_tasks
          const data = response.data.project_tasks
          let incompleteTasks = 0
          let completeTasks = 0
          if (
            data
            && data.length
            && this.projectDtl.active_milestone
            && this.projectDtl.active_sprint
          ) {
            data.forEach(element => {
              if (
                element.milestone_id == this.projectDtl.active_milestone.id
                && element.sprint_id == this.projectDtl.active_sprint.id
              ) {
                if (
                  element
                  && element.status
                  && element.status.category
                  && element.status.category == 'done'
                ) {
                  completeTasks++
                } else {
                  incompleteTasks++
                }
              }
            })
          }
          this.incompleteTasks = incompleteTasks
          this.completeTasks = completeTasks
          setTimeout(() => {
            this.loader = false
          }, 500)
        }
        this.loader = false
      }
    },
    getTaskList(status) {
      const tasks = []
      if (this.projectTasks && this.projectTasks.length) {
        this.projectTasks.forEach(element => {
          // if (element.task_status === status) {
          //   tasks.push(element);
          // }
          if (element.status_id === status) {
            tasks.unshift(element)
          }
        })
      }
      return tasks
    },

  },
}
</script>

<style>
@import "../../../assets/scss/component-css/list.css";
</style>
