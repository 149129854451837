<template>
  <div>
    <!-- Check if there are children to determine the rendering method -->
    <template v-if="suiteData.children.length > 0 || suiteData.parent_id == null">
      <app-collapse-item
        :title="suiteData.suite_name + ' -- ' + totalTestCase(suiteData)"
        :class="suiteData.children.length == 0 ? 'null-menu' : 'sub-menu'"
      >
        <!-- Render nested items recursively -->
        <ul>
          <NestedItem
            v-for="child in suiteData.children"
            :key="child.id"
            :suite-data="child"
          />
        </ul>
      </app-collapse-item>
    </template>
    <template
      v-else
      class="sub-menu"
    >
      <ul>
        <li>
          <span> {{ suiteData.suite_name }} -- {{ suiteData.test_cases_count }} </span>
        </li>
      </ul>
    </template>
  </div>
</template>

<script>
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

export default {
  name: 'NestedItem',
  components: {
    AppCollapseItem,
  },
  props: {
    suiteData: {
      type: Object,
      required: true,
    },
  },

  methods: {
    //   totalTestCase(item) {
    //     let total;
    //     
    //     if(item.parent_id == null) {
    //       total = 0;
    //     }
    //     item.children.forEach((element) => {
    //       total =  item.test_cases_count += element.test_cases_count;
    //     });
    //     return total;
    //   }
    // totalTestCase(item) {
    //   let total = item.test_cases_count;
    //   if (item.children && item.children.length > 0) {
    //     item.children.forEach((element) => {
    //       total += element.test_cases_count;
    //       if(element.children && item.children.length) {
    //         this.totalTestCase(element);
    //       }
    //     });
    //   }
    //   return total;
    // },
    totalTestCase(item) {
      return item.test_cases_count + (item.children || []).reduce((acc, child) => acc + this.totalTestCase(child), 0)
    },

  },
}
</script>
<style lang="scss" scoped>
@import "../../../assets/scss/component-css/tesing.css";
</style>
