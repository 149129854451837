<template>
  <div>
    <b-row class="main-projectlist-row mx-0">
      <!-- project accordion table  -->
      <b-col cols="12 p-0">
        <div
          class="accordion"
          role="tablist"
        >
          <b-card
            no-body
            class="mb-1"
          >
            <b-card-header
              header-tag="header"
              class="p-0"
              role="tab"
            >
              <div
                class="d-flex align-items-center justify-content-between w-100"
              >
                <b-button
                  v-b-toggle="id"
                  block
                  class="project-dashboard-btn d-flex align-items-center w-50"
                >
                  <span
                    class="featherspan-dashboard"
                    :style="cssVars"
                  >
                    <b-icon
                      icon="Caret-right-fill"
                      size="50"
                      class="collapseicon-dashboard"
                      :style="cssVars"
                    />
                  </span>
                  <span
                    class="project-title"
                    :style="cssVars"
                  >{{ task }} </span>
                  <span class="total-task-project">
                    {{
                      taskList && taskList.length
                        ? taskList.length > 1
                          ? taskList.length + " Tasks"
                          : "1 Task"
                        : "0 Task"
                    }}
                  </span>
                </b-button>
                <div>
                  <b-button
                    v-if="
                      status === 'backlog' &&
                        addEditRights &&
                        projectDtl.active_sprint
                    "
                    v-b-modal.addtaskdata
                    variant="primary"
                    class="ml-auto add-task-list"
                    @click="addTask()"
                  >
                    + New Task
                  </b-button>
                </div>
              </div>
            </b-card-header>
            <b-collapse
              :id="id"
              accordion="my-accordion"
              role="tabpanel"
            >
              <b-card-body class="p-0">
                <div class="card-body-listing">
                  <b-row>
                    <b-col
                      v-if="taskList && taskList.length"
                      cols="12"
                    >
                    <b-table
                      responsive
                      :items="taskList"
                      :fields="fields"
                      show-empty
                      class="
                        scrollable-element-dashboard
                        project-task-table
                        project-task-table-header
                        table-responsive
                        mb-0
                      "
                    >
                        <template #cell(name)="data">
                          <div class="d-flex align-items-center">
                            <span
                              v-if="data.item.type == 'bug'"
                              v-b-tooltip.hover.v-secondary
                              class="bug-fill-span"
                              title="Bug"
                            >
                              <b-icon icon="Bug-fill" />
                            </span>
                            <span
                              v-if="data.item.type == 'task'"
                              v-b-tooltip.hover.top="'Task'"
                              class="improvement-fill-span"
                            >
                              <img
                                src="@/assets/images/Image/improvement.svg"
                              >
                            </span>
                            <span
                              v-if="data.item.type == 'story'"
                              v-b-tooltip.hover.top="'Story'"
                              class="story-fill-span"
                            >
                              <b-icon icon="Bookmark-fill" />
                            </span>
                            <div>
                              <p class="project-title-dashboard">
                                {{ data.item.title }}
                              </p>
                              <p class="text-left">
                                <b-badge
                                  v-for="(tag, tindex) in data.item.tags"
                                  :key="tindex"
                                  class="tags-sprint"
                                >{{ tag.tag }}</b-badge>
                                <!-- <b-badge class="tags-sprint" style="background-color: #2c93cc">Api update</b-badge> -->
                              </p>
                            </div>
                          </div>
                        </template>
                        <template #cell(ticketnr)="data">
                          <div>
                            <span class="ticket">{{ data.item.number }} </span>
                          </div>
                        </template>
                        <template #cell(assign)="data">
                          <div>
                            <!-- <b-avatar
                            size="40px"
                            v-b-tooltip.hover
                            title="Not Mention"
                            :src="
                              require('@/assets/images/portrait/small/avatar-s-20.jpg')
                            "
                          /> -->
                            <!-- <div class="clock-icon-span">
                            <feather-icon
                              icon="UserPlusIcon"
                              size="18"
                              v-b-tooltip.hover
                              title="Not Mention"
                            ></feather-icon>
                          </div> -->
                            <b-avatar
                              v-if="data.item.assignee_user"
                              v-b-tooltip.hover
                              variant="primary"
                              :text="data.item.assignee_user.name | avatarText"
                              :src="data.item.assignee_user.picture"
                              size="40px"
                              :title="data.item.assignee_user.full_name"
                            />
                            <div
                              v-else
                              class="clock-icon-span"
                            >
                              <feather-icon
                                v-b-tooltip.hover
                                icon="UserPlusIcon"
                                size="18"
                                title="Not Mention"
                              />
                            </div>
                          </div>
                        </template>
                        <template #cell(timeestimated)="data">
                          <div v-if="data.item.estimated_hours">
                            <span class="esttime">
                              {{ `${data.item.estimated_hours}hr 00m` }}
                            </span>
                            <!-- <span >12hr 08m</span> -->
                          </div>
                          <!-- if time is not define -->
                          <div
                            v-else
                            class="clock-icon-span"
                          >
                            <feather-icon
                              v-b-tooltip.hover
                              icon="ClockIcon"
                              size="18"
                              title="Not Mention"
                            />
                          </div>
                        </template>
                        <template #cell(timespent)="data">
                          <!-- <span class="esttime">14hr 08m</span> -->
                          <span
                            v-if="data.item && data.item.project_task_spent_minutes > 0"
                            class="esttime"
                            :style="
                              data.item.estimated_hours
                                ? data.item.project_task_spent_minutes / 60 >
                                  data.item.estimated_hours
                                  ? { color: 'red' }
                                  : { color: 'green' }
                                : null
                            "
                          ><!--14hr 08m -->
                            {{
                              (data.item.project_task_spent_minutes / 60).toFixed(2)
                                | hoursInTimeFormat
                            }}</span>

                          <div
                            v-if="
                              data.item &&
                                data.item.project_task_spent_minutes === 0
                            "
                            class="clock-icon-span"
                          >
                            <feather-icon
                              v-b-tooltip.hover
                              icon="ClockIcon"
                              size="18"
                              title="No Time Spent"
                            />
                          </div>
                          <!--if not  have project task & total_time 0 then show icon-->
                          <div
                            v-if="
                              !data.item && data.item.project_task_spent_minutes === 0
                            "
                            class="clock-icon-span"
                          >
                            <feather-icon
                              v-b-tooltip.hover
                              icon="ClockIcon"
                              size="18"
                              title="Not Mention"
                            />
                          </div>
                          <!--if not  have project task & total_time > 0 then show TIME-->
                          <span
                            v-if="
                              !data.item && data.item.project_task_spent_minutes !== 0
                            "
                            class="esttime"
                          >
                            {{
                              (data.item.project_task_spent_minutes / 60).toFixed(2)
                                | hoursInTimeFormat
                            }}
                          </span>
                          <!-- <div class="clock-icon-span">
                            <feather-icon
                              icon="ClockIcon"
                              size="18"
                              v-b-tooltip.hover
                              title="Not Mention"
                            >
                            </feather-icon>
                          </div> -->
                        </template>
                        <template #cell(duedate)="data">
                          <span
                            v-if="data.item.due_date"
                            class="esttime"
                            :class="
                              checkDuteDates(data.item.due_date)
                                ? 'text-danger'
                                : 'text-success'
                            "
                          >
                            {{ data.item.due_date | timeDayFormat }}
                          </span>
                          <div
                            v-else
                            class="clock-icon-span"
                          >
                            <feather-icon
                              v-b-tooltip.hover
                              icon="CalendarIcon"
                              size="18"
                              title="Not Mention"
                            />
                          </div>
                        </template>
                        <template #cell(priority)="data">
                          <b-icon
                            v-if="data.item.priority == 'high'"
                            v-b-tooltip.hover.top="'High'"
                            icon="Flag-fill"
                            class="flag-fill-priority"
                          />
                          <b-icon
                            v-if="data.item.priority == 'med'"
                            v-b-tooltip.hover.top="'Medium'"
                            icon="Flag-fill"
                            class="flag-fill-priority-medium"
                          />
                          <b-icon
                            v-if="data.item.priority == 'low'"
                            v-b-tooltip.hover.top="'Low'"
                            icon="Flag-fill"
                            class="flag-fill-priority-low"
                          />
                        </template>
                        <template #cell(ticket)="data">
                          <div class="chervon-right">
                            <!-- <feather-icon icon="MoreHorizontalIcon" size="20" @click="viewTask(data.item.id)">
                          </feather-icon> -->
                            <b-dropdown
                              id="dropdown-dropleft"
                              no-caret
                              dropleft
                              text="Drop-Left"
                            >
                              <template #button-content>
                                <feather-icon
                                  icon="MoreHorizontalIcon"
                                  size="20"
                                  class="edit-dropdown"
                                />
                              </template>
                              <b-dropdown-item
                                v-b-modal.addtaskdata
                                @click="viewTask(data.item.id)"
                              >
                                <span class="d-flex align-items-enter">
                                  <feather-icon
                                    icon="EyeIcon"
                                    size="18"
                                    class="edit-sprint"
                                  />
                                  View
                                </span>
                              </b-dropdown-item>
                              <b-dropdown-item
                                v-if="addEditRights"
                                @click="deleteConfirmation(data.item.id)"
                              >
                                <span class="d-flex align-items-enter">
                                  <feather-icon
                                    icon="Trash2Icon"
                                    size="18"
                                    class="edit-sprint"
                                  />
                                  Delete
                                </span>
                              </b-dropdown-item>
                            </b-dropdown>
                          </div>
                        </template>
                      </b-table>
                    </b-col>
                    <b-col
                      v-else
                      cols="12"
                    >
                      <img
                        src="../../../assets/images/nodatafound/nodata.svg"
                        alt="no-data"
                        class="no-data-img"
                      >
                    </b-col>
                  </b-row>
                </div>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
      </b-col>
    </b-row>
    <add-task
      v-if="viewTaskDtl"
      id="addtaskdata"
      :project-dtl="projectDtl"
      :task-i-d="viewTaskID"
      :board-status="boardStatus"
      @disableTaskID="disableTaskID"
    />
  </div>
</template>

<script>
import { VBTooltip } from 'bootstrap-vue'
import { eventBus } from '@/main'
import * as moment from 'moment/moment'
import AddTask from './AddTask.vue'
import TaskDetail from './TaskDetail.vue'

export default {
  name: 'NewlistView',
  components: {
    VBTooltip,
    AddTask,
    TaskDetail,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    projectDtl: {
      // type: Object,
      required: true,
      default: null,
    },
    id: {
      type: String,
      required: true,
      default: 'id',
    },
    task: {
      type: String,
      required: true,
      default: null,
    },
    status: {
      // type: String,
      required: true,
      default: null,
    },
    color: {
      type: String,
      required: false,
      default: null,
    },
    taskList: {
      type: Array,
      required: false,
      // default: [],
    },
    completeTaskList: {
      type: Array,
      required: false,
      // default: [],
    },
  },
  data() {
    return {
      fields: [
        {
          key: 'name',
          label: '',
          tdClass: 'task-dashboard',
        },
        {
          key: 'ticketnr',
          label: 'Ticket NO.',
          thStyle: 'min-width:120px',
        },

        {
          key: 'timeestimated',
          label: ' Time Estimated',
          thStyle: 'min-width:150px',
        },
        {
          key: 'timespent',
          label: 'Time Spent',
          thStyle: 'min-width:150px',
        },
        {
          key: 'assign',
          thStyle: 'min-width:100px',
        },
        {
          key: 'duedate',
          label: 'Due date',
          thStyle: 'min-width:150px',
        },
        {
          key: 'priority',
          thStyle: 'width:80px',
          tdClass: 'priority-td',
        },
        {
          key: 'ticket',
          label: '',
          thStyle: 'width:50px',
        },
      ],
      // items: [
      //   {
      //     name: "name",
      //   },
      // ],
      perPage: 10,
      currentPage: 1,
      countLoader: false,
      loader: false,
      visibleList: false,
      showLeve: false,
      // taskList: [],
      viewTaskID: null,
      viewTaskDtl: false,
      boardStatus: { status: 'backlog', title: 'Backlog' },
      // id:'id'
    }
  },
  computed: {
    addEditRights() {
      if (this.userInfo && this.userInfo.role == 'SA') {
        return true
      } if (
        this.projectDtl
        && this.projectDtl.project_user
        && this.projectDtl.project_user.length
      ) {
        const user = this.projectDtl.project_user.find(
          pu => pu.id === this.userInfo.id,
        )
        if (
          user
          && (user.access_rights === 'project_owner'
            || user.access_rights === 'co_ordinator')
        ) {
          return true
        }
        // access_rights = project_owner, co_ordinator, member
      }
      return false
    },
    cssVars() {
      return {
        /* variables you want to pass to css */
        '--color': '#0000FF',
      }
    },
  },
  mounted() {
    // console.log("color", this.color);
  },
  methods: {
    checkDuteDates(date) {
      const startDate = moment(date).format('YYYY-MM-DD')
      const endDate = moment(new Date()).format('YYYY-MM-DD')
      if (endDate > startDate) {
        return true
      }
      return false
    },
    addTask() {
      this.viewTaskDtl = true
    },
    viewTask(id) {
      this.viewTaskID = id
      this.viewTaskDtl = true
    },
    // Clean the props viewTaskID and viewTaskDtl
    disableTaskID() {
      this.viewTaskID = null
      this.viewTaskDtl = false
    },
    closeModalEvent() {
      eventBus.$emit('reloadTaskList', true)
      this.viewTaskDtl = false
    },
    async getTaskList() {
      if (!this.projectDtl || !this.projectDtl.id) {
        return false
      } if (this.status == null) {
        return false
      }
      this.countLoader = true
      const input = {
        project_id: this.projectDtl.id,
        milestone_id: this.projectDtl.active_milestone.id,
        sprint_id: this.projectDtl.active_sprint.id,
        task_status: this.status,
      }
      const response = await this.getHTTPPostResponse(
        'project/task/toDoList',
        input,
        false,
      )
      if (response && response.status == 200 && response.data) {
        const { data } = response
        this.taskList = data.lists
        this.countLoader = false
        // this.users = data.users;
      }
      this.countLoader = false
    },
    /**
     * confirmation to delete spint
     */
    deleteConfirmation(id) {
      this.$swal({
        title: 'Are you sure?',
        icon: 'info',
        html: '<p>Once you delete you will not recover this task.</p>',
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: 'Delete',
        confirmButtonAriaLabel: 'Thumbs up, great!',
        cancelButtonAriaLabel: 'Thumbs down',
        customClass: {
          confirmButton: 'btn confirm',
          cancelButton: 'btn cancel ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.deleteTask(id)
        }
      })
    },
    /**
     * delete task
     */
    async deleteTask(id) {
      const input = {
        project_id: this.projectDtl.id,
      }
      const response = await this.getHTTPPostResponse(
        `project/task/delete/${id}`,
        input,
        true,
      )
      if (response && response.status == 200) {
        if (this.status == null) {
          eventBus.$emit('reloadCompleteTaskList', true)
        } else {
          this.getTaskList()
        }
      }
    },
    /**
     * update task flag
     */
    async updateFlag(id, is_flag) {
      const input = {
        is_flag: !is_flag,
        project_id: this.projectDtl.id,
      }
      const response = await this.getHTTPPostResponse(
        `project/task/flag/${id}`,
        input,
        true,
      )
      if (response && response.status == 200) {
        if (this.status == null) {
          eventBus.$emit('reloadCompleteTaskList', true)
        } else {
          this.getTaskList()
        }
      }
    },
  },
}
</script>

<style lang="scss">
.collapsed {
  .collapseicon-dashboard {
    transform: rotate(0deg);
    transition: 0.3s ease-in-out;
    color: #fff;
    height: 10px;
    width: 10px;
  }

  .featherspan-dashboard {
    border: 0px solid transparent;
    border-radius: 100%;
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color);
  }
}

.not-collapsed {
  .collapseicon-dashboard {
    transform: rotate(90deg);
    transition: 0.3s ease-in-out;
    color: var(--color);
    height: 14px;
    width: 12px;
  }

  .featherspan-dashboard {
    border: 2px solid var(--color);
    border-radius: 100%;
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.tags-sprint {
  background-color: #f49494;
  font-size: 10px;
  font-weight: 500;
  margin: 0 5px 5px 0;
}

.add-task-list {
  color: #fb5362 !important;
  background-color: transparent !important;
  border: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 600;
  margin-right: 20px;

  &:active {
    background-color: transparent !important;
  }

  &:focus {
    background-color: transparent !important;
  }

  &:hover {
    box-shadow: none !important;
  }
}
.project-task-table-header table tr th {
  padding: 15px 14px 5px 18px !important;
}
</style>
