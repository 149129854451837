<template>
  <div>
    <div class="right-list">
      <div
        v-if="item.children.length > 0 || item.parent_id == null"
        class="custom-accordien"
      >
        <div>
          <div class="upper-header mb-1">
            <div
              v-b-toggle="`collapse-${item.id}`"
              class="custome-btn"
              @click="collapse(`collapse-${item.id}`)"
            >
              <img
                src="../../../assets/images/icons/Arrow-Side.svg"
                class="img-1"
                style="width: 22px"
              >
              <img
                src="../../../assets/images/icons/Arrow-Down.svg"
                class="img-2"
                style="width: 22px"
              >
            </div>

            <p class="custome-btn">
              {{ item.suite_name }}
            </p>
            <div class="edit-suits ml-1">
              <b-dropdown id="dropdown-1">
                <template #button-content>
                  <feather-icon
                    v-b-tooltip.hover.top="'Create'"
                    icon="PlusIcon"
                    width="18"
                    height="18"
                    color="#2178FB"
                  /></template>
                <b-dropdown-item
                  v-b-modal.createSuite
                  @click="openTestSuite(item, 'Create')"
                >
                  <feather-icon
                    icon="PlusIcon"
                    width="18"
                    height="18"
                    color="#2178FB"
                  />
                  <span class="ml-25">Create Suite</span></b-dropdown-item>
                <b-dropdown-item
                  @click="openTestCase(item.id, 'Create')"
                ><feather-icon
                  icon="PlusIcon"
                  width="18"
                  height="18"
                  color="#2178FB"
                /><span class="ml-25">Create Case</span></b-dropdown-item>
              </b-dropdown>

              <feather-icon
                v-b-modal.createSuite
                v-b-tooltip.hover.top="'Edit'"
                icon="Edit2Icon"
                width="18"
                height="18"
                @click="openTestSuite(item, 'Edit')"
              />
              <feather-icon
                v-b-tooltip.hover.top="'Delete'"
                icon="TrashIcon"
                width="18"
                height="18"
                @click="deleteConfirmation(item.id, 'suite')"
              />
            </div>
          </div>
          <div>
            <b-collapse :id="`collapse-${item.id}`">
              <div
                class="create-list"
                @click="openTestCase(item.id, 'Create')"
              >
                <p>+ Create Test Case</p>
              </div>
              <b-card
                v-show="item.test_cases.length > 0 || item.children.length > 0"
                class="mb-1"
              >
                <div
                  v-for="(test, index) in item.test_cases"
                  :key="test.id"
                >
                  <b-card-text class="card-text">
                    <a
                      :href="testCaseLink + test.id"
                      style="cursor: default"
                      @click.prevent=""
                    >
                      <div class="suit-content">
                        <p
                          class="suit-title"
                          style="cursor: pointer"
                          @click="openTestCase(test.id, 'View')"
                        >
                          {{ test.code }}
                        </p>
                        <p class="suit-desc">
                          {{ test.title }}
                        </p>
                        <div class="edit-delete position-relative">
                          <feather-icon
                            v-b-tooltip.hover.top="'Edit'"
                            icon="Edit2Icon"
                            width="18"
                            height="18"
                            class="mr-50"
                            role="button"
                            color="#6e6b7b"
                            @click="openTestCase(test.id, 'Edit')"
                          />
                          <feather-icon
                            v-b-tooltip.hover.top="'Delete'"
                            icon="TrashIcon"
                            width="18"
                            height="18"
                            role="button"
                            class="mr-50"
                            color="#6e6b7b"
                            @click="deleteConfirmation(test.id, 'case')"
                          />
                          <feather-icon
                            v-b-tooltip.hover.top="'Duplicate Test Case'"
                            icon="CopyIcon"
                            width="18"
                            height="18"
                            role="button"
                            class="mr-50"
                            color="#6e6b7b"
                            @click="duplicateTestCase(test.id)"
                          />
                        </div>
                      </div>
                    </a>
                  </b-card-text>
                </div>
                <SuiteTestCase
                  v-for="child in item.children"
                  :id="child.id"
                  :key="child.id"
                  :item="child"
                />
              </b-card>
            </b-collapse>
          </div>
        </div>
      </div>

      <div v-else>
        <div>
          <div class="upper-header inner-header">
            <p
              v-b-toggle="`collapse-${item.id}`"
              class="custome-btn"
              @click="collapse(`collapse-${item.id}`)"
            >
              <img
                src="../../../assets/images/icons/Arrow-Side.svg"
                class="img-1"
              >
              <img
                src="../../../assets/images/icons/Arrow-Down.svg"
                class="img-2"
              >
              {{ item.suite_name }}
            </p>
            <div class="edit-suits">
              <b-dropdown id="dropdown-1">
                <template #button-content>
                  <feather-icon
                    v-b-tooltip.hover.top="'Create'"
                    icon="PlusIcon"
                    width="18"
                    height="18"
                    color="#2178FB"
                  /></template>
                <b-dropdown-item
                  v-b-modal.createSuite
                  @click="openTestSuite(item, 'Create')"
                >
                  <feather-icon
                    icon="PlusIcon"
                    width="18"
                    height="18"
                    color="#2178FB"
                  />
                  <span class="ml-25">Create Suite</span></b-dropdown-item>
                <b-dropdown-item
                  @click="openTestCase(item.id, 'Create')"
                ><feather-icon
                  icon="PlusIcon"
                  width="18"
                  height="18"
                  color="#2178FB"
                /><span class="ml-25">Create Case</span></b-dropdown-item>
              </b-dropdown>

              <feather-icon
                v-b-modal.createSuite
                v-b-tooltip.hover.top="'Edit'"
                icon="Edit2Icon"
                width="18"
                height="18"
                @click="openTestSuite(item, 'Edit')"
              />
              <feather-icon
                v-b-tooltip.hover.top="'Delete'"
                icon="TrashIcon"
                width="18"
                height="18"
                @click="deleteConfirmation(item.id, 'suite')"
              />
            </div>
          </div>
          <div>
            <b-collapse
              :id="`collapse-${item.id}`"
              class="nested-list"
            >
              <div
                class="create-list mb-0 mt-1"
                @click="openTestCase(item.id, 'Create')"
              >
                <p>+ Create Test Case</p>
              </div>

              <b-card
                v-for="test in item.test_cases"
                :key="test.id"
                class="mb-0"
              >
                <b-card-text class="card-text">
                  <a
                    :href="testCaseLink + test.id"
                    style="cursor: default"
                    @click.prevent=""
                  >
                    <div class="suit-content">
                      <p
                        class="suit-title"
                        style="cursor: pointer"
                        @click="openTestCase(test.id, 'View')"
                      >
                        {{ test.code }}
                      </p>
                      <p class="suit-desc">
                        {{ test.title }}
                      </p>
                      <div class="edit-delete position-relative">
                        <feather-icon
                          v-b-tooltip.hover.top="'Edit'"
                          icon="Edit2Icon"
                          width="18"
                          height="18"
                          class="mr-50"
                          role="button"
                          color="#6e6b7b"
                          @click="openTestCase(test.id, 'Edit')"
                        />
                        <feather-icon
                          v-b-tooltip.hover.top="'Delete'"
                          icon="TrashIcon"
                          width="18"
                          height="18"
                          role="button"
                          color="#6e6b7b"
                          class="mr-50"
                          @click="deleteConfirmation(test.id, 'case')"
                        />

                        <feather-icon
                          v-b-tooltip.hover.top="'Duplicate Test Case'"
                          icon="CopyIcon"
                          width="18"
                          height="18"
                          role="button"
                          color="#6e6b7b"
                          @click="duplicateTestCase(test.id)"
                        />
                      </div>
                    </div>
                  </a>
                </b-card-text>
              </b-card>
            </b-collapse>
          </div>
        </div>
      </div>
    </div>
    <!-- <SuitShimmerVue/> -->
  </div>
</template>

<script>
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import { eventBus } from '@/main'
import SuitShimmerVue from './Loader/SuitShimmer.vue'

export default {
  name: 'SuiteTestCase',
  components: {
    AppCollapseItem,
    SuitShimmerVue,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    id: {
      type: String,
    },
    // testCaseList:{
    //     required: false
    // }
  },
  computed: {
    testCaseLink() {
      const href = this.$route.path
      const newUrl = `${href.substr(0, href.lastIndexOf('/') + 1)}test-case/view/`
      return newUrl || null
    },
  },
  // computed: {
  //   testCaseList() {
  //     //
  //     return this.$store.state.app.testCaseList;
  //   },
  // },
  watch: {
    id(nv) {},
  },

  mounted() {
    this.Accordion(`collapse-${this.id}`)
    // if (
    //   this.$store.state.app.testCaseAccordionIds &&
    //   this.$store.state.app.testCaseAccordionIds.length
    // ) {
    //   this.$store.state.app.testCaseAccordionIds.forEach((element) => {
    //     this.$root.$emit("bv::toggle::collapse", element);
    //   });
    // }
  },
  updated() {
    // if (
    //   this.$store.state.app.testCaseAccordionIds &&
    //   this.$store.state.app.testCaseAccordionIds.length
    // ) {
    //   this.$store.state.app.testCaseAccordionIds.forEach((element) => {
    //     this.$root.$emit("bv::toggle::collapse", element);
    //   });
    // }
  },
  methods: {
    Accordion(id) {
      if (this.$store.state.app.testCaseAccordionIds.indexOf(id) !== -1) {
        this.$root.$emit('bv::toggle::collapse', id)
        // this.$root.$on("bv::collapse::state", (collapseId, isJustShown) => {
        //   if (collapseId == id && !isJustShown) {
        //     this.$root.$emit("bv::toggle::collapse", id);
        //   }
        // });
      }
    },
    collapse(id) {
      if (this.$store.state.app.testCaseAccordionIds.indexOf(id) !== -1) {
        this.$store.state.app.testCaseAccordionIds.splice(
          this.$store.state.app.testCaseAccordionIds.indexOf(id),
          1,
        )
      } else {
        this.$store.state.app.testCaseAccordionIds.push(id)
      }
    },

    /**
     *  confirmation to delete suite
     * @param {id} tag id
     * @returns if conform to delete call deleteTag function
     */
    deleteConfirmation(id, type) {
      this.$swal({
        title: 'Are you sure?',
        icon: 'info',
        html: '<p>Once you delete you will not able to recover this record.</p>',
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: 'Delete',
        confirmButtonAriaLabel: 'Thumbs up, great!',
        cancelButtonAriaLabel: 'Thumbs down',
        customClass: {
          confirmButton: 'btn confirm',
          cancelButton: 'btn cancel ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          type == 'suite' ? this.deleteSuite(id) : this.deleteTestCase(id)
        }
      })
    },

    /**
     *  delete suite
     * @method DELETE
     * @param {id}
     */
    async deleteSuite(id) {
      const response = await this.getHTTPDeleteResponse(
        `project/test-suite/delete/${id}`,
        {},
        true,
      )

      if (response && response.status == 200) {
        eventBus.$emit('reloadSuiteList', true)
      }
    },

    async deleteTestCase(id) {
      const response = await this.getHTTPDeleteResponse(
        `project/test-case/delete/${id}`,
        {},
        true,
      )

      if (response && response.status == 200) {
        // eventBus.$emit("reloadTesCaseData", true);

        // this.item.test_cases = this.item.test_cases.filter((item) => item.id !== id);
        eventBus.$emit('reloadSuiteList', true)
      }
    },

    openTestCase(id, type) {
      if (type == 'Create') {
        this.$store.state.app.suiteId = id
      } else {
        this.$store.commit('app/UPDATE_TEST_CASE_ID', id)
      }
      this.$store.state.app.testActiveName = type

      this.$store.commit(
        'app/UPDATE_BACK_ACTION',
        this.$store.state.app.pageShow,
      )

      this.$store.state.app.pageShow = 'create'
      this.reDirectTab(type.toLowerCase(), id)
    },

    reDirectTab(type, id) {
      this.$router.push({
        name: 'projectTask',
        params: {
          id: this.$route.params.id,
          id2: this.$route.params.id2,
          id3: this.$route.params.id3,
          id4: 'test-case',
          id5: type,
          id6: type != 'create' ? id : null,
        },
        query: {
          ...this.$route.query,
          suiteId: id,
        },
      }).catch(err => {
        // Ignore the NavigationDuplicated error
        if (err.name !== 'NavigationDuplicated') {
          console.log(err)
        }
      })
    },
    /** Fill suite modal data for edit */
    openTestSuite(data, type) {
      const val = {
        id: data.id,
        edit_id: type == 'Edit' ? data.id : null,
        suite_name: type == 'Edit' ? data.suite_name : null,
        parent_suite_id: type == 'Edit' ? data.parent_id : data.id,
      }
      // let val = {};
      // if(type=='Edit') {
      //   val = {
      //     parent_suite_id
      //   }
      // }
      this.$store.commit('app/UPDATE_TEST_SUITE', val)
      // this.$store.state.app.suiteId = data.id;
    },

    /**
     * duplicate test case
     */
    async duplicateTestCase(id) {
      const input = {
        test_case_id: id,
      }
      const response = await this.getHTTPPostResponse(
        'project/test-case/duplicate',
        input,
        true,
      )

      if (response && response.status == 200) {
        eventBus.$emit('reloadSuiteList', true)
      }
    },
  },
}
</script>
<style lang="scss" scoped>
@import "../../../assets/scss/component-css/tesing.css";
</style>
