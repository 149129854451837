<template>
  <div>
    <b-tabs
      content-class=""
      fill
      :class="
        $store.state.appConfig.layout.type == 'full'
          ? ' custom-tab m-0'
          : 'my-1 custom-tab'
      "
    >
      <template #tabs-end>
        <div class="layout-tools">
          <custom-dropdown
            v-show="false"
            v-model="test_project_id"
            :options="$store.state.app.productiveProjectList"
            :label="projectDtl.name"
            :hide-close="true"
            icon="EditIcon"
          />
          <Layoutchange
            :background-color="null"
            :type="'qassure'"
          />
          <Help
            v-show="$store.state.app.userManualList"
            v-if="$store.state.appConfig.layout.type != 'full'"
            :code="helpCode"
          />
        </div>
      </template>
      <b-tab
        :active="
          $route.params.id4 == 'test-suite' || $route.params.id4 == 'test-case'
        "
        button-id="test-suite"
        title="Test Suite"
        @click="
          ($store.state.app.pageShow = 'test-suite'),
          ($store.state.app.activeTab = 'test-suite'),
          reDirectTab('test-suite')
        "
      >
        <template #title>
          <span @contextmenu="openNewTab('test-suite')">Test Suite</span>
        </template>
        <div
          v-if="$store.state.app.pageShow == 'test-suite'"
          class="pb-50"
        >
          <div class="card-filter-section">
            <div
              class="d-flex align-items-center flex-wrap justify-content-between"
            >
              <div
                class="d-flex align-items-center justify-content-start flex-wrap"
              >
                <p class="filter-title">
                  Test Suite
                </p>
              </div>
              <div class="d-flex align-items-center">
                <b-input-group
                  class="input-group-merge search-project"
                  style="min-width: 250px; max-width: 250px; width: 250px"
                >
                  <b-form-input
                    v-model="search"
                    placeholder="Search"
                    @keydown="handleKeyDown"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      v-if="search"
                      icon="XIcon"
                      @click="search = null"
                    />
                    <feather-icon icon="SearchIcon" />
                  </b-input-group-append>
                </b-input-group>

                <div class="ml-1">
                  <button
                    class="create-filter filterRedesign mr-1"
                    @click="openSuiteForm()"
                  >
                    Create Suite
                  </button>
                  <button
                    class="apply-filter-clear filterRedesign mr-1"
                    @click="exportCsv()"
                  >
                    Export
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <SuitShimmerVue v-show="$store.state.app.testSuite.loader" />
        <img
          v-if="
            $store.state.app.projectSuiteList &&
              !$store.state.app.projectSuiteList.length &&
              !$store.state.app.testSuite.loader &&
              !$route.params.id5
          "
          src="../../../assets/images/nodatafound/nodata.svg"
          alt="no-data"
          class="no-data-img"
        >
        <div
          v-if="$store.state.app.pageShow == 'test-suite'"
          v-show="!$store.state.app.testSuite.loader"
          class="row align-items-start"
        >
          <div class="col-xl-3 col-lg-4">
            <TestingSuits />
          </div>
          <div class="col-xl-9 col-lg-8">
            <TestingRightSide />
          </div>
        </div>
      </b-tab>
      <b-tab
        :active="$route.params.id4 == 'test-plan'"
        title="Test Plan"
        button-id="test-plan"
        @click="
          ($store.state.app.pageShow = 'test-plan'),
          ($store.state.app.activeTab = 'test-plan'),
          reDirectTab('test-plan')
        "
      >
        <TestPlanTable v-if="$store.state.app.pageShow == 'test-plan'" />
        <template #title>
          <span @contextmenu="openNewTab('test-plan')">Test Plan</span>
        </template>
      </b-tab>
      <b-tab
        title="Test Run"
        button-id="test-run"
        :active="
          $route.params.id4 == 'test-run' ||
            $route.params.id4 == 'test-plan-run'
        "
        @click="
          ($store.state.app.pageShow = 'test-run'),
          ($store.state.app.activeTab = 'test-run'),
          reDirectTab('test-run')
        "
      >
        <template #title>
          <span @contextmenu="openNewTab('test-run')">Test Run</span>
        </template>
        <TestRunTableVue v-if="$store.state.app.pageShow == 'test-run'" />
      </b-tab>
      <b-tab
        title="Defects"
        button-id="defects"
        :active="$route.params.id4 == 'defects'"
        @click="
          ($store.state.app.pageShow = 'defects'),
          ($store.state.app.activeTab = 'defects'),
          reDirectTab('defects')
        "
      >
        <template #title>
          <span @contextmenu="openNewTab('defects')">Defects</span>
        </template>
      </b-tab>
    </b-tabs>
    <CreateTestCase
      v-if="
        ($route.params.id4 == 'test-run' && $route.params.id7) ||
          ($route.params.id4 == 'test-case' &&
            ($store.state.app.pageShow == 'create' ||
              $store.state.app.pageShow == 'edit' ||
              $store.state.app.pageShow == 'view'))
      "
    />
    <CreateTestPlanVue
      v-if="
        $route.params.id4 == 'test-plan' &&
          ($store.state.app.pageShow == 'create' ||
            $store.state.app.pageShow == 'edit' ||
            $store.state.app.pageShow == 'view')
      "
    />
    <SelecttestcasesVue
      v-if="$store.state.app.pageShow == 'selecttestcasesVue'"
    />
    <TestPlanCasesView v-if="$store.state.app.pageShow == 'TestPlanCasesVue'" />
    <StartTestRunVue
      v-if="
        $route.params.id4 == 'test-plan-run' ||
          (!$route.params.id7 &&
            ($route.params.id4 == 'test-plan-run' ||
              $route.params.id4 == 'test-run') &&
            ($store.state.app.pageShow == 'create' ||
              $store.state.app.pageShow == 'edit' ||
              $store.state.app.pageShow == 'view'))
      "
    />
    <TestCasesTableVue
      v-if="$store.state.app.pageShow == 'testCasesTableVue'"
    />
    <CreateDefect
      v-if="
        (($route.params.id4 == 'test-run' &&
          $route.params.id5 == 'test-case') ||
          $route.params.id4 == 'defects') &&
          ($store.state.app.pageShow == 'create' ||
            $store.state.app.pageShow == 'edit' ||
            $store.state.app.pageShow == 'view')
      "
    />
    <DefeactsTableVue v-if="$store.state.app.pageShow == 'defects'" />
    <AddTestCreate id="indexPage" />
  </div>
</template>

<script>
import { BTabs, BTab, BCardText } from 'bootstrap-vue'
import { eventBus } from '@/main'
import Help from '@/components/HelpDesk/Help.vue'
import Layoutchange from '@/components/layOutChange/LayOutChange.vue'
import customDropdown from '@/components/dropdown/customDropdown.vue'
import AddTestCreate from './addtestcreate.vue'
import TestingSuits from './TestingSuits.vue'
import TestingRightSide from './TestingRightSide.vue'
import CreateTestCase from './CreateTestCase.vue'
import CreateDefect from './CreateDefect.vue'
import CreateTestPlanVue from './CreateTestPlan.vue'
import StartTestRunVue from './StartTestRun.vue'
import SelecttestcasesVue from './Selecttestcases.vue'
import TestPlanTable from './TestPlanTable.vue'
import DefeactsTableVue from './DefeactsTable.vue'
import TestRunTableVue from './TestRunTable.vue'
import TestCasesTableVue from './TestCasesTable.vue'
import TestPlanCasesView from './TestPlanCasesView.vue'
import SuitShimmerVue from './Loader/SuitShimmer.vue'

export default {
  name: 'Testing',
  components: {
    TestingSuits,
    TestingRightSide,
    CreateTestCase,
    CreateDefect,
    CreateTestPlanVue,
    StartTestRunVue,
    SelecttestcasesVue,
    TestPlanTable,
    DefeactsTableVue,
    TestRunTableVue,
    TestCasesTableVue,
    BTabs,
    BTab,
    BCardText,
    AddTestCreate,
    TestPlanCasesView,
    SuitShimmerVue,
    Help,
    Layoutchange,
    customDropdown,
  },
  props: ['projectDtl'],
  data() {
    return {
      search: null,
      loader: false,
      orginalTestSuiteData: [],
      helpCode: 'TestSuite',
      suiteIdArray: [],
      test_project_id: this.$route.params.id3,
    }
  },
  computed: {
    filteredData() {
      let search = this.search ? this.search.trim().toLowerCase() : null
      const filterNestedData = items => {
        if (items.length) {
          this.orginalTestSuiteData.push(items)
        }
        return items.filter(item => {
          const testCase = this.updateTestCase(item.test_cases)
          if (
            item.suite_name
              .toLowerCase()
              .includes(search)
            || (testCase && testCase.length)
          ) {
            item.test_cases = testCase && testCase.length ? testCase : item.test_cases
            return true
          }
          item.test_cases = []
          const filteredChildren = filterNestedData(item.children)
          return filteredChildren.length > 0
        })
      }
      return filterNestedData(this.$store.state.app.projectSuiteList)
    },
  },
  watch: {
    test_project_id(nv) {
      if (nv) {
        this.projectId = nv
      }
    },
    search(nv) {
      if (nv && nv.length > 1) {
        this.$store.state.app.testSuite.search = nv
        this.$nextTick(() => {
          this.$store.state.app.projectSuiteList = this.filteredData
        })
      } else if (!nv || nv.length == 0) {
        eventBus.$emit('reloadSuiteList', [true, nv])
      }

      const query = {
        ...(nv && { search: nv }),
        layout: this.currentLayout,
        pageName: this.pageName,
      }
      this.routeQueryUpdate(query)
    },
  },
  mounted() {
    this.getCurrentyComponent()
    eventBus.$on('clearSuiteSearch', data => {
      this.search = null
      this.$nextTick(() => {
        this.$store.state.app.projectSuiteList = this.filteredData
      })
    })
  },
  destroyed() {
    eventBus.$off('clearSuiteSearch')
  },
  updated() {
    const { activeTab } = this.$store.state.app
    if (activeTab !== 'test-plan') {
      this.$store.dispatch('app/CLEAR_TESTPLAN_FILTER')
      this.$store.commit('app/UPDATE_TEST_CASE_ID', [])
      this.$store.dispatch('app/CLEAR_TESTPLAN_FORM_DATA')
    }
    if (activeTab !== 'test-run') {
      this.$store.dispatch('app/CLEAR_TESTRUN_FILTER')
    }
    if (activeTab !== 'defects') {
      this.$store.dispatch('app/CLEAR_DEFECT_FILTER')
    }
    if (this.search && this.$route.params.id4 === 'test-suite') {
      setTimeout(() => {
        this.$store.state.app.projectSuiteList = this.filteredData
      }, 100)
    }
    this.getCurrentyComponent()
  },
  methods: {
    openSuiteForm() {
      this.$store.state.app.testActiveName = 'Create'
      this.$bvModal.show('indexPage')
    },
    getCurrentyComponent() {
      if (this.$store.state.app.pageShow != 'selecttestcasesVue') {
        const routeParams = this.$route.params
        const appState = this.$store.state.app
        if (routeParams.id4 === 'test-suite') {
          this.search = this.$route.query && this.$route.query.search
            ? this.$route.query.search
            : null
        }
        if (routeParams.id7) {
          appState.pageShow = 'create'
          return appState.pageShow
        }

        if (!routeParams.id6 && !routeParams.id5) {
          appState.pageShow = routeParams.id4
          return appState.pageShow
        }

        if (
          routeParams.id6
          && routeParams.id5
          && routeParams.id4 === 'test-run'
          && !['create', 'edit', 'view'].includes(routeParams.id5)
        ) {
          appState.test_run.plan_id = routeParams.id6
          appState.test_run.id = routeParams.id5
          appState.pageShow = 'testCasesTableVue'
          return appState.pageShow
        }

        appState.pageShow = routeParams.id5
        appState.activeTab = routeParams.id4
        return appState.pageShow
      }
    },
    reDirectTab(id) {
      this.search = null
      // this.$router.push({
      //   name: "projectTask",
      //   params: {
      //     id: this.$route.params.id,
      //     id2: this.$route.params.id2,
      //     id3: id,
      //     pageNumber: this.$route.params.pageNumber,
      //     perPage: this.$route.params.perPage,
      //     page_number: this.$route.params.pageNumber,
      //     per_page: this.$route.params.perPage,
      //     list_icon: this.$route.params.listIcon,
      //   },
      //   query: {
      //     layout: this.currentLayout,
      //   },
      // });
      const query_page = this.$route.params.page_name
      const params = {
        id: this.$route.params.id,
        id2: this.$route.params.id2,
        id3: this.$route.params.id3,
        id4: id,
      }
      const query = {
        layout: this.currentLayout,
        pageName: this.$route.query.pageName,
      }
      this.redirectToOtherTab(params, query)

      switch (id) {
        case 'test-suite':
          this.helpCode = 'TestSuite'
          break
        case 'test-plan':
          this.helpCode = 'TestPlan'
          break
        case 'test-run':
          this.helpCode = 'TestRun'
          break
        case 'defects':
          this.helpCode = 'Defects'
          break
        default:
          this.helpCode = 'TestSuite'
          break
      }
      if (this.$refs.childComponentRef) this.$refs.childComponentRef.currentPageDisplay(this.helpCode)
    },
    handleKeyDown(event) {
      if (this.search && event.key === 'Backspace') {
        this.$store.state.app.projectSuiteList = this.orginalTestSuiteData[0]
      }
    },

    updateTestCase(data) {
      if(!this.search) return true
      let search = this.search ? this.search.trim().toLowerCase() : null
      return data.filter(item => {
        if ((item && item.title && item.title.toLowerCase().includes(search))
          || (item && item.code && item.code.toLowerCase().includes(search))
        ) {
          return true
        }
      })
    },

    exportCsv() {
      this.loader = true
      this.extractIds(this.$store.state.app.projectSuiteList)
      let URL = `${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_API_VERSION}/project/test-suite/export-test-suite?project_id=${this.$route.params.id3}`

      if (this.suiteIdArray && this.suiteIdArray.length) {
        this.suiteIdArray.forEach(element => {
          URL += `&suite_id[]=${element}`
        })
      }

      if (this.search) {
        URL += `&search=${this.search}`
      }

      const respo = this.$axios({
        url: URL,
        method: 'GET',
        responseType: 'blob',
      }).then(response => {
        location.href = URL
        this.defect_loader = false
      })
      this.suiteIdArray = []

      this.loader = false
    },

    extractIds(data) {
      for (const item of data) {
        if (item.id) {
          this.suiteIdArray.push(item.id)
        }
        if (item.children) {
          this.extractIds(item.children) // Recursively call the function for nested data
        }
      }
    },

    /** open tab in new window tab */
    openNewTab(tab) {
      const anchor = document.getElementById(tab)
      const { params } = this.$route
      const { href } = anchor
      let newUrl
      if (tab == this.$store.state.app.activeTab) {
        newUrl = href.substr(0, href.lastIndexOf('/') + 1) + tab
      } else {
        newUrl = href.replace(`${params.id4}`, tab).replace(`/${params.id5}`, '').replace(`/${params.id6}`, '').replace(`/${params.id7}`, '')
      }

      anchor.href = newUrl
    },
  },
}
</script>
<style lang="scss" scoped>
@import "../assets/scss/variables/_variables.scss";
@import "../../../assets/scss/component-css/tesing.css";

.fixHight {
  display: -webkit-box;
  -webkit-line-clamp: 9;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.fixHightCard {
  // min-height: 500px;
}
.card-filter-section {
  background-color: $white;
  margin: 10px 0px 10px 0px;
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
  padding: 10px 15px;
  .filter-title {
    color: $logo-color;
    font-size: 2.25 * $rem;
    font-weight: $fw500;
  }
  .create-filter {
    background-color: $primary-color;
    border: 1px solid $primary-color;
    color: $white;
    padding: 6px 11px;
    border-radius: 5px;
    font-size: 2 * $rem;
    font-weight: $fw600;
    @media (max-width: map-get($mediaSizes , "xxl")) {
      font-size: 2 * $rem;
    }
    @media (max-width: map-get($mediaSizes , "xl")) {
      font-size: 1.75 * $rem;
    }
    @media (max-width: map-get($mediaSizes , "lg")) {
      font-size: 1.5 * $rem;
    }
  }
  .apply-filter-clear {
    border: 1px solid $secondary-color;
    color: $secondary-color;
    padding: 6px 20px;
    border-radius: 5px;
    font-size: 2 * $rem;
    font-weight: $fw600;
    background-color: transparent;
    @media (max-width: map-get($mediaSizes , "xxl")) {
      font-size: 2 * $rem;
    }
    @media (max-width: map-get($mediaSizes , "xl")) {
      font-size: 1.75 * $rem;
    }
    @media (max-width: map-get($mediaSizes , "lg")) {
      font-size: 1.5 * $rem;
    }
  }
  .add-note {
    background-color: #2178fb;
    border: 1px solid #2178fb;
    color: $white;
    padding: 6px 11px;
    border-radius: 5px;
    font-size: 2 * $rem;
    font-weight: $fw600;
    @media (max-width: map-get($mediaSizes , "xxl")) {
      font-size: 2 * $rem;
    }
    @media (max-width: map-get($mediaSizes , "xl")) {
      font-size: 1.75 * $rem;
    }
    @media (max-width: map-get($mediaSizes , "lg")) {
      font-size: 1.5 * $rem;
    }
  }
  .search-project {
    border-radius: 5px;
    color: $dark-gray;
    &:focus-visible {
      outline: $primary-color;
    }
    .form-control {
      border-color: $input-outline;
      padding: 6px 0 6px 20px;
      color: $logo-color !important;
      height: 33px;
      &::placeholder {
        color: $dark-gray;
        font-size: 1.5 * $rem;
        font-weight: $fw500;
      }
    }
  }
}
</style>
