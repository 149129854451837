<template>
  <div v-if="!loader && !defectLoader">
    <div class="activity-logs mt-1">
      <div class="">
        <h3>Activity Logs</h3>
        <hr>
        <div
          v-for="log in activityLog"
          class="logs-content mb-2"
        >
          <p>
            <!-- dob updated by Ravi Thakor (Z00018) Old: 2023-08-18 ,New:
            2001-08-30. -->
            {{ log.description }}
          </p>
          <span class="activity-time">{{ log.created_at | dateFromNow }}</span>
        </div>
        <div
          v-if="activityLog.length == 0"
          class="d-flex align-items-center"
        >
          <h4>No comments added yet</h4>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
} from 'bootstrap-vue'
import ViewSuitcaseVue from './Loader/ViewSuitcase.vue'

export default {
  name: 'ViewTestCase',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    ViewSuitcaseVue,
  },
  props: ['loader'],
  data() {
    return {
      activityLog: [],
      defectLoader:false
    }
  },
  mounted() {
    if (this.$store.state.app.defect.id || this.$route.params.id5) {
      this.userActivityLogs()
    }
  },
  methods: {
    async userActivityLogs() {
      this.defectLoader = true
      const input = {
        defect_id: this.$store.state.app.defect.id ? this.$store.state.app.defect.id : this.$route.params.id6,
      }
      const response = await this.getHTTPPostResponse(
        'project/defect/defect-log-list',
        input,
        false,
      )

      if (response && response.status == 200) {
        const data = response.data.activity
        this.activityLog = data
      }
      this.defectLoader = false
    },
  },
}
</script>
<style lang="scss" scoped>
@import "../../../assets/scss/component-css/tesing.css";
</style>
