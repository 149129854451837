<template>
  <div>
    <!-- Check if there are children to determine the rendering method -->
    <ul class="main-list">
      <li v-if="suiteData.children.length > 0 || suiteData.parent_id == null">
        <div class="list-desc">
          <!-- <input type="checkbox" v-model="suiteId" :value="suiteData.id"/> -->

          <b-form-checkbox
            :value="suiteData.id"
          >
            <label @click="$store.state.app.suiteId = suiteData.id">{{ suiteData.suite_name }}</label>
          </b-form-checkbox>
        </div>
        <ul>
          <NestedSuiteTestCase
            v-for="child in suiteData.children"
            :key="child.id"
            :suite-data="child"
          />
        </ul>
      </li>
      <li
        v-else
        class="sub-list"
      >
        <div class="list-desc">
          <!-- <input type="checkbox" v-model="suiteId" :value="suiteData.id"/>
                  <label>{{suiteData.suite_name}}</label> -->
          <b-form-checkbox
            :value="suiteData.id"
          >

            <label @click="$store.state.app.suiteId = suiteData.id">{{ suiteData.suite_name }}</label>
          </b-form-checkbox>
        </div>
        <ul />
      </li>
    </ul>
  </div>
</template>

<script>
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

export default {
  name: 'NestedSuiteTestCase',
  components: {
    AppCollapseItem,
  },
  props: {
    suiteData: {
      type: Object,
      required: true,
    },
  },
}
</script>
    <style lang="scss" scoped>
    @import "../../../assets/scss/component-css/tesing.css";
    </style>
