<template>
  <div>
    <BoardFilter
      v-if="projectDtl"
      :project-dtl="projectDtl"
      :non-board="false"
      :board-project-id="projectDtl && projectDtl.id"
      :from="from"
      @project-id="$emit('projectId', $event)"
    />
    <BoardShimmer v-if="taskLoader" />
    <draggable
      v-else
      class="draggable-list"
      handle=".control-dragcom"
      :disabled="!addEditRights"
      :list="$store.state.app.projectTaskStatus"
      :style="from == 'generalBoard' ? 'height: calc(100vh - 170px)' : null"
      :class="$store.state.appConfig.layout.type == 'full' ? 'd-flex boardDesign board-inner-view-full-screen' : 'd-flex boardDesign board-inner-view'"
      @end="updateDisplayOrder($event)"
    >
      <!-- <div class="dummy-div"></div> -->
      <div
        v-for="(status, index) in $store.state.app.projectTaskStatus"
        v-if="status && status.id != 'archive'"
        :key="index"
        class="board-status control-dragcom"
        :class="$store.state.app.projectTaskStatus[index].tasks && status.tasks.length == 0 ? 'draggable-box' : null"
      >
        <div class="board-status-header">

          <b-input-group
            v-if="sectionId == status.id && addEditRights"
            class="input-group-merge section-group"
            tabindex="0"
          >
            <b-form-input
              ref="myTextField"
              v-model="status.name"
              placeholder="Add status"
              class="section-textfield"
            />
            <b-input-group-append is-text>
              <feather-icon
                class="mr-25 cursor-pointer"
                icon="CheckIcon"
                size="18"
                @click="updateSection(status, index)"
              />

            </b-input-group-append>
          </b-input-group>
          <p
            v-else
            class="board-status-title"
            @click="sectionParentTitle(status.id)"
          >
            {{ status.name }}

            <feather-icon
              v-if="status && status.category == 'done'"
              icon="CheckIcon"
              size="18"
              class="cursor-pointer"
              color="green"
            />
          </p>

          <div
            v-if="addEditRights"
            class="d-flex align-items-center"
          >
            <span
              v-b-modal.addtaskdata
              class="add-board"
              @click="addtaskData(status)"
            >
              <feather-icon
                icon="PlusIcon"
                size="16"
              />
            </span>

            <div class="boardDropdown">
              <b-dropdown
                toggle-class="text-decoration-none"
                no-caret
                append-to-body
              >
                <template v-slot:button-content>
                  <button class="edit-del-button">
                    <feather-icon
                      icon="MoreHorizontalIcon"
                      size="21"
                      class="align-middle edit-data-delete"
                    />
                  </button>
                </template>
                <b-dropdown-item
                  v-if="addEditRights"
                  class="edit-delete-item"
                  @click="sectionParentTitle(status.id)"
                >
                  <feather-icon
                    icon="EditIcon"
                    size="16"
                    class="mr-50 edit-icons"
                  />
                  <span class="edit-data">Edit status name</span>
                </b-dropdown-item>
                <b-dropdown-item
                  class="edit-delete-item"
                  @click="deleteConfirmation(status, index)"
                >
                  <feather-icon
                    icon="TrashIcon"
                    size="16"
                    class="mr-50 edit-icons"
                  />
                  <span class="edit-data">Delete status</span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
        </div>

        <draggable
          group="all-users"
          :class="$store.state.appConfig.layout.type == 'full' ? 'draggable-list-fullscreen' : 'draggable-list'"
          :style="from == 'generalBoard' ? 'max-height: calc(100vh - 245px) !important;' : null"
          ghost-class="moving-card"
          :list="status.tasks"
          :animation="200"
          @change="getChangeData($event, status.id)"
        >
          <div
            v-for="(task, index) in status.tasks"
            :key="index"
            class="task projectListnew min-heigth"
            role="button"
          >
            <div class="d-flex">
              <b-form-input
                v-if="taskid == task.id && textEdit"
                id="editTask"
                v-model="tasktitle"
                type="text"
                :value="task.title"
                placeholder="Edit task"
                name="editTask"
                class="w-75"
                @keyup.enter="onEnter(task.id, task.title)"
                @blur="textEdit = false"
              />
              <p
                v-else
                v-b-modal.addtaskdata
                class="board-task-heading"
                @click="viewTask(task.id)"
              >
                {{ task.title }}
              </p>

              <div class="boardDropdown">
                <b-dropdown
                  toggle-class="text-decoration-none"
                  no-caret
                  append-to-body
                >
                  <template v-slot:button-content>
                    <button class="edit-del-button">
                      <feather-icon
                        icon="MoreHorizontalIcon"
                        size="21"
                        class="align-middle edit-data-delete"
                      />
                    </button>
                  </template>
                  <b-dropdown-item
                    v-if="addEditRights"
                    class="edit-delete-item"
                    @click="editText(task.id, task.title)"
                  >
                    <feather-icon
                      icon="EditIcon"
                      size="16"
                      class="mr-50 edit-icons"
                    />
                    <span class="edit-data">Edit task name</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="addEditRights"
                    class="edit-delete-item"
                  >
                    <feather-icon
                      icon="CopyIcon"
                      size="16"
                      class="mr-50 edit-icons"
                    />
                    <span
                      class="edit-data"
                      @click="duplicateTask(task.id)"
                    >Duplicate Task</span>
                  </b-dropdown-item>
                  <b-dropdown-item class="edit-delete-item">
                    <feather-icon
                      icon="Link2Icon"
                      size="16"
                      class="mr-50 edit-icons link-icon"
                    />
                    <span
                      class="edit-data"
                      @click="copyLink(task.id)"
                    >
                      Copy task link</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="addEditRights"
                    class="edit-delete-item"
                    @click="archiveTask(task.id)"
                  >
                    <feather-icon
                      icon="FileIcon"
                      size="16"
                      class="mr-50 edit-icons"
                    />
                    <span class="edit-data">Archive</span>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>

            <div
              v-b-modal.addtaskdata
              class="my-50"
              @click="viewTask(task.id)"
            >
              <button
                v-for="(tag, tindex) in taskTagArray(task.tag_name)"
                :key="tindex"
                class="task-tag"
              >

                <span class="task-tag-title">{{ tag }}</span>
              </button>
              <div class="d-flex justify-content-between align-items-center">
                <p
                  v-b-modal.addtaskdata
                  class="assignTitle"
                  @click="viewTask(task.id)"
                >
                  Assigned to
                </p>
                <p
                  v-b-tooltip.hover.top="'Ticket No.'"
                  class="assignTitle"
                  style="color: #2178fb"
                >
                  {{ task.task_number }}
                </p>
              </div>
            </div>

            <div
              v-b-modal.addtaskdata
              class="d-flex justify-content-between flex-wrap"
              @click="viewTask(task.id)"
            >
              <b-avatar-group>
                <b-avatar
                  v-if="task.assignee_user_id"
                  v-b-tooltip.hover
                  class="pull-up"
                  :src="task.user_picture"
                  :text="task.full_name | avatarText"
                  :title="task.full_name"
                />
                <b-avatar
                  v-if="task.reporter_user_id"
                  v-b-tooltip.hover
                  class="pull-up"
                  variant="primary"
                  :src="task.r_user_picture"
                  :text="task.reporter_full_name | avatarText"
                  :title="task.reporter_full_name"
                />
              </b-avatar-group>
              <div class="d-flex align-items-center">
                <b-icon
                  v-if="task.priority == 'high'"
                  v-b-tooltip.hover.top="'High'"
                  icon="Flag-fill"
                  class="flag-fill-priority text-danger"
                />
                <b-icon
                  v-if="task.priority == 'med'"
                  v-b-tooltip.hover.top="'Medium'"
                  icon="Flag-fill"
                  class="flag-fill-priority-medium flag-icon"
                  style="color: #ffa900;"
                />
                <b-icon
                  v-if="task.priority == 'low'"
                  v-b-tooltip.hover.top="'Low'"
                  icon="Flag-fill"
                  class="flag-fill-priority-low"
                />
                <p class="task-date">
                  {{ task.due_date | timeDayFormat }}
                </p>
              </div>
            </div>
          </div>
        </draggable>
      </div>

      <div
        v-if="addEditRights && !addNewTextfield"
        class="add-new-board-status-btn"
        :draggable="false"
      >
        <div class="d-flex">
          <span
            v-if="!addNewTextfield"
            v-b-modal.addtaskdata
            class="add-board"
            @click="addNewTextfield = true"
          >
            <feather-icon
              icon="PlusIcon"
              size="18"
              class="mb-25"
            />
            <span class="add-new-status-title ml-25">Add New Status</span>
          </span>
        </div>
      </div>
      <div
        v-if="addEditRights && addNewTextfield"
        class="add-new-board-status"
      >
        <validation-observer ref="boardStatus">
          <div v-if="addNewTextfield">
            <validation-provider
              #default="{ errors }"
              name="Task Status"
              rules="required"
            >
              <b-form-input
                v-if="addNewTextfield"
                id="testing1"
                v-model="statusName"
                placeholder="Add new status *"
                type="text"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>

            <b-form-group
              v-if="addNewTextfield"
              class="mt-1"
            > <validation-provider
              #default="{ errors }"
              name="Category"
              rules="required"
            >
              <v-select
                v-model="category"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                placeholder="Select Category *"
                :options="option"
                :reduce="(option) => option.value"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            </b-form-group>
          </div>
        </validation-observer>
        <div v-if="addNewTextfield">
          <button
            class="board-status-btn commonBtn "
            :disabled="statusLoader"
            @click.prevent="validateAddStatus"
          >
            Save
          </button>
          <button
            class="board-status-btn commonBtn-outline"
            @click.prevent="addNewTextfield = false"
          >
            Cancel
          </button>
        </div>
      </div>
    </draggable>
    <add-task
      id="addtaskdata"
      :project-dtl="projectDtl"
      :task-i-d="viewTaskID"
      :board-status="boardStatus"
      :from="from == 'generalBoard' ? 'generalBoard' : 'project'"
      type="board"
      @disableTaskID="disableTaskID"
    />

    <b-modal
      v-if="statuses && statuses.length"
      :id="id"
      v-model="isStatusModal"
      cancel-variant="outline-secondary"
      centered
      size="lg"
    >

      <template #modal-title>
        <h3 class="sprint-from-title ml-0">
          Tasks
        </h3>

        <div class="complete-underline w-100" />
      </template>
      <validation-observer ref="boardStatusTasksRules">

        <p class="note m-0 pb-50">
          {{ status }} Status Task count : {{ specificTaskCount }}
        </p>
        <validation-provider
          #default="{ errors }"
          name="Task Status"
          rules="required"
        >
          <v-select
            id="sprintID"
            v-model="task_status"
            label="name"
            :options="statuses"
            class="select-size-lg select-task-option cu-select"
            name="sprint_id"
            :reduce="(st) => st.id"
            placeholder="Select Status"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>

      </validation-observer>
      <template #modal-footer>
        <b-button
          class="complete-btn"
          :disabled="loader"
          @click="moveTaskValidation"
        >
          <b-spinner
            v-if="loader"
            small
            class="spinner"
          />Save
        </b-button>
        <b-button
          class="cancel-btn"
          @click="closeModal"
        >
          Cancel
        </b-button>
      </template>

    </b-modal>

    <b-modal
      v-else
      :id="id"
      v-model="isStatusModal"
      cancel-variant="outline-secondary"
      centered
      hide-footer
      size="lg"
    >

      <h3
        class="pt-2 "
        style="color:#2178fb"
      >
        Create new project status with Done category & move current task on that.
      </h3>

    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import draggable from 'vuedraggable'
import AddTask from '@/views/project/List/AddTask.vue'
import { eventBus } from '@/main'
import { BDropdown, BDropdownItem } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import BoardFilter from './boardFilter.vue'
import BoardShimmer from '../../shimmer/BoardShimmer.vue'

export default {
  name: 'Board',
  components: {
    draggable,
    AddTask,
    BDropdown,
    BDropdownItem,
    BoardFilter,
    BoardShimmer,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    projectDtl: {
      type: Object,
      required: true,
      default: null,
    },
    from: {
      type: String,
      required: true,
      default: 'projectDetail',
    },
  },
  data() {
    return {
      textEdit: false,
      statusLoader: false,
      taskid: null,
      tasktitle: null,
      text: true,
      projectTasks: [],
      taskStatus: [],
      viewTaskID: null,
      viewTaskDtl: false,
      boardStatus: {},

      assigneeFilterId: [],
      tags: [],
      search: null,
      taskLoader: false,
      isStatusModal: false,
      task_status: null,
      specificTaskCount: null,
      list: [],
      addNewTextfield: false,
      loader: false,
      option: [
        { title: 'To Do', value: 'to_do' },
        { title: 'In Progress', value: 'in_progress' },
        { title: 'Done', value: 'done' },
      ],
      category: 'to_do',
      statusName: null,
      status: null,
      id: null,
      sectionId: null,
      statusDetail: null,
      allProjectStatus: [],
    }
  },
  computed: {
    addEditRights() {
      if (!this.projectDtl || !this.projectDtl.active_sprint || !this.projectDtl.active_milestone) return false
      if (this.userInfo && this.userInfo.role === 'SA') {
        return true
      }
      if (
        this.$store.state.app.projectUsers
        && this.$store.state.app.projectUsers.length
      ) {
        const user = this.$store.state.app.projectUsers.find(
          pu => pu.id === this.userInfo.id,
        )
        if (
          user
          && (user.access_rights === 'project_owner'
            || user.access_rights === 'co_ordinator')
        ) {
          return true
        }
      }
      return false
    },
    statuses() {
      const currentStatus = this.$store.state.app.projectTaskStatus.filter(a => (a.id == this.id) && a.category === 'done')
      let status = []
      if (currentStatus && currentStatus.length) {
        status = this.$store.state.app.projectTaskStatus.filter(a => (a.id !== this.id && a.is_active && a.category === 'done'))
      } else {
        status = this.$store.state.app.projectTaskStatus.filter(a => (a.id !== this.id && a.is_active) || a.id === 'archive')
      }
      if (status && status.length) status.sort((a, b) => a.name - b.name)
      return status.sort((a, b) => a.name - b.name)
    },

  },
  watch: {
    isStatusModal(nv) {
      if (!nv) {
        this.task_status = null
      }
    },
  },
  mounted() {
    if (
      this.$store.state.app.projectBoardColor
      && this.$store.state.app.projectBoardColor.code
      && this.$store.state.app.projectBoardColor.project_id
      === this.$route.params.id3
    ) {
      this.$store.state.app.boardBackGroundColor = this.$store.state.app.projectBoardColor.code
    } else {
      this.backgroundColorSet()
    }
    this.getAllTaskStatus(true)

    if (this.$route.params.id4) {
      this.viewTask(this.$route.params.id4)
      this.$root.$emit('bv::toggle::modal', 'addtaskdata')
    }

    if (this.$route.query && this.$route.query.taskId) {
      this.viewTask(this.$route.query.taskId)
      this.$root.$emit('bv::toggle::modal', 'addtaskdata')
    }

    eventBus.$on('reloadBoardCompleteTaskList', data => {
      if (data) {
        this.getProjectTaskLists()
      }
    })
    eventBus.$on('boardApplyFilters', data => {
      if (data) {
        this.eventBusFunction(data)
      }
    })
    eventBus.$on('clearFilters', data => {
      if (data) {
        this.eventBusFunction(data)
      }
    })
    eventBus.$on('calenderFilters', data => {
      if (data) {
        this.eventBusFunction(data)
      }
    })

    eventBus.$on('reloadGeneralProjectBoardTaskList', data => {
      if (data && this.from == 'generalBoard') {
        this.eventBusFunction(data)
      }
    })

    if (this.projectDtl && !this.projectDtl.active_sprint || !this.projectDtl.active_milestone) {
      this.$swal({
        title: 'No active sprint',
        icon: 'info',
        html: '<p>Currently, there is no active sprint in this project. To add tasks to the board, please activate a sprint.</p>',
        showCloseButton: true,
        showCancelButton: false, // Hide cancel button
        showConfirmButton: false, // Hide confirm button
        focusConfirm: false,
        customClass: {
          confirmButton: 'btn confirm',
          cancelButton: 'btn cancel ml-1',
        },
        buttonsStyling: false,
      })
    }
  },
  destroyed() {
    eventBus.$off('calenderFilters')
    eventBus.$off('boardApplyFilters')
    eventBus.$off('reloadBoardCompleteTaskList')
    eventBus.$off('clearFilters')
    this.$store.dispatch('app/CLEAR_BOARD_BACKGROUND_COLOR')
  },
  methods: {

    // Active section title text field
    sectionParentTitle(val) {
      this.sectionId = val
    },

    validateAddStatus() {
      this.$refs.boardStatus.validate().then(success => {
        if (success) {
          this.saveStatus()
        }
      })
    },
    // create new status section
    async saveStatus() {
      this.statusLoader = true
      const input = {
        project_id: this.projectDtl.id,
        name: this.statusName ? this.statusName : 'Untitled',
        type: 'project',
        category: this.category ? this.category : 'to_do',
      }
      const response = await this.getHTTPPostResponse(
        'project/task-status/create',
        input,
        false,
      )
      if (response && response.status === 200) {
        this.getAllTaskStatus()
        this.statusName = null
        this.category = null
        this.addNewTextfield = false
      }
      this.statusLoader = false
    },
    async updateSection(data, index) {
      const input = {
        project_id: this.projectDtl.id,
        name: data.name ? data.name : 'Untitled',
        type: 'project',
        category: data.category,
      }
      const response = await this.getHTTPPutResponse(
        `project/task-status/update/${data.id}`,
        input,
      )

      if (response && response.status === 200) {
        this.$store.state.app.projectTaskStatus[index].name = data.name ? data.name : 'Untitled'
      }
      this.sectionId = null
    },

    closeModal() {
      this.isStatusModal = false
    },

    /** event bus common code  */
    eventBusFunction(data) {
      this.assigneeFilterId = data.assignee
      this.tags = data.tags
      this.search = data.search
      this.getProjectTaskLists()
    },
    // Board Background color set
    async backgroundColorSet() {
      const response = await this.getHTTPGetResponse(
        `project/board-color/view/${this.projectDtl.id}`,
      )
      if (response && response.data && response.data.project_color) {
        this.$store.commit(
          'app/UPDATE_PROJECT_BOARD_COLOR',
          response.data.project_color,
        )

        this.$store.state.app.boardBackGroundColor = response.data.project_color && response.data.project_color.code
      }
    },

    // Copy Link Task
    copyLink(taskId) {
      if (this.from === 'projectDetail') {
        navigator.clipboard.writeText(
          `${window.location.href}/${taskId}`,
        )
      } else {
        const locationHost = location.host
        navigator.clipboard.writeText(
          `${locationHost
          + this.$router.options.base
          }general-board?projectId=${this.projectDtl.id}&taskId=${taskId}&type=project`,
        )
      }
      this.$bvToast.toast('Link copied successfully', {
        toaster: 'b-toaster-top-right',
        solid: true,
        title: 'Success',
        variant: 'success',
      })
    },
    outFocus() { },
    // Update Task Title
    async onEnter(id, title) {
      this.taskid = null
      const input = {
        project_id: this.projectDtl.id,
        title: this.tasktitle ? this.tasktitle : title,
        project_task_id: id,
      }
      const response = await this.getHTTPPostResponse(
        'project/task/update-title',
        input,
        false,
      )
      if (response && response.data) {
        this.text = true
        this.textEdit = false
        this.getProjectTaskLists()
      }
    },
    // Archive Task Title
    async archiveTask(id) {
      const input = {
        project_task_id: id,
      }
      const response = await this.getHTTPPostResponse(
        'project/task/task-archive',
        input,
        true,
      )
      if (response && response.data) {
        this.getProjectTaskLists()
      }
    },
    //  Add Task Modal open and viewTaskID null
    addtaskData(val) {
      this.boardStatus = val
      this.viewTaskID = null
    },
    //  Add Task Modal open and viewTaskID set
    viewTask(id) {
      this.viewTaskID = id
      if (this.from === 'generalBoard') {
        this.$router.push({
          query: { projectId: this.projectDtl.id, taskId: id, layout: this.currentLayout },
        }).catch(err => {
        // Ignore the NavigationDuplicated error
          if (err.name !== 'NavigationDuplicated') {
            console.log(err)
          }
        })
      } else {
        this.$router.push({
          name: 'projectTask',
          params: {
            ...this.$route.params,
            id4: id,
          },
          query: this.$route.query,
        }).catch(err => {
        // Ignore the NavigationDuplicated error
          if (err.name !== 'NavigationDuplicated') {
            console.log(err)
          }
        })
      }
    },
    editText(id, title) {
      if (id) {
        this.taskid = id
        this.textEdit = true
        this.tasktitle = title
      }
    },
    // disable TaskID form Emit
    disableTaskID() {
      this.viewTaskID = null
      this.boardStatus = {}
      if (this.from == 'generalBoard') {
        this.$router.push({
          query: { projectId: this.projectDtl.id, layout: this.currentLayout },
        }).catch(err => {
        // Ignore the NavigationDuplicated error
          if (err.name !== 'NavigationDuplicated') {
            console.log(err)
          }
        })
      } else {
        this.$router.push({
          name: 'projectTask',
          params: {
            id: 'board',
            id2: this.projectDtl.name,
            id3: this.$route.params.id3,
          },
        }).catch(err => {
        // Ignore the NavigationDuplicated error
          if (err.name !== 'NavigationDuplicated') {
            console.log(err)
          }
        })
      }
    },
    closeModalEvent() {
      this.getProjectTaskLists()
      this.viewTaskDtl = false
    },
    /** Todo:08-08-23 change all data need to show */
    async getProjectTaskLists() {
      if (!this.projectDtl
        || !this.projectDtl.active_milestone
        || !this.projectDtl.active_sprint) {
        return
      }
      this.taskLoader = true
      const input = {
        project_id: this.projectDtl.id,
        milestone_id: this.projectDtl.active_milestone.id,
        sprint_id: this.projectDtl.active_sprint.id,
        user_id:
          this.assigneeFilterId && this.assigneeFilterId.length
            ? this.assigneeFilterId
            : [],
        tags: this.tags,
        search: this.search,
      }
      const response = await this.getHTTPPostResponse(
        'project/task/list',
        input,
        false,
      )
      if (response && response.data) {
        const allStatus = []

        this.$store.state.app.projectTaskStatus.forEach(status => {
          status.tasks = []
          if (
            response.data.project_tasks
            && response.data.project_tasks.length
          ) {
            response.data.project_tasks.forEach(task => {
              if (status.id === task.status_id) {
                status.tasks.unshift(task)
              }
            })
          }
          allStatus.push(status)
        })
        this.taskStatus = allStatus
        this.taskLoader = false
      }
    },
    specificTask(status) {
      return this.projectTasks.filter(element => element.task_status === status)
    },
    getChangeData(event, status) {
      const eventName = Object.keys(event)[0]
      if (eventName == 'added') {
        this.updateStatus(event.added.element.id, status)
      }
    },
    async duplicateTask(id) {
      const input = {
        project_task_id: id,
        project_id: this.$route.params.id3,
      }
      const response = await this.getHTTPPostResponse(
        'project/task/duplicate',
        input,
        false,
      )
      if (response && response.status === 200) {
        this.getProjectTaskLists()
      }
    },
    async updateStatus(id, status) {
      this.loader = true
      const input = {
        // task_status: status,
        status_id: status,
        project_id: this.projectDtl && this.projectDtl.id,
        is_non_project: false,
      }
      const response = await this.getHTTPPostResponse(
        `project/task/status/${id}`,
        input,
        false,
      )
      if (response && response.status === 200) {
        /* need to code refactor */
        this.loader = false
      }
      this.loader = false
    },

    taskTagArray(items) {
      if (items && items.length) {
        return items.split(',')
      }
      return []
    },

    /*
    * Project Status Methods
    */

    async getAllTaskStatus() {
      const input = {
        sort_field: 'display_order',
        sort_order: 'asc',
        type: 'project',
        is_active: true,
        project_id: this.projectDtl.id,
      }
      const response = await this.getHTTPPostResponse(
        'project/task-status',
        input,
        false,
      )
      if (response && response.data) {
        const a = response.data.task_statuses
        this.$store.state.app.projectTaskStatus = a
        let displayOrder = 0
        displayOrder = a && a.length && a[a.length - 1].display_order ? a[a.length - 1].display_order : 0
        this.$store.state.app.projectTaskStatus.push({ id: 'archive', name: 'archive', display_order: displayOrder + 1 })
        this.allProjectStatus = a
        this.getProjectTaskLists()
      }
    },

    /* display order update */
    async updateDisplayOrder(val) {
      const input = {
        project_id: this.projectDtl.id,
        id: val?.item?._underlying_vm_.id,
        display_order: val.newIndex + 1,
        type: 'project',
      }

      const response = await this.getHTTPPostResponse(
        'project/task-status/update-order',
        input,
      )
      if (response && response.status === 200) {
        // this.getAllTaskStatus()
      }
    },

    /* if delete project status if this status have any then move to other status for that open modal for update status other wise take delete conformation  */
    async deleteConfirmation(item) {
      this.status = item.name
      this.statusDetail = item
      this.specificTaskCount = item.project_tasks_count

      this.id = item.id
      this.is_status_active = item.is_active
      const input = {
        project_id: this.projectDtl.id,
        id: item.id,
      }
      const response = await this.getHTTPPostResponse(
        'project/task-status/status-tasks-count',
        input,
      )
      if (response && response.status === 200) {
        this.specificTaskCount = response && response.data && response.data.status_task_count && response.data.status_task_count.length && response.data.status_task_count[0].project_tasks_count
        if (!this.specificTaskCount) {
          this.$swal({
            title: 'Are you sure?',
            icon: 'info',
            html: '<p>Once you delete you will not able to recover this record.</p>',
            showCloseButton: true,
            showCancelButton: true,
            focusConfirm: false,
            confirmButtonText: 'Delete',
            confirmButtonAriaLabel: 'Thumbs up, great!',
            cancelButtonAriaLabel: 'Thumbs down',
            customClass: {
              confirmButton: 'btn confirm',
              cancelButton: 'btn cancel ml-1',
            },
            buttonsStyling: false,
          }).then(result => {
            if (result.value) {
              this.deleteStatus(item.id)
            }
          }) // latest
        } else if (this.specificTaskCount) {
          this.delId = item.id
          this.isStatusModal = true
        }
      }

      return ''
    },
    /* check validation for new selected status */
    moveTaskValidation() {
      this.$refs.boardStatusTasksRules.validate().then(success => {
        if (success) {
          this.moveTask()
        } else {
          return false
        }
      })
    },
    /* particular status all task move to new selected status */
    async moveTask() {
      const input = {
        id: this.id,
        move_to: this.task_status,
        status_id: this.statusDetail && this.statusDetail.id,
      }
      if (this.delId) {
        this.deleteStatus(this.delId)
      } else {
        const response = await this.getHTTPPostResponse(
          'project/task-status/change-status',
          input,
        )
        if (response && response.status === 200) {
          this.isStatusModal = false
          setTimeout(() => {
            this.task_status = null
          }, 1000)
          this.getAllTaskStatus()
        }
      }
    },

    /* delete project status */
    async deleteStatus(id) {
      const input = {
        id,
        move_to: this.task_status ? this.task_status : 'archive',
      }
      const response = await this.getHTTPPostResponse(
        'project/task-status/delete',
        input,
        true,
      )
      if (response && response.status === 200) {
        this.isStatusModal = false
        this.getAllTaskStatus()
      }
    },

  },
}
</script>

<style>
@import "../../../assets/scss/component-css/board.css";
@import "../../../assets/scss/component-css/list.css";

/* Unfortunately @apply cannot be setup in codesandbox,
but you'd use "@apply border opacity-50 border-blue-500 bg-gray-200" here */
.moving-card {
  opacity: 0.5;
  background: #f7fafc;
  border: 1px solid #4299e1;
}

/* Add section text field css */
.section-group {
  border: 1px solid !important;
}

.section-group .section-textfield {
  background: transparent;
}

.section-group .input-group-text {
  background: transparent;
}

/*  */
.add-new-board-status {
  padding: 12px;
  width: 300px;
  min-width: 300px;
  margin-right: 20px;
  background-color: #f1f2f4;
  border-radius: 12px;
}

.add-new-board-status-btn {
  padding: 14px;
  width: 300px;
  min-width: 300px;
  margin-right: 20px;
  background-color: #f1f2f4;
  border-radius: 12px;
}

.add-new-status-title {
  font-size: 18px;
  color: #5e5873;
  font-weight: 700;

}

.min-heigth {
  min-height: 150px !important;
}
</style>
