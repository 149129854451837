<template>
  <div>
    <div v-if="!loader">
      <b-form>
        <div class="test-form mt-1">
          <b-row>
            <!-- first name -->
            <b-col cols="4">
              <b-form-group
                label="Defect ID"
                label-for="v-defect-id"
              >
                <p>{{ $store.state.app.defect.code }}</p>
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group
                label="Defect Title"
                label-for="v-defect-title"
                class="mb-0"
              >
                <p>{{ $store.state.app.defect.defect_title }}</p>
              </b-form-group>
            </b-col>
            <b-col
              cols="4"
              class="modal-select"
            >
              <b-form-group
                label="Severity"
                label-for="Severity"
                class="mb-0"
              >
                <p>{{ $store.state.app.defect.severity | capitalize }}</p>
              </b-form-group>
            </b-col>

            <b-col
              cols="4"
              class="modal-select"
            >
              <b-form-group
                label="Test Suite"
                label-for="Assignee"
                class="mb-0"
              >
                <p>{{ testSuite() }}</p>
              </b-form-group>
            </b-col>

            <b-col
              cols="4"
              class="modal-select"
            >
              <b-form-group
                label="Defect Type"
                label-for="Assignee"
                class="mb-0"
              >
                <p>{{ $store.state.app.defect.defect_type | capitalize }}</p>
              </b-form-group>
            </b-col>

            <b-col
              cols="4"
              class="modal-select"
            >
              <b-form-group
                label="Assignee"
                label-for="Assignee"
                class="mb-0"
              >
                <p>{{ findUser() }}</p>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- <h4>No content added yet</h4> -->
        </div>
        <div class="test-form mt-1">
          <b-row>
            <b-col cols="12">
              <div class="description view-steps">
                <label for="textarea-default">Issue Description</label>
                <div
                  v-if="
                    $store.state.app.defect.issue_discription &&
                      checkDesription($store.state.app.defect.issue_discription)
                  "
                  v-html="$store.state.app.defect.issue_discription"
                />
                <h4 v-else>
                  No content added yet
                </h4>
              </div>
            </b-col>
          </b-row>

          <!--  -->
        </div>
        <div class="test-form mt-1">
          <b-row>
            <b-col cols="12">
              <div class="description">
                <label for="textarea-default">Attachment</label>

                <div
                  v-if="$store.state.app.defect.attachment.length"
                  class="mb-1 download-content ml-0"
                  style=""
                >
                  <div class="download-files">
                    <div
                      v-for="(image, key) in $store.state.app.defect.attachment"
                      :key="key"
                      class="download-link p-50"
                      role="button"
                      @click="openFileInNewTab(image.url)"
                    >
                      <!-- <a :href="image.url | attachmentURL" target="_blank"> -->
                      {{ image.name }}
                      <!-- </a> -->
                      <feather-icon
                        icon="EyeIcon"
                        color="white"
                      />
                    </div>
                  </div>
                </div>

                <h4 v-else>
                  No content added yet
                </h4>
              </div>
            </b-col>
          </b-row>

          <!-- <h4>No content added yet</h4> -->
        </div>
      </b-form>
      <div class="test-form mt-1">
        <div class="scenario-steps view-steps">
          <b-row>
            <b-col cols="12">
              <div>
                <label for="textarea-default">Steps To Reproduce</label>
                <div
                  v-if="
                    $store.state.app.defect.step_to_reproduce &&
                      checkDesription($store.state.app.defect.step_to_reproduce)
                  "
                  v-html="$store.state.app.defect.step_to_reproduce"
                />
                <h4 v-else>
                  No content added yet
                </h4>
              </div>
            </b-col>
          </b-row>
        </div>
        <!-- <h4>No content added yet</h4> -->
      </div>
    </div>

    <defect-logs-vue :loader="loader" />
    <DefectViewLoader v-if="loader" />
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BForm,
} from 'bootstrap-vue'
import DefectViewLoader from './Loader/DefectViewLoader.vue'
import DefectLogsVue from './DefectLogs.vue'

export default {
  name: 'ViewDefect',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BForm,
    DefectViewLoader,
    DefectLogsVue,
  },
  props: {
    loader: {
      type: Boolean,
      default: true,
    },
  },

  methods: {
    findUser() {
      const id = this.$store.state.app.defect.assignee
      this.$store.state.app.projectUsers.forEach(element => {
        element.name = element.full_name
        element.value = element.id
      })
      const element = this.$store.state.app.projectUsers.find(
        element => element.value == id,
      )
      return element && element.name ? element.name : '-'
    },
    testSuite() {
      const id = this.$store.state.app.defect.test_suite

      const element = this.$store.state.app.suiteListWithoutTestCase.find(
        element => element.id == id,
      )
      return element && element.suite_name ? element.suite_name : '-'
    },
    checkDesription(value) {
      const str = value.replace(/<p>\s*<\/p>|<p><br><\/p>|<br>/g, '')
      return str.length > 0
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/component-css/tesing.css";
.more {
  display: inline-block;
  margin: 16px;
  border: 3px dashed lightgray;
  width: 200px;
  height: 200px;
  box-sizing: border-box;
  color: lightgray;
  border-radius: 8px;
  font-size: 60px;
  text-align: center;
  line-height: 200px;
  pointer-events: none;
}

.in-progress {
  border-radius: 8px;
  padding: 10px 24px;
  background: rgba(33, 120, 251, 0.12) !important;
  color: rgb(33, 120, 251) !important;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  min-width: 110px;
  text-transform: capitalize;
}

.passed {
  border-radius: 8px;
  padding: 10px 24px;
  background: rgba(40, 199, 111, 0.12) !important;
  color: rgb(35, 188, 116) !important;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  min-width: 110px;
  text-transform: capitalize;
}

.defect {
  border-radius: 8px;
  padding: 10px 24px;
  background: rgba(249, 119, 83, 0.12) !important;
  color: rgb(249, 119, 83) !important;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  min-width: 110px;
  text-transform: capitalize;
}
.download-link {
  color: #fff;
}
</style>
