<template>
  <div class="">

    <div class="noteCardinfo">
      <div class="noteHeader d-flex justify-content-between">
        <span class="notecardTitle"> {{ data.title }}</span>
        <feather-icon
          icon="Trash2Icon"
          class="trash-icon"
          size="22"
          @click="deleteConfirmation(data.id)"
        />
      </div>
      <div
        v-b-modal="data.id"
        class="noteBody"
        @click="callEditor(data)"
      >
        <div
          class="fixHight"
          v-html="data.description"
        />
      </div>
      <div class="noteFooter">
        <div class="notDropdown d-flex align-items-center">

          <custom-dropdown
            v-model="users"
            :options="project_users"
            :created-by="data && data.created_by && data.created_by.id"
            multiple
            disabled
            icon="UserPlusIcon"
            class="mr-50 noteDropdown"
          >

            <span @click="noteRights()">
              Apply
            </span>
          </custom-dropdown>
          <b-avatar
            v-if="data.users && data.users.length"
            v-b-tooltip.hover.top="data.users[0].full_name"
            size="26px"
            class="ml-25 noteAvatar"
            :src="data.users[0].picture"
            :text="data.users[0].full_name | avatarText"
          />

          <b-avatar
            v-if="data.users && data.users.length > 1"
            v-b-tooltip.hover.top="data.users[1].full_name"
            size="26px"
            class="noteAvatar"
            :src="data.users[1].picture"
            :text="data.users[1].full_name | avatarText"
          />

          <div
            v-if="data.users && data.users.length && data.users.length > 2"
            v-b-tooltip.hover.top="asseccRightsUser()"
            class="skillMore d-flex justify-content-center align-items-center projecrMembermore"
          >
            <feather-icon
              icon="PlusIcon"
              size="9"
            />
            <span>{{ data.users.length - 2 }}</span>
          </div>
        </div>
        <div class="d-flex align-items-center">

          <div
            class=""
            @click="callEditor(data)"
          >
            <b-avatar
              v-b-tooltip.hover.top="data && data.updated_by
                ? data.updated_by.full_name
                : data.created_by.full_name
              "
              size="24"
              :src="
                data && data.updated_by
                  ? data.updated_by.picture
                  : data.created_by.picture
              "
              :text="
                data && data.updated_by
                  ? data.updated_by.full_name
                  : data.created_by.full_name
              "
            />
          </div>
          <div
            class="noteuserActivity"
            @click="callEditor(data)"
          >
            <span>Last updated<br>
              {{ data.updated_at | DDMMYYYYDDDD }}
              <!-- 14 Aug 2022 at 10:29AM -->
            </span>
          </div>
        </div>
      </div>
    </div>
    <NotesEditors

      :id="data.id"
      :data="data"
      :tags="tags"
    />
  </div>
</template>

<script>
import { eventBus } from '@/main'
import moment from 'moment'
import NotesEditors from './NotesEditors.vue'
import customDropdown from '../dropdown/customDropdown.vue'

export default {
  name: 'Notescard',
  components: {
    NotesEditors,
    customDropdown,
  },
  filters: {
    DDMMYYYYDDDD(value) {
      if (value) {
        // 14 Aug 2022 at 10:29AM
        return moment(value).calendar()
      }
    },
  },
  props: {
    data: {
      type: Object,
      default: null,
    },
    projectDtl: {
      required: true,
      default: null,
    },
    tags: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      loader: false,
      users: [],
    }
  },
  computed: {
    project_users() {
      const users = []
      this.projectDtl.project_user.forEach(element => {
        users.push({
          value: element.id,
          label: element.full_name,
          picture: element.picture,
          access_rights: element.access_rights,
        })
      })

      return users
    },
  },
  watch: {},
  mounted() {
    this.users = []

    this.data.users.forEach(element => {
      this.users.push(element.id)
    })
    if (this.data && this.data.created_by && this.data.created_by.id) {
      const a = this.users.filter(e => e === this.data.created_by.id)
      if (a && !a.length) {
        this.users.push(this.data.created_by.id)
      }
    }
  },
  methods: {
    asseccRightsUser(data) {
      let asseccRightsUser = ''
      this.data.users.forEach((element, index) => {
        if (index != 0 && index != 1) {
          asseccRightsUser = asseccRightsUser ? `${asseccRightsUser} , ${element.full_name}` : element.full_name
        }
      })
      return asseccRightsUser
    },
    callEditor(data) {
      eventBus.$emit('updateNote', data)
    },
    async noteRights() {
      if (this.loader) return
      this.loader = true

      const user = this.project_users.filter(e => e.access_rights === 'project_owner').map(s => s.value)

      const input = {
        user_ids: this.userInfo.role === 'EMP' ? [...this.users, ...user] : this.users,
        note_id: this.data.id,
      }
      const response = await this.getHTTPPostResponse(
        'project/note/user',
        input,
        false,
      )
      if (response && response.data) {
        this.$emit('noteList', true)
      }
      this.loader = false
    },
    /**
     * confirmation to delete user
     */
    deleteConfirmation(id) {
      this.$swal({
        title: 'Are you sure you want to delete this Record?',
        icon: 'info',
        html: '<p>You won’t be able to revert this!</p>',
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: 'Yes, delete it!',
        confirmButtonAriaLabel: 'Thumbs up, great!',
        cancelButtonAriaLabel: 'Thumbs down',
        customClass: {
          confirmButton: 'btn confirm',
          cancelButton: 'btn cancel ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.deleteNote(id)
        }
      })
    },
    /**
     * delete client
     */
    async deleteNote(id) {
      const input = {
        project_id: this.$route.params.id3,
      }
      const response = await this.getHTTPDeleteResponse(
        `project/note/delete/${id}`,
        input,
        true,
      )
      if (response && response.status == 200) {
        this.$emit('noteList', true)
      }
    },
  },
}
</script>

<style scoped>
/* temp*/
.fixHight {
  display: -webkit-box;
  -webkit-line-clamp: 9;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.fixHightCard {
  max-height: 500px;
}
</style>
