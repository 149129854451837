<template>
  <div style="margin-bottom: 80px;">
    <b-row>
      <b-col
        v-if="userInfo && userInfo.role && userInfo.role == 'EMP'"
        cols="12"
        lg="8"
      >
        <div class="aq-summary-card">
          <div class="header">
            <h3 class="mb-2">
              QAssure Report
            </h3>
          </div>
          <div class="qa-report">
            <b-row>
              <b-col
                cols="12"
                lg="3"
                xl="2"
              >
                <div class="qa-report-width">
                  <div
                    class="qa-assure-report-card total"
                    @click="reDirectTab('all')"
                  >
                    <h5>Total Defects</h5>
                    <p>{{ items.count }}</p>
                  </div>
                </div>
              </b-col>
              <b-col
                cols="12"
                lg="3"
                xl="2"
              >
                <div
                  class="qa-report-width"
                  @click="reDirectTab('open')"
                >
                  <div class="qa-assure-report-card open">
                    <h5>Open</h5>
                    <p>{{ items.open }}</p>
                  </div>
                </div>
              </b-col>
              <b-col
                cols="12"
                lg="3"
                xl="2"
              >
                <div
                  class="qa-report-width"
                  @click="reDirectTab('reopen')"
                >
                  <div class="qa-assure-report-card reopen">
                    <h5>Reopen</h5>
                    <p>{{ items.reopen }}</p>
                  </div>
                </div>
              </b-col>
              <b-col
                cols="12"
                lg="3"
                xl="2"
              >
                <div
                  class="qa-report-width"
                  @click="reDirectTab('resolve')"
                >
                  <div class="qa-assure-report-card resolved">
                    <h5>Resolved</h5>
                    <p>{{ items.resolved }}</p>
                  </div>
                </div>
              </b-col>
              <b-col
                cols="12"
                lg="3"
                xl="2"
              >
                <div
                  class="qa-report-width"
                  @click="reDirectTab('not_an_issue')"
                >
                  <div class="qa-assure-report-card not-a-issuecard">
                    <h5>Not An Issue</h5>
                    <p>{{ items.not_an_issue }}</p>
                  </div>
                </div>
              </b-col>
              <b-col
                cols="12"
                lg="3"
                xl="2"
              >
                <div
                  class="qa-report-width"
                  @click="reDirectTab('close')"
                >
                  <div class="qa-assure-report-card closeCard">
                    <h5>Close</h5>
                    <p>{{ items.close }}</p>
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-col>

      <b-col
        v-else
        cols="12"
        lg="4"
      >
        <div class="aq-summary-card">
          <div class="header">
            <h3 class="mb-2">
              QAssure Report
            </h3>
          </div>
          <div class="qa-report">
            <b-row>
              <b-col
                cols="12"
                lg="3"
                xl="4"
              >
                <div class="qa-report-width">
                  <div
                    class="qa-assure-report-card total"
                    @click="reDirectTab('all')"
                  >
                    <h5>Total Defects</h5>
                    <p>{{ items.count }}</p>
                  </div>
                </div>
              </b-col>
              <b-col
                cols="12"
                lg="3"
                xl="4"
              >
                <div
                  class="qa-report-width"
                  @click="reDirectTab('open')"
                >
                  <div class="qa-assure-report-card open">
                    <h5>Open</h5>
                    <p>{{ items.open }}</p>
                  </div>
                </div>
              </b-col>
              <b-col
                cols="12"
                lg="3"
                xl="4"
              >
                <div
                  class="qa-report-width"
                  @click="reDirectTab('reopen')"
                >
                  <div class="qa-assure-report-card reopen">
                    <h5>Reopen</h5>
                    <p>{{ items.reopen }}</p>
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
          <div class="qa-report mt-1">
            <b-row>

              <b-col
                cols="12"
                lg="3"
                xl="4"
              >
                <div
                  class="qa-report-width"
                  @click="reDirectTab('resolve')"
                >
                  <div class="qa-assure-report-card resolved">
                    <h5>Resolved</h5>
                    <p>{{ items.resolved }}</p>
                  </div>
                </div>
              </b-col>
              <b-col
                cols="12"
                lg="3"
                xl="4"
              >
                <div
                  class="qa-report-width"
                  @click="reDirectTab('not_an_issue')"
                >
                  <div class="qa-assure-report-card not-a-issuecard">
                    <h5>Not An Issue</h5>
                    <p>{{ items.not_an_issue }}</p>
                  </div>
                </div>
              </b-col>
              <b-col
                cols="12"
                lg="3"
                xl="4"
              >
                <div
                  class="qa-report-width"
                  @click="reDirectTab('close')"
                >
                  <div class="qa-assure-report-card closeCard">
                    <h5>Close</h5>
                    <p>{{ items.close }}</p>
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-col>
      <b-col
        v-if="userInfo && userInfo.role && userInfo.role == 'SA'"
        cols="12"
        lg="5"
      >
        <TaskSummaryReport />
      </b-col>

      <b-col
        cols="12"
        :lg="userInfo.role =='SA' ?3 :4"
      >
        <div
          class="notificationmodalInfo2"
        >
          <div
            class="
          d-flex
          justify-content-between
          mb-1"
          >
            <h2
              style="font-weight: 700;
            font-size: 18px;
            line-height: 20px;
            color: #2178fb;"
            >Notifications</h2>
            <button
              v-if="allNotificationData && notificationCount > 0"
              @click="readNotification('all', notificationIds)"
            >
              Read All
            </button>
          </div>
          <div class="notificationDetails">
            <div
              v-for="(item, index) in allNotificationData"
              :key="index"
              class="singleNote w-100"
            >
              <div class="d-flex justify-content-between w-100">
                <h4>{{ item.data.title }}</h4>
                <div class="d-flex">
                  <p class="mr-2">
                    {{ item.created_at | DateFormatFormnowOrLocal }}
                  </p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    @click="readNotification('single', item.id)"
                  >
                    <path
                      fill="currentColor"
                      d="M4 16.615q-.421-.153-.71-.53Q3 15.71 3 15.232V7.573q0-.26.136-.478q.135-.218.395-.36L11 3l7.32 3.735q.222.092.348.32q.126.23.182.483h-1.137L11 4.135L4 7.61zM7.385 20q-.69 0-1.153-.462t-.463-1.153v-7.462q0-.69.463-1.153t1.153-.462h12q.69 0 1.152.462q.463.463.463 1.153v7.462q0 .69-.462 1.152q-.463.463-1.153.463zm6-5.265l-6.616-3.35v7q0 .269.173.442t.443.173h12q.269 0 .442-.173t.173-.442v-7zm0-1.077l6.384-3.235q-.096-.058-.182-.086q-.087-.03-.202-.03h-12q-.097 0-.193.03q-.096.028-.192.086zM20 10.308H6.77z"
                    />
                  </svg>
                </div>

              </div>
              <p>
                {{ item.data.message }}
              </p>

            </div>
            <div
              v-if="notificationCount == 0"
              class="d-flex align-items-center singleNote"
            >
              <div>
                <div class="d-flex justify-content-between" />
                <p>No Notifications Found</p>
              </div>
            </div>
          </div>
        </div></b-col></b-row></div>
  </b-col>
  </b-row>
  </div>
</template>

<script>
import { eventBus } from '../../../main'
import TaskSummaryReport from './TimeLogSummaryReport.vue'

export default {
  name: 'QaAssureReport',
  components: {
    TaskSummaryReport,
  },
  data() {
    return {
      items: [
        {
          count: 0,
          open: 0,
          reopen: 0,
          resolved: 0,
          not_an_issue: 0,
          closed: 0,
        },
      ],
      fields: [
        {
          key: 'count',
          label: 'TOTAL DEFECTS',
        },
        {
          key: 'open',
          label: 'OPEN',
        },
        {
          key: 'reopen',
          label: 'REOPEN',
        },
        {
          key: 'resolved',
          label: 'RESOLVED',
        },
        {
          key: 'not_an_issue',
          label: 'NOT AN ISSUE',
        },
        {
          key: 'close',
          label: 'CLOSE',
        },
      ],
      allNotificationData: [],
      notificationCount: null,
      notificationIds: [],

    }
  },

  mounted() {
    this.getQAssureReport()
    this.projectNotificationList()
  },
  methods: {
    async getQAssureReport() {
      const input = {
        project_id: this.$route.params.id3,
      }
      const response = await this.getHTTPPostResponse(
        'project/defect/qassure-report',
        input,
        false,
      )
      if (response && response.data) {
        const { data } = response
        this.items = data
      }
    },
    reDirectTab(status) {
      this.$router.push({
        name: 'projectTask',
        params: {
          id: 'qassure',
          id2: this.$route.params.id2,
          id3: this.$route.params.id3,
          id4: 'defects',
        },
        query: {
          status,
        },
      })
      eventBus.$emit('qassureTabOpen', true)
    },

    // Get all notification list
    async projectNotificationList() {
      const input = {
        project_id: [this.$route.params.id3],
      }
      const response = await this.getHTTPPostResponse(
        'admin/master/user/project-notification-list',
        input,
        false,
      )
      if (response && response.data) {
        this.notificationCount = response.data.count
        this.allNotificationData = response.data.notifications
        const ids = []
        response.data.notifications.forEach(element => {
          ids.push(element.id)
        })
        this.notificationIds = ids
      }
    },

    // Read single/All Notication
    async readNotification(type, id) {
      const input = {
        notification_id: type == 'all' ? this.notificationIds : [id],
      }
      const response = await this.getHTTPPostResponse(
        'admin/master/user/notification-read',
        input,
        false,
      )
      if (response && response.data) {
        this.projectNotificationList()
        eventBus.$emit('reloadNotifictionData')
        eventBus.$emit('isNotification', true)
      }
    },


  },
}
</script>

<style >
@import "../../../assets/scss/component-css/tesing.css";
@import "../../../assets/scss/component-css/taskSummaryReport.css";

.time-log-report .clock-in-out .viewEdit .timesheet-header .card-header-edit .card-header-second .calendar .date-select {
  margin-right: 0px;
}
</style>
