<template>
  <div>
    <b-modal
      ok-only
      ok-title="Accept"
      centered
      size="lg"
      id="comment"
      class="addtask-modal"
      :show-ok="false"
      @hidden="clearModalData()"
    >
      <template #modal-title>Add Comment</template>
      <validation-observer ref="commentRules">
        <b-row class="addtask-content">
          <!-- Select Title Block -->
          <b-col cols="12">
            <b-form-group
              label-for="title"
              class="m-1 to-do-input auto-resize-teaxtarea"
            >
              <validation-provider
                #default="{ errors }"
                name="comment"
                rules="validateNoImage|max:512"
              >
                <!-- <textarea
                  ref="textarea"
                  v-model="localValue"
                  name="comment"
                  @input="resizeTextarea"
                  placeholder="Write comment ..."
                  rows="5"
                ></textarea> -->
                <quill-editor
                  v-model="localValue"
                  id="issue_discription"
                  rows="4"
                  name="issue discription"
                  :options="commentEditorOption"
                >
                  <div id="toolbar1" slot="toolbar">
                    <!-- Add font size dropdown -->
                    <select class="ql-size">
                      <option value="small" />
                      <!-- Note a missing, thus falsy value, is used to reset to default -->
                      <option selected />
                      <option value="large" />
                      <option value="huge" />
                    </select>

                    <select class="ql-font">
                      <option selected="selected" />
                      <option value="serif" />
                      <option value="monospace" />
                    </select>
                    <!-- Add a bold button -->
                    <button class="ql-bold">Bold</button>
                    <button class="ql-italic">Italic</button>
                    <button class="ql-underline">Underline</button>

                    <button class="ql-clean" />
                    <select class="ql-align">
                      <option selected="selected"></option>
                      <option value="center"></option>
                      <option value="right"></option>
                      <option value="justify"></option>
                    </select>
                    <button id="ql-link" class="ql-link">
                      <feather-icon icon="Link2Icon" color="white" />
                    </button>

                    <button class="ql-list" value="ordered"></button>
                    <button class="ql-list" value="bullet"></button>
                    <!-- You can also add your own -->
                  </div>
                </quill-editor>
                <small class="text-danger text-capitalize">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </validation-observer>
      <template #modal-footer="{ cancel }">
        <div class="text-center w-100">
          <div>
            <b-button
              variant="primary"
              size="sm"
              class="btnClose"
              @click="commentValidationForm()"
            >
              Submit
            </b-button>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import { BModal, BFormTextarea } from "bootstrap-vue";
import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";

// extend("validateNoImage", {
//   validate: (value) => {
//     // Check if the content contains any image tag
//     const containsImage = /<img[^>]+src="[^"]+"[^>]*>/i.test(value);
//     return !containsImage;
//   },
//   message: "Image is not allowed in comment box",
// });

export default {
  name: "commentModal",
  components: {
    BModal,
    BFormTextarea,
    ValidationObserver,
    ValidationProvider,
    quillEditor,
  },
  props: {
    comment: String,
    status: String,
  },
  data() {
    return {
      localValue: this.comment,
      commentEditorOption: {
        modules: {
          toolbar: "#toolbar1",
        },
        placeholder: "Type comment",
      },
    };
  },
  model: {
    event: "change",
  },
  watch: {
    comment(newVal) {
      this.localValue = newVal;
    },
  },
  methods: {
   
    commentValidationForm() {
      this.$refs.commentRules.validate().then((success) => {
        if (success) {
          let  str =  this.localValue ? this.localValue.replace(/<p>\s*<\/p>|<p><br><\/p>|<br>/g, "") :"";
          str = str.length > 0 ? this.localValue : null;
          let obj = {
            status: this.status,
            comment: str,
          };
          this.$emit("updateData", obj);
          this.$root.$emit("bv::toggle::modal", "comment");
        }
      });
    },

    clearModalData() {
      this.localValue = null;
      this.$refs.commentRules.reset();
    },
  },
};
</script>
<!-- <style scoped>
.modal-content {
  border-radius: 20px;
  padding: 20px 20px 10px 15px !important;
}
.addtask-modal {
  background-color: aqua !important;
}
</style> -->
<style lang="scss" scoped>
@import "../../../../assets/scss/component-css/tesing.css";

</style>
