<template>
  <div>
    <div class="">
      <div class="card-filter-section">
        <div
          class="d-flex align-items-center flex-wrap justify-content-between"
        >
          <div
            class="d-flex align-items-center justify-content-start flex-wrap"
          >
            <p class="filter-title">
              Test Plan
            </p>
          </div>
          <div class="d-flex align-items-center">
            <OptionDropdown
              v-model="perPage"
              class="mr-2"
            />
            <b-input-group
              class="input-group-merge search-project"
              style="min-width: 250px; max-width: 250px; width: 250px"
            >
              <b-form-input
                v-model="debouncedSearch"
                placeholder="Search"
              />

              <b-input-group-append is-text>
                <feather-icon
                  v-if="debouncedSearch"
                  icon="XIcon"
                  @click="debouncedSearch = null,search=null"
                />
                <feather-icon icon="SearchIcon" />
              </b-input-group-append>
            </b-input-group>

            <div class="ml-1">
              <button
                class="create-filter filterRedesign mr-1"
                @click="openTestPlan(null, 'Create')"
              >
                Create Plan
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="table role test-plan-table">
        <TableLoader
          v-if="test_plan_loader"
          :fields="fields"
        />
        <b-row v-else>
          <b-col cols="12">
            <!-- data table start-->
            <b-table
              responsive
              :items="items"
              :fields="fields"
              show-empty
              class="scrollable-element tableDesigns test-table mastertblColor non-project-table"
            >
              <template #cell(title)="data">
                <p
                  v-b-tooltip.hover.top
                  class="tableData cursor-pointer data-hover"
                  :title="totalCount > 1 ? data.item.title : ''"
                  @click="openTestPlan(data.item, 'View')"
                >
                  {{ data.item.title }}
                </p>
              </template>
              <template #cell(created)="data">
                <p class="tableData">
                  {{ data.item.created_at | DateFormatFormnowOrLocal }}
                </p>
              </template>
              <template #cell(updated)="data">
                <p class="tableData">
                  {{ data.item.updated_at | DateFormatFormnowOrLocal }}
                </p>
              </template>
              <template #cell(time)="data">
                <div class="d-flex align-itmes-center">
                  <img src="../../../assets/images/icons/carbon_time.svg">
                  <p class="ml-50 tableData">
                    {{ timeSpend(data.item) | secondsToTime }}
                  </p>
                </div>
              </template>
              <template #cell(cases)="data">
                <p class="tableData">
                  {{ data.item.test_plan_cases.length }} test cases
                </p>
              </template>
              <template #cell(action)="data">
                <div class="boardDropdown mr-75 text-right">
                  <b-dropdown
                    toggle-class="text-decoration-none"
                    no-caret
                    boundary="viewport"
                    dropleft
                    text="Drop-Left"
                  >
                    <template v-slot:button-content>
                      <button class="edit-del-button">
                        <feather-icon
                          icon="MoreHorizontalIcon"
                          size="21"
                          class="align-left edit-data-delete"
                        />
                      </button>
                    </template>
                    <b-dropdown-item
                      class="edit-delete-item"
                      @click="openTestRun(data.item.id)"
                    >
                      <span class="edit-data">create test run </span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      class="edit-delete-item"
                      @click="openTestPlan(data.item, 'View')"
                    >
                      <span class="edit-data">View </span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      class="edit-delete-item"
                      @click="openTestPlan(data.item, 'Edit')"
                    >
                      <span class="edit-data">Edit</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </template>
            </b-table>
            <!-- data table complete -->
            <img
              v-if="totalCount == 0"
              src="@/assets/images/nodatafound/nodata.svg"
              alt="no-data"
              class="no-data-img"
            >
          </b-col>
          <!-- pagination section -->
          <b-col
            v-if="totalCount > 0"
            cols="12"
            class="d-flex justify-content-between flex-wrap align-items-center table-pagination"
          >
            <p
              v-if="totalCount > 10"
              class="mt-1"
            >
              Showing {{ 1 + (currentPage - 1) * perPage }} to
              <span v-if="currentPage * perPage < totalCount">{{
                currentPage * perPage
              }}</span>
              <span v-else>{{ totalCount }}</span> out of
              {{ totalCount }} entries
            </p>
            <p
              v-else
              class="mt-1"
            >
              Showing 1 to {{ totalCount }} entries out of
              {{ totalCount }} entries
            </p>
            <!-- <p></p> -->
            <b-pagination
              v-if="totalCount > 0"
              v-model="currentPage"
              :total-rows="totalCount"
              :per-page="perPage"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
          <!-- pagination section complete here -->
        </b-row>
      </div>
    </div>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BFormInput,
  BTable,
} from 'bootstrap-vue'
import TableLoader from '@/components/loaders/table-loading.vue'
import debounce from 'lodash/debounce'
import OptionDropdown from '../../../components/optiondropdown/OptionDropdown.vue'

export default {
  name: 'TestPlanTable',
  components: {
    BRow,
    BCol,
    BFormInput,
    BTable,
    TableLoader,
    OptionDropdown,
  },
  data() {
    return {
      totalCount: 1,
      test_plan_loader: false,
      currentPage: 1,
      perPage: 25,
      sortBy: 'name',
      sortDesc: true,
      is_active: 'active',
      search: null,
      fields: [
        { key: 'title', label: 'Title', thStyle: { minWidth: '250px' } },
        {
          key: 'created',
          label: 'Created At',
          thStyle: { minWidth: '150px', textAlign: 'start' },
        },
        {
          key: 'updated',
          label: 'Updated At',
          thStyle: { minWidth: '150px', textAlign: 'start' },
        },
        {
          key: 'time',
          label: 'Time Spent',
          thStyle: { minWidth: '200px', textAlign: 'start' },
        },
        {
          key: 'cases',
          label: 'Cases',
          thStyle: { minWidth: '150px', textAlign: 'start' },
        },
        {
          key: 'action',
          label: 'Action',
          thStyle: { minWidth: '150px', textAlign: 'end' },
        },
      ],
      items: [],
    }
  },
  watch: {
    search() {
      this.testPlanList()
      this.queryDataUpdate()
    },
    currentPage() {
      this.testPlanList()
      this.queryDataUpdate()
    },

    perPage() {
      if (this.currentPage == 1) {
        this.testPlanList()
      } else {
        this.currentPage = 1
      }
      this.queryDataUpdate()
    },
  },
  computed: {
    debouncedSearch: {
      get() {
        return this.search
      },
      set: debounce(function (value) {
        this.search = value
      }, 750),
    },
  },
  mounted() {
    this.setFilterValue()
    // if (this.perPage == 25) {
    //   this.testPlanList()
    // }
  },
  methods: {
    queryDataUpdate() {
      this.$router.push({
        query: {
          currentPage: this.currentPage,
          perPage: this.perPage,
          search: this.search,
          layout: this.currentLayout,
          pageName: this.$route.query.pageName,
        },
      }).catch(err => {
        // Ignore the NavigationDuplicated error
        if (err.name !== 'NavigationDuplicated') {
          console.log(err)
        }
      })
    },
    setFilterValue() {
      if (this.$route.query) {
        this.search = this.$route.query.search
        this.perPage = this.$route.query.perPage
          ? this.$route.query.perPage
          : 25
        this.currentPage = this.$route.query.currentPage
          ? this.$route.query.currentPage
          : 1
      }
    },
    timeSpend(data) {
      const testRunCases = data?.test_run_cases || []
      let total_time = 0
      testRunCases.forEach(element => {
        total_time += element.total_time ? element.total_time : 0
      })

      return total_time * 60
    },

    async testPlanList() {
      this.test_plan_loader = true
      const input = {
        sort_field: 'created_at',
        sort_order: 'desc',
        project_id: this.$route.params.id3,
        page: this.currentPage,
        per_page: this.perPage,
        search: this.search,
      }
      const response = await this.getHTTPPostResponse(
        'project/test-plan',
        input,
        false,
      )
      if (response && response.data) {
        const { data } = response
        this.$store.state.app.testPlan = data.test_plan
        this.items = data.test_plan
        this.totalCount = data.count
      }
      this.test_plan_loader = false
    },

    openTestRun(id) {
      this.$store.state.app.testRun.plan_id = id
      this.$store.state.app.testActiveName = 'Create'
      this.$store.state.app.pageShow = 'create'

      this.$router.push({
        name: 'projectTask',
        params: {
          id: this.$route.params.id,
          id2: this.$route.params.id2,
          id3: this.$route.params.id3,
          id4: 'test-plan-run',
          id6: 'create',
          id7: id,
        },
        query: {
          currentPage: this.currentPage,
          perPage: this.perPage,
          search: this.search,
          layout: this.currentLayout,
          pageName: this.$route.query.pageName,
        },
      }).catch(err => {
        // Ignore the NavigationDuplicated error
        if (err.name !== 'NavigationDuplicated') {
          console.log(err)
        }
      })
    },

    openTestPlan(item, type) {
      if (type != 'Create') {
        const testCaseId = []
        const testSuiteId = []
        item.test_plan_cases.forEach(element => {
          testCaseId.push(element.id)
          testSuiteId.push(element.suite_id)
        })

        const uniqueTestSuiteId = Array.from(new Set(testSuiteId))
        this.$store.commit('app/UPDATE_TEST_PLAN_FORM_DATA', {
          testPlanId: item.id,
          title: item.title,
          description: item.description,
          suiteId: uniqueTestSuiteId,
        })

        this.$store.commit('app/UPDATE_TEST_CASE_ID', testCaseId)
      }

      this.$store.state.app.testActiveName = type

      this.$store.commit('app/UPDATE_TESTPLAN_FILTER', {
        search: this.search,
        perPage: this.perPage,
      })

      this.reDirectTab(type.toLowerCase(), item?.id)
    },

    reDirectTab(type, id) {
      this.$router.push({
        name: 'projectTask',
        params: {
          id: this.$route.params.id,
          id2: this.$route.params.id2,
          id3: this.$route.params.id3,
          id4: this.$route.params.id4,
          id5: type,
          id6: type != 'create' ? id : null,
        },
        query: {
          currentPage: this.currentPage,
          perPage: this.perPage,
          search: this.search,
          layout: this.currentLayout,
          pageName: this.$route.query.pageName,
        },
      }).catch(err => {
        // Ignore the NavigationDuplicated error
        if (err.name !== 'NavigationDuplicated') {
          console.log(err)
        }
      })
      this.$store.state.app.pageShow = 'create'
    },
  },
}
</script>
<style lang="scss" scoped>
@import "../../../assets/scss/component-css/tesing.css";
</style>
