<template>
  <div>
    <div class="addtask task-detail-modal">
      <b-modal
        :id="id"
        v-model="taskDtl"
        cancel-variant="outline-secondary"
        centered
        size="xl"
        content-class="task-deatil-popup"
        @close="closeTaskDtl()"
      >
        <template #modal-title>
          <h3 class="addtask-from-title">
            Task Detail
            <feather-icon
              v-if="!editTask && addEditRights"
              v-b-tooltip.hover
              icon="EditIcon"
              size="20"
              class="edit-icons-summary ml-2"
              title="Edit deatils"
              @click="editTaskDtl()"
            />
          </h3>
        </template>
        <b-overlay
          :show="loader"
          rounded="sm"
        >
          <validation-observer ref="sprintTaskUpdateRules">
            <b-col
              v-if="editTask"
              sm="12"
            >
              <div class="edit-icon d-flex justify-content-end">
                <!-- cancel button -->
                <span>
                  <feather-icon
                    v-b-tooltip.hover
                    icon="CheckIcon"
                    size="18"
                    class="done-edit"
                    title="Update"
                    @click="validationForm"
                  />
                </span>
                <span>
                  <feather-icon
                    v-b-tooltip.hover
                    icon="XIcon"
                    size="18"
                    class="cancel-edit"
                    title="Cancel"
                    @click="cancelEdit()"
                  />
                </span>
                <!-- success button -->
              </div>
            </b-col>
            <hr class="mb-0">
            <b-row class="tasksection-padding">
              <b-col
                md="12"
                xl="8"
                class="mb-xl-0 mb-sm-1"
              >
                <div
                  v-if="!editTask"
                  class="list-task-name"
                >
                  <p>{{ title }}</p>
                </div>
                <validation-provider
                  v-if="editTask"
                  #default="{ errors }"
                  name="Title"
                  rules="required|min:4|max:200"
                >
                  <input
                    id=""
                    v-model="title"
                    type="text"
                    name="title"
                    class="form-input"
                  >
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>

                <!-- <div class="edit-icon d-flex justify-content-end">
                  <span v-if="edit === true"
                    ><feather-icon
                      icon="CheckIcon"
                      size="18"
                      class="done-edit"
                    ></feather-icon
                  ></span>
                  <span v-if="edit === true"
                    ><feather-icon
                      icon="XIcon"
                      size="18"
                      class="cancel-edit"
                    ></feather-icon
                  ></span>
                </div> -->

                <div
                  v-if="!editTask"
                  class="task-description"
                >
                  <h4>Description</h4>
                  <p>{{ description }}</p>
                </div>
                <textarea
                  v-if="editTask"
                  id=""
                  v-model="description"
                  rows="5"
                  type="text"
                  name="projectcode"
                  class="form-input-des"
                />
                <!-- <div class="edit-icon d-flex justify-content-end">
                  <span v-if="edit === true"
                    ><feather-icon
                      icon="CheckIcon"
                      size="18"
                      class="done-edit"
                    ></feather-icon
                  ></span>
                  <span v-if="edit === true"
                    ><feather-icon
                      icon="XIcon"
                      size="18"
                      class="cancel-edit"
                    ></feather-icon
                  ></span>
                </div> -->
                <div class="attachment">
                  <!-- <input type="file" /> -->
                  <p>
                    <feather-icon icon="PaperclipIcon" />
                    <span>Attachment</span>
                  </p>
                </div>
                <b-row
                  v-if="editTask"
                  class="pl-1"
                >
                  <b-col
                    cols="12"
                    class="mt-1 pl-0"
                  >
                    <b-form-group label-for="attachments">
                      <b-form-file
                        id="attachments"
                        v-model="attachments"
                        placeholder="Drag and drop to upload, or."
                        class="attachments"
                        multiple
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row
                  v-if="task_attachments && task_attachments.length"
                  class="pl-1"
                >
                  <div
                    v-for="(attachment, index) in task_attachments"
                    v-if="attachment.type != 'file'"
                    :key="index"
                    class="task-view-image"
                    cols="3"
                  >
                    <img
                      :src="attachment.url | attachmentURL"
                      alt=""
                    >
                    <!-- <feather-icon
                      icon="XIcon"
                      class="trashicon"
                      size="21"
                      v-if="editTask"
                      @click="removeAttachments(index, attachment.id)"
                    /> -->
                    <b-icon
                      v-if="editTask"
                      class="X-circle-fill"
                      icon="X-circle-fill"
                      @click="removeAttachments(index, attachment.id)"
                    />
                    <p>
                      <a
                        :href="attachment.url | attachmentURL"
                        target="_blank"
                      >{{ attachment.name }}</a>
                    </p>
                  </div>
                  <div
                    v-for="(attachment, index) in task_attachments"
                    v-if="attachment.type == 'file'"
                    :key="index"
                    class="task-view-image"
                    cols="3"
                  >
                    <img
                      src="../../../assets/images/Image/downloadfile.png"
                      alt=""
                    >
                    <a
                      :href="attachment.url | attachmentURL"
                      target="_blank"
                    >{{
                      attachment.name
                    }}</a>
                    <!-- <feather-icon
                      icon="XIcon"
                      class="trashicon"
                      size="21"
                      v-if="editTask"

                    /> -->
                    <b-icon
                      v-if="editTask"
                      class="X-circle-fill"
                      icon="X-circle-fill"
                      @click="removeAttachments(index, attachment.id)"
                    />
                  </div>
                  <!-- <div class="task-view-image" cols="3">
                    <img src="@/assets/images/flags/de.png" alt="" />
                    <p>data research.jpg</p>
                  </div> -->
                </b-row>
              </b-col>
              <b-col
                md="12"
                xl="4"
                class="task-data"
              >
                <div class="task-status">
                  <p>Task Status</p>
                  <b-dropdown
                    id="dropdown-1"
                    v-ripple.400="'rgba(33, 120, 251, 0.2)'"
                    :text="displayStatus"
                    class="select-status"
                    :disabled="!addEditRights"
                  >
                    <b-dropdown-item
                      v-for="(status, index) in allTaskStatusList"
                      :key="index"
                      @click="updateStatus(status.status)"
                    >{{ status.title }}</b-dropdown-item>
                    <!-- <b-dropdown-item>Complete</b-dropdown-item> -->
                  </b-dropdown>
                </div>
                <div class="under-line" />
                <b-row class="overflow-section">
                  <b-col
                    v-if="project"
                    cols="12"
                  >
                    <p class="label-tag">
                      Project
                    </p>
                    <div class="project">
                      <b-avatar
                        button
                        :text="project.name.charAt(0)"
                        class="align-baseline project-avtar"
                        size="25"
                      />
                      <p class="project-name">
                        {{ project.name }}
                      </p>
                    </div>
                  </b-col>
                  <b-col
                    v-if="milestone"
                    cols="12"
                    lg="6"
                    class="task-detail-row"
                  >
                    <p class="label-tag">
                      Milestone
                    </p>
                    <span class="milestone-task">{{ milestone.title }}</span>
                  </b-col>
                  <b-col
                    v-if="sprint"
                    cols="12"
                    lg="6"
                    class="task-detail-row"
                  >
                    <p class="label-tag">
                      sprint
                    </p>
                    <span class="milestone-task">{{ sprint.title }}</span>
                  </b-col>
                  <!-- <b-col cols="12" lg="6" class="task-detail-row" v-if="!editTask">
                    <p class="label-tag">Type</p>
                    <span class="task-type">{{type}}</span>
                  </b-col> -->
                  <!-- Type Input  -->
                  <b-col
                    cols="12"
                    lg="6"
                    class="task-detail-row"
                  >
                    <b-form-group
                      label="Type"
                      label-for="type"
                    >
                      <validation-provider
                        v-if="editTask"
                        #default="{ errors }"
                        name="Type"
                        rules="required"
                      >
                        <v-select
                          id="type"
                          v-model="type"
                          label="name"
                          :options="allType"
                          class="
                            select-size-lg select-task-option
                            taskdetail-tag
                          "
                          name="type"
                          :reduce="(type) => type.value"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                      <p
                        v-if="!editTask"
                        id="type"
                        class="task-type"
                      >
                        {{ type }}
                      </p>
                    </b-form-group>
                  </b-col>
                  <!-- priority Input  -->
                  <b-col
                    cols="12"
                    lg="6"
                    class="task-detail-row"
                  >
                    <b-form-group
                      label="priority"
                      label-for="priority"
                    >
                      <validation-provider
                        v-if="editTask"
                        #default="{ errors }"
                        name="Priority"
                        rules="required"
                      >
                        <v-select
                          id="priority"
                          v-model="priority"
                          label="name"
                          :options="allPriorities"
                          class="
                            select-size-lg select-task-option
                            taskdetail-tag
                          "
                          name="priority"
                          :reduce="(p) => p.value"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                      <p
                        v-if="!editTask"
                        class="task-type"
                      >
                        {{ priority }}
                      </p>
                    </b-form-group>
                  </b-col>
                  <!-- ESTIMATED HOURS Input  -->
                  <b-col
                    cols="12"
                    lg="6"
                    class="task-detail-row"
                  >
                    <p class="label-tag">
                      estimated hours
                    </p>
                    <validation-provider
                      v-if="editTask"
                      #default="{ errors }"
                      name="Estimated hours"
                      rules="required|numeric|min_value:0|max_value:50"
                    >
                      <input
                        id="estimatedhours"
                        v-model="estimated_hours"
                        type="number"
                        placeholder="Estimated hours"
                        name="estimated_hours"
                        class="hours-input"
                      >
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                    <p
                      v-if="!editTask"
                      class="task-type"
                    >
                      {{ estimated_hours }}
                    </p>
                  </b-col>
                  <!-- Actual hours Input  -->
                  <b-col
                    cols="12"
                    lg="6"
                    class="task-detail-row"
                  >
                    <p class="label-tag">
                      Actual hours
                    </p>
                    <validation-provider
                      v-if="editTask"
                      #default="{ errors }"
                      name="Actual hours"
                      rules="required|numeric|min_value:0|max_value:50"
                    >
                      <input
                        id="actual_hours"
                        v-model="actual_hours"
                        type="number"
                        placeholder="Actual hours"
                        name="actual_hours"
                        class="hours-input"
                      >
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                    <p
                      v-if="!editTask"
                      class="task-type"
                    >
                      {{ actual_hours }}
                    </p>
                  </b-col>
                  <!-- Actual hours Input  -->
                  <b-col
                    cols="12"
                    class="task-detail-row"
                  >
                    <p class="label-tag">
                      Due date
                    </p>
                    <validation-provider
                      v-if="editTask"
                      #default="{ errors }"
                      name="Due date"
                      rules="required"
                    >
                      <b-input-group class="input-group-merge">
                        <flat-pickr
                          id="astartdate"
                          v-model="due_date"
                          class="form-control"
                          :config="configDueDate"
                          placeholder="Due date"
                          name="due_date"
                        />
                        <b-input-group-append is-text>
                          <feather-icon icon="CalendarIcon" />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                    <p
                      v-if="!editTask"
                      class="task-type"
                    >
                      {{ due_date }}
                    </p>
                  </b-col>
                  <!-- assignee user input -->
                  <b-col
                    cols="12"
                    class="task-detail-row"
                  >
                    <p class="label-tag">
                      Assignee
                    </p>
                    <validation-provider
                      v-if="editTask"
                      #default="{ errors }"
                      name="Assignee user"
                      rules="required"
                    >
                      <v-select
                        v-model="assignee_id"
                        label="name"
                        name="assignee_id"
                        :options="assigneeUsers"
                        :reduce="(u) => u.id"
                        class="select-size-lg select-task-option taskdetail-tag"
                      >
                        <template v-slot:option="option">
                          <b-avatar
                            size="30"
                            :text="option.name.charAt(0)"

                            class="mr-1"
                          />
                          <img
                            :src="option.img"
                            alt=""
                          >
                          {{ option.name }}
                        </template>
                      </v-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                    <p
                      v-if="!editTask"
                      class="task-type d-flex align-items-center"
                    >
                      <b-avatar
                        v-if="assignee"
                        button
                        :text="assignee ? assignee.name.charAt(0) : '-'"
                        :src="assignee.picture"
                        class="align-baseline user-name-avtar"
                        size="25"
                      />
                      {{ assignee ? assignee.full_name : "-" }}
                    </p>
                  </b-col>
                  <!-- assignee user input -->
                  <b-col
                    cols="12"
                    class="task-detail-row"
                  >
                    <p class="label-tag">
                      Reporter
                    </p>
                    <validation-provider
                      v-if="editTask"
                      #default="{ errors }"
                      name="Reporter user"
                    >
                      <v-select
                        v-model="reporter_id"
                        label="name"
                        name="reporter_id"
                        :options="reporterUsers"
                        :reduce="(u) => u.id"
                        class="select-size-lg select-task-option taskdetail-tag"
                      >
                        <template v-slot:option="option">
                          <b-avatar
                            size="30"
                            :text="option.name.charAt(0)"
                            class="mr-1"
                          />
                          <img
                            :src="option.img"
                            alt=""
                          >
                          {{ option.name }}
                        </template>
                      </v-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                    <p
                      v-if="!editTask"
                      class="task-type d-flex align-items-center"
                    >
                      <b-avatar
                        v-if="reporter"
                        button
                        :text="reporter ? reporter.name.charAt(0) : '-'"
                        :src="reporter ? reporter.picture : ''"
                        class="align-baseline user-name-avtar"
                        size="25"
                      />
                      {{ reporter ? reporter.full_name : "-" }}
                    </p>
                  </b-col>
                  <b-col
                    cols="12"
                    class="task-detail-row"
                  >
                    <b-form-group
                      label="Tag"
                      label-for="tag"
                    >
                      <validation-provider
                        v-if="editTask"
                        #default="{ errors }"
                        name="Tags"
                        rules="required"
                      >
                        <v-select
                          id="tags"
                          v-model="tags"
                          hide-details
                          multiple
                          name="tags"
                          label="name"
                          :options="$store.state.app.tagList"
                          :reduce="(tag) => tag.name"
                          class="select-size-lg task-tag taskdetail-tag"
                          placeholder="Select Any Tag"
                          :close-on-select="false"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                      <div v-if="!editTask && tags && tags.length">
                        <button
                          v-for="(tag, index) in tags"
                          :key="index"
                          class="task-tag-button"
                        >
                          <span class="task-tag-title">{{ tag }}</span>
                          <!-- <feather-icon icon="XIcon" size="18"></feather-icon> -->
                        </button>
                      </div>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </validation-observer>
        </b-overlay>
        <template #modal-footer>
          <b-button class="d-none" />
          <b-button class="d-none" />
        </template>
      </b-modal>
    </div>
  </div>
</template>

<script>
import {
  BButton,
  BModal,
  VBModal,
  BForm,
  BFormInput,
  BFormGroup,
  BRow,
  BCol,
  BFormTextarea,
  BFormFile,
  BAvatar,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'
import { eventBus } from '@/main'
import FeatherIcon from '../../../@core/components/feather-icon/FeatherIcon.vue'

export default {
  name: 'TaskDetail',
  components: {
    BButton,
    BModal,
    VBModal,
    BForm,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    vSelect,
    BFormTextarea,
    BFormFile,
    FeatherIcon,
    BAvatar,
    ValidationProvider,
    ValidationObserver,
    flatPickr,
  },
  directives: {
    'b-modal': VBModal,
  },
  props: {
    projectDtl: {
      // type: Object,
      required: true,
      default: null,
    },
    id: {
      type: String,
      required: true,
      default: 'taskdetail',
    },
    taskID: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      editTask: false,
      loader: true,
      taskDtl: true,
      isshow: true,
      edit: false,
      options: [
        {
          title: 'hello',
          img: require('@/assets/images/avatars/1-small.png'),
          // img: require("@/assets/images/portrait/small/avatar-s-20.jpg"),
        },
      ],
      priority: null,
      estimated_hours: null,
      actual_hours: null,
      assignee_id: null,
      assignee: null,
      reporter_id: null,
      reporter: null,
      tags: 'ui design',
      title: null,
      description: null,
      attachment: [],
      project: null,
      milestone: null,
      sprint: null,
      type: null,
      all_tags: [],
      tags: [],
      allType: [
        { value: 'task', name: 'Task' },
        { value: 'bug', name: 'Bug' },
        { value: 'story', name: 'Story' },
      ],
      attachments: [],
      task_status: null,
      task_attachments: [],
      remove_attachments: [],
      edit_task_tags: [],
      due_date: null,
      configDueDate: {
        minDate: new Date(),
        maxDate: null,
        dateFormat: 'Y-m-d',
      },
    }
  },
  computed: {
    addEditRights() {
      if (this.userInfo && this.userInfo.role == 'SA') {
        return true
      } if (
        this.projectDtl
        && this.projectDtl.project_user
        && this.projectDtl.project_user.length
      ) {
        const user = this.projectDtl.project_user.find(
          pu => pu.id === this.userInfo.id,
        )
        if (
          user
          && (user.access_rights == 'project_owner'
            || user.access_rights == 'co_ordinator')
        ) {
          return true
        }
        // access_rights = project_owner, co_ordinator, member
      }
      return false
    },
    allTaskStatusList() {
      const status = []
      this.allTasksStatus.forEach(element => {
        if (this.task_status && this.task_status != element.status) {
          status.push(element)
        }
      })
      return status
    },
    displayStatus() {
      const status = this.allTasksStatus.find(
        s => s.status === this.task_status,
      )
      if (status) {
        return status.title
      }
      return this.task_status
    },
    assigneeUsers() {
      if (
        this.projectDtl
        && this.projectDtl.project_user
        && this.projectDtl.project_user.length
      ) {
        const users = []
        this.projectDtl.project_user.forEach(element => {
          if (!this.reporter_id || this.reporter_id != element.user_id) {
            element.users.name = element.users.full_name

            users.push(element.users)
          }
        })
        return users
      }
      return []
    },
    reporterUsers() {
      if (
        this.projectDtl
        && this.projectDtl.project_user
        && this.projectDtl.project_user.length
      ) {
        const users = []
        this.projectDtl.project_user.forEach(element => {
          if (!this.assignee_id || this.assignee_id != element.user_id) {
            element.users.name = element.users.full_name
            users.push(element.users)
          }
        })
        return users
      }
      return []
    },
  },
  watch: {
    taskDtl(val) {
      if (!val) {
        this.closeTaskDtl()
      }
    },
  },
  mounted() {
    if (this.taskID) {
      this.getTaskDetails()
    }
    // this.getTagsData();
  },
  methods: {
    editTaskDtl() {
      if (!this.addEditRights) {
        return false
      }
      this.editTask = true
    },
    closeTaskDtl() {
      eventBus.$emit('reloadCompleteTaskList', true)
    },
    removeAttachments(index, id) {
      this.remove_attachments.push(id)
      this.task_attachments.splice(index, 1)
      this.attachments.splice(index, 1)
    },
    editMethod() {
      this.isshow = false
      this.edit = true
    },
    /**
     * Get tags data
     */
    async getTagsData() {
      const input = {
        is_active: 1,
      }
      const response = await this.getHTTPPostResponse(
        'admin/master/tag',
        input,
        false,
      )
      if (response && response.data) {
        const { data } = response
        this.all_tags = data.count ? data.tags : []
      }
    },
    async getTaskDetails() {
      this.loader = true
      const input = {
        project_id: this.projectDtl.id,
      }
      const response = await this.getHTTPGetResponse(
        `project/task/view/${this.taskID}`,
        input,
        false,
      )
      if (response && response.status == 200) {
        if (response.data && response.data.project_task) {
          this.loader = false
          const { project_task } = response.data
          this.title = project_task.title
          this.description = project_task.description
          this.project = project_task.project
          this.milestone = project_task.project_plan_milestone
          this.sprint = project_task.project_plan_sprint
          this.type = project_task.type
          this.priority = project_task.priority
          this.estimated_hours = project_task.estimated_hours
          this.actual_hours = project_task.actual_hours
          this.assignee_id = project_task.assignee
          this.assignee = project_task.assignee_user
          this.reporter_id = project_task.reporter_id
          this.reporter = project_task.reporter_user
          this.task_status = project_task.task_status
          this.task_attachments = project_task.project_task_attachments
          if (project_task.tags && project_task.tags.length) {
            const tags = []
            project_task.tags.forEach(tag => {
              tags.push(tag.tag)
            })
            this.tags = tags
            this.edit_task_tags = project_task.tags
          }
          this.due_date = project_task.due_date
          this.configDueDate = {
            minDate: project_task.due_date ? project_task.due_date : new Date(),
            maxDate: null,
            dateFormat: 'Y-m-d',
          }
          this.remove_attachments = []
        }
        // console.log("project-task/view response", response.data);
      }
    },
    cancelEdit() {
      this.editTask = false
      this.getTaskDetails()
    },
    async updateStatus(status) {
      this.loader = true
      const input = {
        task_status: status,
      }
      const response = await this.getHTTPPostResponse(
        `project/task/status/${this.taskID}`,
        input,
        true,
      )
      if (response && response.status == 200) {
        this.getTaskDetails()
      }
      this.loader = false
    },
    validationForm() {
      this.$refs.sprintTaskUpdateRules.validate().then(success => {
        if (success) {
          this.updateTask()
        }
      })
    },
    /**
     * save the client form details
     */
    async updateTask() {
      this.loader = true
      const formData = new FormData()
      formData.append('_method', 'PUT')
      formData.append('project_id', this.projectDtl.id)
      formData.append('milestone_id', this.milestone.id)
      formData.append('sprint_id', this.sprint.id)
      formData.append('task_status', this.task_status)
      formData.append('type', this.type)
      formData.append('assignee', this.assignee_id)
      formData.append('title', this.title)
      formData.append('description', this.description)
      formData.append('priority', this.priority)
      formData.append('due_date', this.due_date)
      formData.append('reporter_id', this.reporter_id)
      if (this.tags && this.tags.length) {
        this.tags.forEach((element, index) => {
          formData.append('tags[]', element)
        })
      }
      formData.append('estimated_hours', this.estimated_hours)
      formData.append('actual_hours', this.actual_hours)
      if (this.attachments && this.attachments.length) {
        this.attachments.forEach((element, index) => {
          formData.append('files[][file]', element)
        })
      }
      if (this.remove_attachments && this.remove_attachments.length) {
        this.remove_attachments.forEach((element, index) => {
          formData.append('remove_file_ids[]', element)
        })
      }
      if (this.edit_task_tags && this.edit_task_tags.length) {
        this.edit_task_tags.forEach((element, index) => {
          formData.append('remove_tag_ids[]', element.id)
        })
      }

      const response = await this.getHTTPPostResponse(
        `project/task/update/${this.taskID}`,
        formData,
        true,
      )
      if (response && response.status == 200) {
        this.editTask = false
        this.getTaskDetails()
      }
      this.loader = false
    },
    // async updateTitle(){

    // }
    // 'milestone_id'               => 'nullable|exists:project_plans,id',
    // 'sprint_id'                  => 'nullable|exists:project_plans,id',
    // 'task_status'                => 'required|in:backlog,to_do,in_progress,in_testing,done',
    // 'type'                       => 'required|in:' . implode(',', config('constants.PROJECT_TASK_TYPE')),
    // 'assignee'                   => 'required|exists:users,id',
    // 'title'                      => 'required',
    // 'description'                => 'nullable',
    // 'priority'                   => 'required|in:' . implode(',', config('constants.PROJECT_TASK_PRIORITY')),
    // 'due_date'                   => 'nullable|date_format:Y-m-d',
    // 'reporter_id'                => 'required |exists:users,id',
    // 'parent_id'                  => 'nullable|exists:project_tasks,id',
    // 'estimated_hours'            => 'nullable',
    // 'actual_hours'               => 'nullable',
    // //project task attachment
    // 'files'                      => 'nullable|array|min:1',
    // 'files.*.file'               => 'nullable',
    // 'remove_option_ids'          => 'nullable|array',
    // 'remove_option_ids.*'        => 'nullable|exists:project_task_attachments,id',
  },
}
</script>

<style>
</style>
