<template>
  <div>
    <loader
      v-if="loader"
      :row-num="1"
    />
    <div
      v-if="projectDtl && !loader"
      class="new-summary-detail select-value"
    >
      <validation-observer ref="projectDetailsObserver">
        <b-row>
          <b-col cols="12">
            <div class="d-flex justify-content-between mb-1">
              <div><p class="projectOverview">
                Project Overview
              </p></div>
              <div>
                <feather-icon
                  v-if="!isEdit && editRights"
                  icon="Edit3Icon"
                  size="30"
                  class="edit-icons-summary"
                  @click="isEdit = true"
                />
                <feather-icon
                  v-if="isEdit"
                  icon="CheckIcon"
                  size="30"
                  class="edit-icons-summary mr-50"
                  @click="updateDetails()"
                />
                <feather-icon
                  v-if="isEdit"
                  icon="XIcon"
                  size="30"
                  class="edit-icons-summary"
                  @click="getDetails()"
                />
              </div>
            </div>
          </b-col>

          <b-col
            cols="12"
            lg="4"
          >
            <p class="new-smmarydetail-title">
              Project Description
              <span
                v-if="isEdit"
                class="text-danger"
              >*</span>
            </p>
            <p
              v-if="!isEdit"
              class="new-smmarydetail-desc"
            >
              {{ projectDtl.description }}
            </p>
            <validation-provider
              v-else
              #default="{ errors }"
              name="Project description"
              rules="required"
            >
              <b-form-textarea
                id="textarea-rows"
                v-model="description"
                placeholder="Description"
                rows="8"
                class="my-1 mb-0 textboxProjectedit"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            <div class="mt-2 mb-1">
              <p class="new-smmarydetail-title mb-50">
                Project Scope <span
                  v-if="isEdit"
                  class="text-danger"
                >*</span>
              </p>
              <div v-if="!isEdit">
                <b-badge
                  v-for="(scope, sindex) in projectDtl.project_scopes"
                  :key="sindex"
                  class="newproject-card-tag"
                >
                  <span class="tag_name">{{ scope.name }}</span>
                </b-badge>
              </div>
              <validation-provider
                v-else
                #default="{ errors }"
                name="Project scope"
                rules="required"
              >
                <v-select
                  id="project_scope_id"
                  v-model="project_scope_id"
                  hide-details
                  name="project_scope_id"
                  label="name"
                  :options="$store.state.app.projectScope"
                  :reduce="(scope) => scope.id"
                  class="select-size-lg project-scope-detail"
                  placeholder="Select project scope"
                  multiple
                  :close-on-select="false"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
            <div>
              <p class="newsummary-editableinput-title">
                Project Status <span
                  v-if="isEdit"
                  class="text-danger"
                >*</span>
              </p>
              <validation-provider
                v-if="isEdit"
                #default="{ errors }"
                name="Project status"
                rules="required"
              >
                <v-select
                  id="status"
                  v-model="status"
                  hide-details
                  name="status"
                  label="label"
                  :options="projectStatusList"
                  :reduce="(status) => status.value"
                  placeholder="Select status"
                  :clearable="true"
                  class="mb-50"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>

              <div
                v-if="!isEdit"
                class="newproject-timeline-section"
              >
                <b-badge
                  :class="`newproject-card-status ${projectStatusClass}`"
                >
                  {{ projectStatusName }}
                </b-badge>
                <!-- <p class="project-input" v-else>{{ projectStatusName }}</p> -->
              </div>
            </div>
          </b-col>

          <b-col
            cols="12"
            lg="4"
            class="mt-lg-0 mt-1"
          >
            <b-row
              v-if="isEdit"
              class="mb-lg-3 mb-2"
            >
              <b-col cols="4">
                <p class="newsummary-editableinput-title">
                  Project Name <span
                    v-if="isEdit"
                    class="text-danger"
                  >*</span>
                </p>
                <validation-provider
                  #default="{ errors }"
                  name="Project name"
                  rules="required"
                >
                  <b-form-input
                    id="project name"
                    v-model="projectName"
                    placeholder="Name"
                    name="project name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
              <b-col
                cols="4"
                class="input-group color-picker-input"
              >
                <p class="newsummary-editableinput-title">
                  Project Color <span
                    v-if="isEdit"
                    class="text-danger"
                  >*</span>
                </p>
                <validation-provider
                  #default="{ errors }"
                  name="Project color"
                  rules="required"
                >
                  <b-input-group class="color-picker-input">
                    <b-form-input
                      id="projectcolor"
                      v-model="projectColor"
                      type="text"
                      name="projectcolor"
                      placeholder="Select any color"
                    />
                    <b-input-group-append
                      is-text
                      class="projectColorpicker"
                    >
                      <input
                        v-model="projectColor"
                        type="color"
                        name="projectcolor"
                        class="color-picker"
                      >
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                v-if="projectDtl.client"
                cols="4"
              >
                <p
                  id="basicInput"
                  class="newsummary-editableinput-title"
                >
                  Client name <span
                    v-if="isEdit"
                    class="text-danger"
                  >*</span>
                </p>
                <validation-provider
                  v-if="isEdit"
                  #default="{ errors }"
                  name="Client name"
                  rules="required"
                >
                  <v-select
                    id="client_id"
                    v-model="clientID"
                    hide-details
                    name="client_id"
                    label="name"
                    :options="$store.state.app.clientList"
                    :reduce="(client) => client.id"
                    placeholder="Select Client"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                <p
                  v-else
                  class="project-input"
                >
                  {{ projectDtl.client.name }}
                </p>
              </b-col>
              <b-col cols="4">
                <p class="newsummary-editableinput-title">
                  Project Type <span
                    v-if="isEdit"
                    class="text-danger"
                  >*</span>
                </p>
                <validation-provider
                  v-if="isEdit"
                  #default="{ errors }"
                  name="Project type"
                  rules="required"
                >
                  <v-select
                    id="projecttype"
                    v-model="type"
                    hide-details
                    name="projecttype"
                    label="label"
                    :options="projectTypeList"
                    :reduce="(type) => type.value"
                    placeholder="Select type"
                    :clearable="true"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                <p
                  v-else
                  class="project-input"
                >
                  {{ projectTypeName }}
                </p>
              </b-col>
            </b-row>

            <b-row class="newproject-date-section">
              <b-col
                cols="4"
                class="pl-0"
              >
                <div class="total-spent time-log-section projectSpent">
                  <p class="userhour-log text-left">
                    Time Spent
                  </p>
                  <p class="user-totalhour text-left">
                    <!-- {{ projectDtl.time_spent_minutes | convertMinutes }} -->
                    {{ projectTotalTimeSpend }}
                  </p>
                </div>
              </b-col>
              <b-col
                cols="4"
                class="pr-0"
              >
                <!-- v-if="projectDtl.type === 'fix_timeline'" -->
                <div
                  v-if="isEdit"
                  class="total-spent projectSpent"
                >
                  <p class="newsummary-editableinput-title text-left">
                    Time Estimated
                    <span
                      v-if="isEdit"
                      class="text-danger"
                    >*</span>
                  </p>
                  <validation-provider
                    v-if="isEdit"
                    #default="{ errors }"
                    name="Time estimated "
                    :rules="{ required: true, numeric: true }"
                  >
                    <b-form-input
                      id="basicInput"
                      v-model="time_estimated"
                      type="number"
                      value="ZT"
                      class="newsummary-editableinput-input"
                      placeholder="Estimated time.."
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>

                <div
                  v-if="!isEdit"
                  class="total-spent projectSpent"
                  :class="
                    projectDtl.time_spent_minutes / 60 >
                      projectDtl.time_estimated
                      ? 'maxTime-log'
                      : 'minTime-log'
                  "
                >
                  <p class="userhour-log text-left">
                    Max Time Planned
                  </p>

                  <p class="user-totalhour text-left">
                    {{
                      projectDtl.time_estimated | hoursInTimeFormatForSummary
                    }}
                  </p>
                </div>
              </b-col>
            </b-row>

            <b-row class="newproject-date-section">
              <b-col
                cols="4"
                class="total-spent time-log-section projectSpent"
              >
                <div class="total-spent projectSpent">
                  <p class="userhour-log text-left">
                    Time Billable
                  </p>
                  <p class="user-totalhour text-left">
                    {{ projectTimeBillable }}
                  </p>
                </div>
              </b-col>

              <b-col
                cols="4"
                class="total-spent projectSpent minTime-log"
              >
                <p class="newsummary-editableinput-title">
                  Project Code <span
                    v-if="isEdit"
                    class="text-danger"
                  >*</span>
                </p>
                <validation-provider
                  v-if="isEdit"
                  #default="{ errors }"
                  name="Project code"
                  rules="required"
                >
                  <b-form-input
                    id="basicInput"
                    v-model="code"
                    value="ZT"
                    class="newsummary-editableinput-input"
                    placeholder="Enter code"
                    readonly
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                <p
                  v-else
                  class="project-input"
                >
                  {{ projectDtl.code }}
                </p>
              </b-col>
            </b-row>
          </b-col>

          <b-col
            cols="12"
            lg="2"
          >
            <div id="chart">
              <apexchart
                type="pie"
                width="280"
                :options="chartOptions"
                :series="series"
              />
            </div>
          </b-col>
        </b-row>
      </validation-observer>
    </div>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { eventBus } from '@/main'
import VueApexCharts from 'vue-apexcharts'
import Loader from '@/components/loaders/timesheet-loader.vue'
import FeatherIcon from '../../../@core/components/feather-icon/FeatherIcon.vue'

export default {
  name: 'ProjectDetail',
  components: {
    FeatherIcon,
    ValidationObserver,
    ValidationProvider,
    apexchart: VueApexCharts,
    Loader,
  },
  props: {
    projectDtl: {
      type: Object,
      required: true,
      default: null,
    },
    spendHoursDetail: {
      type: Object,
      required: true,
      default: null,
    },
  },
  data() {
    return {
      isEdit: false,
      description: null,
      code: null,
      clientID: null,
      status: null,
      type: null,
      project_scope_id: [],
      projectScopes: [],
      time_estimated: null,
      projectColor: null,
      projectName: null,
      series: [],
      loader: false,
      chartOptions: {
        chart: {
          width: 210,
          type: 'pie',
        },
        dataLabels: {
          enabled: true,
          style: {
            fontSize: 10,
            position: 'bottom',
            dropShadow: false,
          },
          dropShadow: {
            enabled: false,
          },
          background: {
            enabled: true,
            foreColor: '#2178FB',
            borderRadius: 2,
            padding: 4,
            opacity: 0.9,
            borderWidth: 1,
            borderColor: '#fff',
          },
        },
        tooltip: {
          enabled: true,
          style: {
            fontSize: '12px',
            paddingBottom: 0,
          },
        },
        legend: {
          show: false,
        },
        colors: ['#1A60C9', '#808080'],
        labels: [],
        responsive: [
          {
            breakpoint: 575,
            options: {
              chart: {
                width: 150,
              },
              legend: {
                position: 'center',
              },
            },
          },
        ],
      },

      allUsers: [],
    }
  },
  computed: {
    editRights() {
      if (this.userInfo && this.userInfo.role == 'SA') {
        return true
      } if (
        this.projectDtl
        && this.projectDtl.project_user
        && this.projectDtl.project_user.length
      ) {
        const user = this.projectDtl.project_user.find(
          pu => pu.id === this.userInfo.id,
        )

        if (user && user.access_rights == 'project_owner') {
          return true
        }
        // access_rights = project_owner, co_ordinator, member
      }
      return false
    },
    projectTypeName() {
      const name = this.projectTypeList.find(element => {
        if (element.value === this.projectDtl.type) {
          return element
        }
      }).label
      return name
    },
    projectTypeClass() {
      if (this.projectDtl.type == 'dedicated') return 'dedicated'
      if (this.projectDtl.type == 'fix_cost') return 'fixcost'
      if (this.projectDtl.type == 'fix_timeline') return 'fixtimeline'
      return 'hourly'
    },
    projectStatusName() {
      const name = this.projectStatusList.find(element => {
        if (element.value === this.projectDtl.status) {
          return element
        }
      }).label
      return name
    },
    projectStatusClass() {
      if (this.projectDtl.status == 'in_planning') return 'p-inplanning'
      if (this.projectDtl.status == 'in_progress') return 'p-inprogress'
      if (this.projectDtl.status == 'on_hold') return 'p-insupport'
      if (this.projectDtl.status == 'in_support') return 'p-insupport'
      return 'p-completed'
    },
    checkValidation() {
      if (!this.code || !this.clientID || !this.status || !this.type) {
        return false
      }

      if (!this.time_estimated || this.time_estimated < 0) {
        return false
      }

      if (!this.project_scope_id || !this.project_scope_id.length) {
        return false
      }

      return true
    },
    projectTimeBillable() {
      if (this.spendHoursDetail && this.spendHoursDetail.billable_total) {
        return this.$options.filters.convertMinutes(this.spendHoursDetail.billable_total)
      }
      return '00:00'
    },
    projectTotalTimeSpend() {
      if (this.spendHoursDetail && this.spendHoursDetail.total_time) {
        return this.$options.filters.convertMinutes(this.spendHoursDetail.total_time)
      }
      return '00:00'
    },
  },
  watch:{
    
    spendHoursDetail(){
      this.countBillableOrNonbillableHours()
    }
  },
  
  mounted() {
    this.getDetails()

    if (this.editRights) {
      if (this.$store.state.app.projectScope.length == 0) {
        this.getProjectScopes()
      }

      if (this.$store.state.app.clientList.length == 0) {
        this.getClientData()
      }
    }
    this.countBillableOrNonbillableHours()
    // this.projectDetail();
  },
  methods: {
    /**
     * Get Project details for edit
     *
     */
    getDetails() {
      this.isEdit = false
      this.description = this.projectDtl?.description || null
      this.code = this.projectDtl?.code || null
      this.clientID = this.projectDtl?.client_id || null
      this.status = this.projectDtl?.status || null
      this.type = this.projectDtl?.type || null
      this.time_estimated = this.projectDtl?.time_estimated || null
      this.project_scope_id = this.projectDtl?.project_scopes?.map(s => s.id) || []
      this.projectColor = this.projectDtl?.color
        ? `#${this.projectDtl.color}`
        : ''
      this.projectName = this.projectDtl?.name || ''
      this.allUsers = this.projectDtl?.project_user
      // this.countBillableOrNonbillableHours();
    },
    /**
     * Get Clients data
     */
    async getClientData() {
      const input = {
        is_active: 1,
      }
      const response = await this.getHTTPPostResponse(
        'admin/master/client',
        input,
        false,
      )
      if (response && response.data) {
        this.$store.commit('app/UPDATE_CLIENT_LIST', response.data.clients)
      }
    },
    /**
     * Get project Scope list
     */
    async getProjectScopes() {
      const input = {
        is_active: 1,
      }
      const response = await this.getHTTPPostResponse(
        'admin/master/project-scope',
        input,
        false,
      )
      if (response && response.data) {
        /** used in project detail */
        this.$store.commit('app/UPDATE_PROJECT_SCOPE', response.data.project_scopes)
      }
    },
    async updateDetails() {
      this.$refs.projectDetailsObserver.validate().then(async success => {
        if (success) {
          this.loader = true
          const input = {
            client_id: this.clientID,
            description: this.description,
            status: this.status,
            type: this.type,
            code: this.code,
            project_scope_id: this.project_scope_id,
            time_estimated: this.time_estimated,
            project_id: this.$route.params.id3,
            color: this.projectColor.substring(1),
            name: this.projectName,
          }
          const response = await this.getHTTPPutResponse(
            `project/update-basic/${this.projectDtl.id}`,
            input,
            true,
          )
          if (response && response.status == '200') {
            this.isEdit = false
            this.loader = false
            eventBus.$emit('reloadProjectDtl', true)
          }
        } else {
          this.loader = false
          this.isEdit = true
        }
      })
    },

    // TODO:count billable or non billable hours
    countBillableOrNonbillableHours() {
      let actualBillableHours = 0
      let actualNonBillableHours = 0
      let billableHours = 0
      let nonBillableHours = 0
      actualBillableHours = this.projectDtl.time_plan[0].billable_time_plan

      actualNonBillableHours = this.projectDtl.time_plan[0].non_billable_time_plan

      billableHours = Number(this.convertMinutes(this.spendHoursDetail.billable_total))
      nonBillableHours = Number(this.convertMinutes(this.spendHoursDetail.non_billable_total))

      this.series= []

      this.series.push(billableHours, nonBillableHours)
      this.chartOptions.labels.push(
        `<b>Billable</b> <br> Planned hour :<span style="font-weight: bold;">   ${
          actualBillableHours
        }</span><br> Actual hour`,
      )
      this.chartOptions.labels.push(
        `<b>Non-Billable</b> <br> Planned hour :<span style="font-weight: bold;">   ${
          actualNonBillableHours
        }</span><br> Actual hour`,
      )
    },
    convertMinutes(num) {
      const hours = num / 60
      const rhours = Math.floor(hours)
      const minutes = (hours - rhours) * 60
      const rminutes = Math.round(minutes);
      (`0${num}`).slice(-2)
      if (num < 6000) {
        return `${(`0${rhours}`).slice(-2)}.${(`0${rminutes}`).slice(-2)}`
      }
      return `${rhours}.${(`0${rminutes}`).slice(-2)}`
    },
  },
}
</script>

<style>
@import "../../../assets/scss/component-css/projectcards.css";
.maxTime-log {
  background-color: rgba(234, 84, 85, 0.12) !important;
  padding: 5px 9px;
}

.color-picker-input.color-picker {
  height: 36px;
  width: 60px;
  border: transparent;
  background: transparent;
}
.color-picker-input.input-group-text {
  padding: 0;
}
.color-picker-input.form-control {
  border-right: none;
}
</style>
