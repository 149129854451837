<template>
  <div>
    <div>
      <!--filter start-->
      <div class="card-filter-section py-1">
        <div
          class="d-flex align-items-center flex-wrap justify-content-between"
        >
          <div class="d-flex align-items-center">
            <button
              class="back-button btn btn-secondary mr-2"
              @click="back()"
            >
              <feather-icon
                icon="ArrowLeftIcon"
                width="18"
                height="18"
              />
              <span>Back</span>
            </button>
            <p class="filter-title ml-50">
              {{
                actionTypeCheck
                  ? "Details"
                  : $store.state.app.testActiveName + " Test Case"
              }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="!actionTypeCheck"
      class="test-form"
    >
      <validation-observer ref="testCaseRules">
        <b-form @submit.prevent="validationForm">
          <b-row>
            <!-- first name -->
            <b-col
              v-show="testCaseId"
              cols="3"
            >
              <b-form-group
                label="ID"
                label-for="v-id"
              >
                <b-form-input
                  id="v-id"
                  v-model="code"
                  type="text"
                  placeholder="ID"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>
            <b-col cols="3">
              <b-form-group
                label="Title"
                label-for="v-title"
                class="required-form-filed"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Title"
                  rules="required|max:512"
                >
                  <b-form-input
                    id="v-title"
                    v-model="title"
                    type="text"
                    placeholder="Title"
                    name="Title"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="3"
              class="project-select"
            >
              <b-form-group
                label="Application Type"
                label-for="ApplicationType"
                class="required-form-filed"
              >
                <validation-provider
                  #default="{ errors }"
                  name="ApplicationType"
                  rules="required"
                >
                  <v-select
                    id="ApplicationType"
                    v-model="applicationType"
                    label="name"
                    class="select-size-lg"
                    name="ApplicationType"
                    placeholder="Select Application Type"
                    :options="applicationTypeOption"
                    :reduce="(t) => t.value"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="3"
              class="project-select"
            >
              <b-form-group
                label="Test Severity"
                label-for="TestSeverity"
                class="required-form-filed"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Test Severity"
                  rules="required"
                >
                  <v-select
                    id="TestSeverity"
                    v-model="priority"
                    label="name"
                    class="select-size-lg"
                    name="Test Severity"
                    placeholder="Select Test Severity"
                    :options="allPriorities"
                    :reduce="(p) => p.value"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="3"
              class="project-select"
            >
              <b-form-group
                label="Test Suite"
                label-for="TestSuite"
                class="required-form-filed"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Test Suite"
                  rules="required"
                >
                  <v-select
                    id="testSuite"
                    v-model="$store.state.app.suiteId"
                    :options="suiteListOption"
                    label="suite_name"
                    :reduce="(priority) => priority.id"
                    class="select-size-lg"
                    name="Assignee"
                    placeholder=" select test suite"
                    :disabled="actionTypeCheck"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              v-show="false"
              cols="3"
            >
              <b-form-group>
                <validation-provider name="Last Tested Date">
                  <label> Last Tested Date </label>
                  <flat-pickr
                    v-model="testedDate"
                    class="form-control"
                    :disabled="actionTypeCheck"
                  />
                </validation-provider>
                <p>Last Tested Date</p>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <div>
                <label for="textarea-default">Attachment</label>
                <div class="position-relative">
                  <div :class="actionTypeCheck ? 'non-clickable' : null" />
                  <vue-dropzone
                    v-show="!actionTypeCheck"
                    id="dropzone"
                    ref="myVueDropzone"
                    :options="dropzoneOptions"
                    :duplicate-check="true"
                    :disabled="actionTypeCheck"
                    @vdropzone-success="handleFileAdding"
                    @vdropzone-removed-file="removeFile"
                    @vdropzone-error="handleError"
                    @vdropzone-duplicate-file="handleDuplicateFile"
                    @vdropzone-sending="checkExistFile"
                  />
                  <small
                    v-if="!isValidFile"
                    class="text-danger"
                  >{{
                    FileErrorMsg
                  }}</small>
                  <div
                    v-show="addIconShow && !actionTypeCheck && errorFileList.length == 0"
                    ref="more"
                    class="more"
                  >
                    +
                  </div>
                </div>
                <div
                  v-if="task_attachments.length"
                  class="mb-1 download-content"
                  style=""
                >
                  <div class="download-files">
                    <div
                      v-for="(image, key) in task_attachments"
                      :key="key"
                      class="download-link p-50"
                      role="button"
                      @click="openFileInNewTab(image.url)"
                    >
                      <!-- <a :href="image.url | attachmentURL" target="_blank"> -->
                      {{ image.name }}
                      <!-- </a> -->
                      <feather-icon
                        icon="EyeIcon"
                        color="white"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </b-col>
            <b-col cols="6">
              <div class="description">
                <label for="textarea-default">Description<span class="text-danger">*</span></label>
                <validation-provider
                  #default="{ errors }"
                  name="Description"
                  rules="required|required_rule_for_editor|validateNoImage|max:2000"
                >
                  <quill-editor
                    v-model="description"
                    :options="descriptionEditorOption"
                  >
                    <div
                      id="toolbar1"
                      slot="toolbar"
                    >
                      <!-- Add font size dropdown -->
                      <select class="ql-size">
                        <option value="small" />
                        <!-- Note a missing, thus falsy value, is used to reset to default -->
                        <option selected />
                        <option value="large" />
                        <option value="huge" />
                      </select>

                      <select class="ql-font">
                        <option selected="selected" />
                        <option value="serif" />
                        <option value="monospace" />
                      </select>
                      <!-- Add a bold button -->
                      <button class="ql-bold">
                        Bold
                      </button>
                      <button class="ql-italic">
                        Italic
                      </button>
                      <button class="ql-underline">
                        Underline
                      </button>

                      <button class="ql-clean" />
                      <select class="ql-align">
                        <option selected="selected" />
                        <option value="center" />
                        <option value="right" />
                        <option value="justify" />
                      </select>
                      <button
                        id="ql-link"
                        class="ql-link"
                      >
                        <feather-icon
                          icon="Link2Icon"
                          color="white"
                        />
                      </button>

                      <button
                        class="ql-list"
                        value="ordered"
                      />
                      <button
                        class="ql-list"
                        value="bullet"
                      />
                      <!-- You can also add your own -->
                    </div>
                  </quill-editor>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </div>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col cols="12">
              <b-form-group
                label="Expected Result"
                label-for="v-expected-result"
                class="required-form-filed"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Expected result"
                  rules="required"
                >
                  <b-form-input
                    v-if="!actionTypeCheck"
                    id="v-expected-result"
                    v-model="expectedResult"
                    type="text"
                    placeholder="Expected Result"
                    :disabled="actionTypeCheck"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                <p v-if="actionTypeCheck">
                  {{ expectedResult }}
                </p>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
        <div class="scenario-steps">
          <!-- <p class="title">Scenario Steps</p> -->
          <!-- <div class="d-flex my-2 align-items-center">
            <feather-icon
              icon="PlusCircleIcon"
              width="18"
              height="18"
              color="#2178FB"
            ></feather-icon>
            <p class="add-step ml-50">Scenario Steps</p>
          </div> -->
          <b-form>
            <b-row>
              <b-col cols="12">
                <div>
                  <validation-provider
                    #default="{ errors }"
                    name="Test Case Steps"
                    rules="required|required_rule_for_editor|validateNoImage|max:2000"
                  >
                    <label for="textarea-default">Test Case Steps<span class="text-danger">*</span></label>

                    <div
                      v-if="actionTypeCheck"
                      v-html="scenarioSteps"
                    />
                    <div>
                      <quill-editor
                        v-show="!actionTypeCheck"
                        v-model="scenarioSteps"
                        rows="4"
                        :options="editorOption"
                      >
                        <div
                          id="toolbar"
                          slot="toolbar"
                        >
                          <!-- Add font size dropdown -->
                          <select class="ql-size">
                            <option value="small" />
                            <!-- Note a missing, thus falsy value, is used to reset to default -->
                            <option selected />
                            <option value="large" />
                            <option value="huge" />
                          </select>

                          <select class="ql-font">
                            <option selected="selected" />
                            <option value="serif" />
                            <option value="monospace" />
                          </select>
                          <!-- Add a bold button -->
                          <button class="ql-bold">
                            Bold
                          </button>
                          <button class="ql-italic">
                            Italic
                          </button>
                          <button class="ql-underline">
                            Underline
                          </button>

                          <button class="ql-clean" />
                          <select class="ql-align">
                            <option selected="selected" />
                            <option value="center" />
                            <option value="right" />
                            <option value="justify" />
                          </select>
                          <button
                            id="ql-link"
                            class="ql-link"
                          >
                            <feather-icon
                              icon="Link2Icon"
                              color="white"
                            />
                          </button>

                          <button
                            class="ql-list"
                            value="ordered"
                          />
                          <button
                            class="ql-list"
                            value="bullet"
                          />

                          <!-- You can also add your own -->
                        </div>
                      </quill-editor>
                    </div>
                    <small class="text-danger">{{ errors[0] }}</small>
                    <p v-show="false">
                      Scenario Steps
                    </p>
                  </validation-provider>
                </div>
              </b-col>
            </b-row>
            <b-row />
          </b-form>
          <div
            v-if="$store.state.app.testActiveName != 'View'"
            class="my-2 position-relative zindex-4"
          >
            <b-button
              type="submit"
              class="mr-1 save-btn"
              :disabled="loader"
              @click="validationForm"
            >
              <b-spinner
                v-if="loader"
                small
                class="spinner"
              />
              {{ testCaseId ? "Update" : "Save" }}
            </b-button>
            <!-- <b-button type="submit" class="mr-1 save-btn" v-show="testCaseId" :disabled="loader" @Click="updateStatusConfirmation('defect')"> Defect </b-button>
            <b-button type="submit" class="mr-1 pass-btn" v-show="testCaseId" :disabled="loader" @Click="updateStatusConfirmation('pass')"> pass </b-button> -->
            <b-button
              class="cancelBtn"
              type="reset"
              @click="back()"
            >
              cancel
            </b-button>
          </div>
        </div>
      </validation-observer>
    </div>

    <ViewTestCase
      v-if="actionTypeCheck"
      :code="code"
      :title="title"
      :application_type="application('applicationTypeOption', applicationType)"
      :test_severity="priority"
      :description="description"
      :expect_result="expectedResult"
      :scenario_steps="scenarioSteps"
      :attachment="task_attachments"
      :last_test_run="lastRun"
      :suite_id="$store.state.app.suiteId"
      :loader="loader"
    />
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";
import { quillEditor } from 'vue-quill-editor'
import { eventBus } from '@/main'
import ViewTestCase from './viewTestCase.vue'

extend('validateNoImage', {
  validate: value => {
    // Check if the content contains any image tag
    const containsImage = /<img[^>]+src="[^"]+"[^>]*>/i.test(value)
    return !containsImage
  },
  message: 'Image is not allowed in the description',
})
export default {
  name: 'CreateTestCase',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    vueDropzone: vue2Dropzone,
    quillEditor,
    ViewTestCase,
  },
  data() {
    return {
      applicationTypeOption: [],
      title: null,
      applicationType: null,
      priority: 'medium',
      testedDate: null,
      taskTag: null,
      description: null,
      expectedResult: null,
      scenarioSteps: null,
      loader: false,
      suiteId: null,
      code: null,
      testCaseId: null,
      testcaseprojectId: null,
      file: [],
      task_attachments: [],
      remove_attachments: [],
      lastRun: null,
      dropzoneOptions: {
        url: `${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_API_VERSION}/project/task/image-store`,
        maxFiles: 20,
        uploadMultiple: true,
        parallelUploads: 100,
        // thumbnailWidth: 150,
        maxFilesize: 10,
        addRemoveLinks: true,
        addViewLinks: true,
        acceptedFiles:
          '.jpeg,.jpg, .png, .txt, .pdf,.ppt,.pptx, .doc, .csv, .docx, .xls, .xlsx',
        dictDefaultMessage:
          "<b class='drop-title'>Drag & drop files</b> <br> <p class='drop-req'>Supported formats: JPEG, PNG, JPG, <br /> TXT, PDF, PPT, DOC, CSV, DOCX, XLS, XLSX. <br />",
      },
      editorOption: {
        modules: {
          toolbar: '#toolbar',
        },
        placeholder: 'Type anything',
      },
      descriptionEditorOption: {
        modules: {
          toolbar: '#toolbar1',
        },
        placeholder: 'Type anything',
      },
      addIconShow: false,
      errorFileList: [],
      isValidFile: true,
      FileErrorMsg: null,
    }
  },
  computed: {
    actionTypeCheck() {
      if (this.$store.state.app.testActiveName == 'View') {
        return true
      }
      return false
    },
    suiteListOption() {
      const { suiteList } = this.$store.state.app
      suiteList.forEach(element => {
        element.label = element.suite_name
        element.value = element.id
      })
      return suiteList
    },
  },
  destroyed() {
    this.$store.commit('app/UPDATE_TEST_CASE_ID', null)
  },

  // mounted() {
  //   this.clear();

  //   const { app, route } = this.$store.state;
  //   const { id3, id4, id5 } = this.$route.params;

  //   app.backAction = app.backAction || id3;
  //   app.testActiveName = app.testActiveName || id4;

  //   if (app.testActiveName) {
  //     app.testActiveName =
  //       app.testActiveName[0].toUpperCase() + app.testActiveName.slice(1);
  //   }

  //   if (id4 !== "create" && id5) {
  //     this.getDefect(id5);
  //   }

  //   if (!app.suiteListWithoutTestCase || !app.suiteListWithoutTestCase.length) {
  //     this.getSuiteList();
  //   }
  // },
  mounted() {
    const { app } = this.$store.state
    const {
      id4, id5, id6, id7,
    } = this.$route.params
    app.backAction = app.backAction || id4 === 'test-case' ? 'test-suite' : id4

    app.testActiveName = app.testActiveName || id5
    if ((id6 && id5 == 'view') || id7) {
      app.testActiveName = 'View'
    }

    if (app.testActiveName) {
      app.testActiveName = app.testActiveName[0].toUpperCase() + app.testActiveName.slice(1)
    }
    this.$store.state.app.projectDetail.project_scopes.forEach(element => {
      const obj = {
        value: element.id,
        name: element.name,
      }
      this.applicationTypeOption.push(obj)
    })

    if (id5 === 'create') {
      this.applicationType = this.applicationTypeOption[0].value
      this.$store.state.app.suiteId = this.$route.query.suiteId
    }

    // if (id4 == "view" || id6) {
    //   this.$store.state.app.testCaseId = id6 ? id6 : id5;
    //   if(id6){
    //     this.$store.state.app.test_run.id  = id4;
    //   this.$store.state.app.test_run.plan_id  = id5;
    //   }
    //   this.getTestCaseDetails();
    // }

    if ((id5 !== 'create' && id6) || id7) {
      this.$store.state.app.testCaseId = id7 || id6

      this.getTestCaseDetails()
    }
    if (!app.suiteListWithoutTestCase || !app.suiteListWithoutTestCase.length) {
      this.getSuiteList()
    }

    eventBus.$on('reloadSuiteList', () => {
      this.getSuiteList()
    })
  },

  methods: {
    back() {
      if (this.$route.params.id7) {
        this.$router.push({
          name: 'projectTask',
          params: {
            id: this.$route.params.id,
            id2: this.$route.params.id2,
            id3: this.$route.params.id3,
            id4: this.$route.params.id4,
            id5: this.$route.params.id5,
            id6: this.$route.params.id6,
          },
          query: this.$route.query,
        })
        return this.$store.state.app.pageShow = 'testCasesTableVue'
      }

      this.$store.state.app.pageShow = this.$store.state.app.backAction
        ? this.$store.state.app.backAction
        : this.$route.params.id4 == 'test-case'
          ? 'test-suite'
          : null

      this.$router.push({
        name: 'projectTask',
        params: {
          id: this.$route.params.id,
          id2: this.$route.params.id2,
          id3: this.$route.params.id3,
          id4: 'test-suite',
          id5: null,
          id6: null,

        },
        query: this.$route.query,
      })
    },
    /**
     *  check the form validation
     * @returns if success true then call saveGroupDtl Method
     */
    validationForm() {
      this.$refs.testCaseRules.validate().then(success => {
        if (success) {
          if (this.testCaseId) {
            this.updateTestCase()
          } else {
            this.createNewTestCase()
          }
        }
      })
    },

    /** Create new test case */
    async createNewTestCase() {
      this.loader = true
      const formData = new FormData()
      formData.append('project_id', this.$route.params.id3)
      const suiteId = this.$store.state.app.suiteId
        ? this.$store.state.app.suiteId
        : null
      formData.append('suite_id', suiteId)
      formData.append('title', this.title)
      formData.append('project_scope_id', this.applicationType)
      formData.append('test_severity', this.priority)
      formData.append('scenario_steps', this.scenarioSteps)
      formData.append('description', this.description)
      formData.append('expected_result', this.expectedResult)
      if (this.file && this.file.length) {
        this.file.forEach((element, index) => {
          formData.append(`files[${index}][file]`, element.dataURL)
          formData.append(`files[${index}][name]`, element.name)
        })
      }

      const response = await this.getHTTPPostResponse(
        'project/test-case/create',
        formData,
        true,
      )

      if (response && response.status == 200) {
        eventBus.$emit('clearSuiteSearch', true)
        setTimeout(() => {
          this.back()
          this.loader = false
        }, 1000)
      } else {
        this.loader = false
      }
    },

    handleMoreThumbnail() {
      const { dropzone } = this.$refs.myVueDropzone
      dropzone.files.length > 0 && dropzone.files.length < 20
        ? (this.addIconShow = true)
        : (this.addIconShow = false)
      dropzone.files.length > 0
        ? dropzone.element.appendChild(this.$refs.more)
        : dropzone.element.removeChild(this.$refs.more)
    },
    handleFileAdding(file) {
      // let dropzone = this.$refs.myVueDropzone.dropzone;
      //   dropzone.files.length > 0 ?  this.addIconShow = true : this.addIconShow = false;
      //   dropzone.files.length > 0
      //     ? dropzone.element.appendChild(this.$refs.more)
      //     : dropzone.element.removeChild(this.$refs.more);
      this.handleMoreThumbnail()

      if (!file.manuallyAdded == true) {
        if (file.status == 'success') {
          this.isValidFile = true
          this.file.push(file)
          this.file.forEach(value => {
            const fileReader = new FileReader()
            let base64
            fileReader.onload = fileLoadedEvent => {
              base64 = fileLoadedEvent.target.result
              value.dataURL = base64
            }
            fileReader.readAsDataURL(value)
            //  Checks every second for the dataURL.
            var checkIfReady = setInterval(() => {
              // If there is it logs it.
              if (fileReader.result) {
                // USE the DATAURL
                clearInterval(checkIfReady)
              }
              // Stops checking after 10 seconds.
              setTimeout(() => {
                clearInterval(checkIfReady)
              }, 1000)
            }, 1000)
          })
        }
      }
    },

    removeFile(file) {
      if (file.id) {
        this.remove_attachments.push(file.id)
        const task_attachmentsIndex = this.task_attachments.findIndex(
          x => x.id === file.id,
        )
        this.task_attachments.splice(task_attachmentsIndex, 1)
      } else {
        const fileIndex = this.file.findIndex(
          x => x.upload.uuid === file.upload.uuid,
        )
        this.file.splice(fileIndex, 1)
      }

      const ErrorFileIndex = this.errorFileList.findIndex(
        x => x.upload.uuid === file.upload.uuid,
      )
      this.errorFileList.splice(ErrorFileIndex, 1)

      this.file.length > 0
        ? (this.addIconShow = true)
        : this.task_attachments.length > 0 ? (this.addIconShow = true) : (this.addIconShow = false)

      this.handleMoreThumbnail()
    },

    handleError(file) {
      this.errorFileList.push(file)
    },

    // Check on duplicate file when task create
    handleDuplicateFile(event) {
      this.isValidFile = false
      this.FileErrorMsg = 'Duplicate file does not allowed.'
    },

    // Check on duplicate file when task update
    checkExistFile(file) {
      if (this.task_attachments.length) {
        const fileExit = this.task_attachments.find(item => item.name === file.name)
        if (fileExit) {
          this.$refs.myVueDropzone.removeFile(file)
          this.isValidFile = false
          this.FileErrorMsg = 'Duplicate file does not allowed.'
        }
      }
    },
    /** fill up form data for edit */
    async getTestCaseDetails() {
      
      if (
        this.$store.state.app.testCaseId
        && this.$store.state.app.testCaseId.length > 0
      ) {
        this.loader = true
        if (this.$route.params.id7) {
          this.testCaseId = this.$route.params.id7
        } else if (this.$route.params.id6) {
          this.testCaseId = this.$route.params.id6
        } else {
          this.testCaseId = this.$store.state.app.testCaseId
        }

        if (!this.testCaseId) {
          return
        }
        let response
        if (this.$route.params.id4 === 'test-case') {
          response = await this.getHTTPGetResponse(
            `project/test-case/view/${this.testCaseId}`,
          )
        } else {
          response = await this.getHTTPGetResponse(
            `project/test-run/view-test-run-case/${this.testCaseId}`,
          )
        }
        if (response && response.status == 200) {
          let data
          if (this.$route.params.id4 === 'test-case') {
            data = response.data && response.data.test_case
              ? response.data.test_case
              : null
          } else {
            data = response.data
              && response.data.testRunCase && response.data.testRunCase.length
              && response.data.testRunCase[0].test_case
              ? response.data.testRunCase[0].test_case
              : null
            this.$store.commit(
              'app/UPDATE_TEST_CASE_ID_OF_RUN',
              response.data.testRunCase[0],
            )
          }
          this.code = data && data.code
          this.title = data && data.title
          this.applicationType = data.project_scope_id
          //

          this.priority = data.test_severity
          this.expectedResult = data.expected_result
          this.testedDate = data.last_tested_date
          this.description = data.description
          this.scenarioSteps = data.scenario_steps
          this.$store.state.app.suiteId = data.suite_id
          this.testcaseprojectId = data.project_id
          this.task_attachments = data.test_case_attachments
          this.lastRun = data?.last_tested_date
          this.remove_attachments = []
          if (this.task_attachments.length) {
            this.task_attachments.forEach(attachment => {
              const file = {
                size: 100,
                name: `${attachment.name}`,
                type: `${attachment.type}`,
                id: `${attachment.id}`,
              }
              //
              let url
              this.openFileInNewTab(attachment.url, false).then(response => {
                url = response
                if (this.$route.params.id4 == 'edit') {
                  this.$refs.myVueDropzone.manuallyAddFile(file, response)
                  this.handleMoreThumbnail()
                }
              })
            })
          }
        }
        this.loader = false
      }
    },

    /** Update test case data */
    async updateTestCase() {
      this.loader = true
      const formData = new FormData()
      formData.append('_method', 'PUT')
      formData.append('project_id', this.testcaseprojectId)
      formData.append('suite_id', this.$store.state.app.suiteId)
      formData.append('title', this.title)
      formData.append('project_scope_id', this.applicationType)
      formData.append('test_severity', this.priority)
      formData.append('scenario_steps', this.scenarioSteps)
      formData.append('description', this.description)
      if (this.last_tested_date) {
        formData.append('last_tested_date', this.testedDate)
      }
      formData.append('expected_result', this.expectedResult)

      if (this.file && this.file.length) {
        this.file.forEach((element, index) => {
          formData.append(`files[${index}][file]`, element.dataURL)
          formData.append(`files[${index}][name]`, element.name)
        })
      }

      if (this.remove_attachments && this.remove_attachments.length) {
        this.remove_attachments.forEach((element, index) => {
          formData.append('remove_file_ids[]', element)
        })
      }

      const response = await this.getHTTPPostResponse(
        `project/test-case/update/${this.testCaseId}`,
        formData,
        true,
      )

      if (response && response.status == 200) {
        setTimeout(() => {
          this.back()
          this.loader = false
        }, 1000)
      } else {
        this.loader = false
      }
    },

    /**
     * update Status Confirmation Message
     */

    /*
    updateStatusConfirmation(status) {
      this.$swal({
        title: 'Are you sure ?',
        icon: 'info',
        html: `change a status as ${status}.`,
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: 'Update',
        confirmButtonAriaLabel: 'Thumbs up, great!',
        cancelButtonAriaLabel: 'Thumbs down',
        customClass: {
          confirmButton: 'btn confirm',
          cancelButton: 'btn cancel ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.updateStatus(status)
        }
      })
    },
    */

    application(type, id) {
      const arr = type == 'applicationTypeOption'
        ? this.applicationTypeOption
        : this.allPriorities

      const element = arr.find(element => element.value == id)
      return element && element.name ? element.name : null
    },
    async getSuiteList() {
      const input = {
        project_id: this.$route.params.id3 ? this.$route.params.id3 : null,
        search: this.search,
      }
      const response = await this.getHTTPPostResponse(
        'project/test-suite',
        input,
        false,
      )

      if (response && response.status == 200) {
        const data = response.data && response.data.test_suite
          ? response.data.test_suite
          : []
        this.suiteList = data
        this.$store.commit('app/UPDATE_SUITE_LIST_WITHOUT_TESTCASE', data)
      }
    },
  },
}
</script>
<style lang="scss" scoped>
@import "../../../assets/scss/component-css/tesing.css";

.dropzone {
  background-color: #fff !important;
  border: 1px dashed;
  padding: 40px 20px !important;
}

.more {
  display: inline-block;
  margin: 16px;
  border: 3px dashed lightgray;
  width: 200px;
  height: 200px;
  box-sizing: border-box;
  color: lightgray;
  border-radius: 8px;
  font-size: 60px;
  text-align: center;
  line-height: 200px;
  pointer-events: none;
}
</style>
