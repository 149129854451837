<template>
  <div>
    <table
      v-if="items.sprints && items.sprints.length"
      class="w-100 sprint-table"
    >
      <tr>
        <th>
          <p class="sprint-title">
            Sprint name
          </p>
        </th>
        <th>
          <p class="sprint-title">
            start Date
          </p>
        </th>
        <th>
          <p class="sprint-title">
            End Date
          </p>
        </th>
        <th>
          <p class="sprint-title last-child">
            Status
          </p>
        </th>
      </tr>
      <tr
        v-for="datas in items.sprints"
        :key="datas.index"
        class="sprint-data-row cursor-pointer"
        @click="datas.status == 'active' ? activeListOpen(datas.project_id) : sprintOpen(datas.id) "
      >
        <td class="sprint-item-row first-child">
          <p class="sprint-item">
            {{ datas.title }}
          </p>
        </td>
        <td class="sprint-item-row">

          <p class="sprint-item sprint-date">
            {{ dayName(datas.estimated_start_date) }}
          </p>
        </td>
        <td class="sprint-item-row">
          <p class="sprint-item sprint-date">
            {{ dayName(datas.estimated_end_date) }}
          </p>
        </td>

        <td
          :style="
            rights === false
              ? { borderRight: '1px solid #dddbe2', borderRadius: '5px' }
              : ''
          "
          class="sprint-item-row"
        >
          <p
            v-if="datas.status == 'not_started'"
            class="sprint-status-item inprogress"
          >
            Not Started
          </p>
          <p
            v-if="datas.status == 'complete'"
            class="sprint-status-item completed"
          >
            Complete
          </p>
          <p
            v-if="datas.status == 'active'"
            class="sprint-status-item active"
          >
            Active
          </p>
        </td>

        <td
          v-if="rights === true || userInfo.role == 'SA'"
          class="edit-button sprint-item-row last-child-td"
          @click.stop
        >
          <b-dropdown
            toggle-class="text-decoration-none"
            dropup
          >
            <template v-slot:button-content>
              <button class="edit-del-button">
                <feather-icon
                  icon="MoreHorizontalIcon"
                  size="20"
                  class="text-body align-middle"
                />
              </button>
            </template>
            <b-dropdown-item
              class="dropdown-items"
              @click="edit(datas)"
            >
              <feather-icon
                icon="Edit2Icon"
                class="mr-1 icons"
              />
              <span
                v-b-toggle.sprintfrom
                class="icons"
              >Edit</span>
            </b-dropdown-item>
            <b-dropdown-item
              class="dropdown-items"
              @click="deleteConfirmation(datas.id)"
            >
              <feather-icon
                icon="XIcon"
                class="mr-1 del-icons"
              />
              <span class="del-icons">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </td>
      </tr>
    </table>

    <div v-if="rights === true || userInfo.role == 'SA'">
      <b-button
        v-if="data.status !== 'complete'"
        class="add-sprint-form"
        @click="addNewSprint = true"
      >
        <feather-icon
          icon="PlusIcon"
          size="18"
        />
        <span>Add sprint</span>
      </b-button>
    </div>
    <add-sprint
      v-if="addNewSprint"
      id="sprintfrom"
      ref="addEditSprint"
      :count-num="items.sprints.length"
      fromtitle="Add sprint"
      :milstoneid="data.id"
      :milestone-end-date="data.estimated_end_date"
      :count="count"
      :milestone-start-date="data.estimated_start_date"
      @closeAddSprint="addNewSprint = false"
      @sprintList="reloadSprintList"
    />
  </div>
</template>

<script>
import {
  VBToggle,
  BButton,
  BDropdownItem,
  BDropdown,
} from 'bootstrap-vue'
import * as moment from 'moment/moment'
import AddSprint from './AddSprint.vue'
import { eventBus } from '../../../main'

export default {
  name: 'Sprint',
  components: {
    BButton,
    BDropdownItem,
    BDropdown,
    AddSprint,
  },

  directives: {
    'b-toggle': VBToggle,
  },

  props: ['data', 'index', 'count', 'rights'],

  data() {
    return {
      status: 'active',
      items: this.data,
      addNewSprint: false,
    }
  },

  methods: {

    reloadSprintList(data) {
      this.items.sprints.unshift(data)
      this.$emit('count', this.items.sprints.length)
    },
    /* day's name */
    dayName(date) {
      return moment(date).format('DD MMMM, YYYY')
    },
    edit(data) {
      this.addNewSprint = true
      setTimeout(() => {
        this.$refs.addEditSprint.sprintID = data.id
        this.$refs.addEditSprint.sprint = data.title
        this.$refs.addEditSprint.description = data.description
        this.$refs.addEditSprint.estimated_start_date = data.estimated_start_date
        this.$refs.addEditSprint.estimated_end_date = data.estimated_end_date
        this.$refs.addEditSprint.parent_id = data.parent_id
        this.$refs.addEditSprint.configEstimatedStartDate = {
          minDate: data.estimated_start_date,
          maxDate: null,
          dateFormat: 'Y/m/d',
        }
        this.$refs.addEditSprint.configEstimatedEndDate = {
          minDate: data.estimated_start_date,
          maxDate: null,
          dateFormat: 'Y/m/d',
        }
      }, 100)
    },
    sprintOpen(sprint_id) {
      this.$router.push({
        name: 'sprint',
        params: {
          id: sprint_id,
        },
      })
    },
    /**
     * Active task list open
     */
    activeListOpen(val) {
      eventBus.$emit('activeListOpen', val)
    },
    /**
     * confirmation to delete spint
     */
    deleteConfirmation(id) {
      this.$swal({
        title: 'Are you sure?',
        icon: 'info',
        html: '<p>Once you delete you will not able to recover this record.</p>',
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: 'Delete',
        confirmButtonAriaLabel: 'Thumbs up, great!',
        cancelButtonAriaLabel: 'Thumbs down',
        customClass: {
          confirmButton: 'btn confirm',
          cancelButton: 'btn cancel ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.deleteSprint(id)
        }
      })
    },
    /**
     * delete client
     */
    async deleteSprint(id) {
      const input = {
        project_id: this.$route.params.id3,
      }

      const response = await this.getHTTPPostResponse(
        `project/plan/delete/${id}`,
        input,
        true,
      )
      if (response && response.status == 200) {
        const index = this.items.sprints.findIndex(s => s.id == id)
        if (index >= 0) {
          this.items.sprints.splice(index, 1)
          this.$emit('count', this.items.sprints.length)
        }
      }
    },

  },
}
</script>

<style></style>
