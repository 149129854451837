<template>
  <div>
    <div
      ref="scrollContainer"
      :class="
        parentSuiteList && parentSuiteList.length > 2
          ? $store.state.appConfig.layout.type == 'full' ?'right-side right-full-screen': 'right-side right-fix-height'
          : 'right-side'
      "
      @scroll="updateScrollbarValue"
    >
      <SuiteTestCase
        v-for="item in parentSuiteList"
        :id="item.id"
        :key="item.id"
        :item="item"
      />
    </div>

    <addtestcreate
      v-if="
        $store.state.app.testSuite.parent_suite_id ||
          $store.state.app.testSuite.id
      "
      id="createSuite"
      ref="addSuite"
    />
  </div>
</template>
<script>
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import {
  BCollapse, BButton, VBToggle, BCard,
} from 'bootstrap-vue'
import { eventBus } from '@/main'
import addtestcreate from './addtestcreate.vue'
import SuiteTestCase from './SuiteTestCase.vue'

export default {
  name: 'TestingRightSide',
  components: {
    AppCollapse,
    AppCollapseItem,
    BButton,
    BCard,
    BCollapse,
    addtestcreate,
    SuiteTestCase,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      testCaseList: [],
      suiteKeys: [],
      pageIndex: 0,
    }
  },
  computed: {
    parentSuiteList() {
      const parentSuiteList = this.$store.state.app.projectSuiteList // .filter((record) => record.parent_id === null);

      return parentSuiteList
    },
    testCaseData() {
      return Object.values(this.testCaseList).forEach(element => element)
    },
  },
  mounted() {
    // eventBus.$on("reloadTesCaseData", (data) => {
    //   if (data) {
    //     this.getTestCaseData();
    //   }
    // });
    // this.getTestCaseData();
    if (this.$store.state.app.testCaseAccordionIds.length) {
      setTimeout(() => {
        this.$refs.scrollContainer.scrollTop = this.$route.query.pageIndex
      }, 800)
    } else {
      this.queryDataUpdate()
    }
  },
  destroyed() {
    if (this.$store.state.app.pageShow !== 'create') {
      this.$store.state.app.testCaseAccordionIds = []
    }
  },
  methods: {
    /**
     *  confirmation to delete suite
     * @param {id} tag id
     * @returns if conform to delete call deleteTag function
     */
    deleteConfirmation(id) {
      this.$swal({
        title: 'Are you sure?',
        icon: 'info',
        html: '<p>Once you delete you will not able to recover this record.</p>',
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: 'Delete',
        confirmButtonAriaLabel: 'Thumbs up, great!',
        cancelButtonAriaLabel: 'Thumbs down',
        customClass: {
          confirmButton: 'btn confirm',
          cancelButton: 'btn cancel ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.deleteSuite(id)
        }
      })
    },

    /**
     *  delete suite
     * @method DELETE
     * @param {id}
     */
    async deleteSuite(id) {
      const response = await this.getHTTPDeleteResponse(
        `project/test-suite/delete/${id}`,
      )

      if (response && response.status == 200) {
        eventBus.$emit('reloadSuiteList', true)
      }
    },

    /** Fill suite modal data for edit */
    editData(data) {
      this.$refs.addSuite.editId = data.id
      this.$refs.addSuite.suiteName = data.suite_name
      this.$refs.addSuite.parent_suite_id = data.parent_id
    },

    /** Get test case data */
    // async getTestCaseData() {
    //   let input = {
    //     project_id: this.$route.params.id ? this.$route.params.id : null,
    //   };
    //   let response = await this.getHTTPPostResponse(
    //     "project/test-case",
    //     input,
    //     false
    //   );

    //   if (response && response.status == 200) {
    //     let data = response.data.test_case;
    //     this.testCaseList = data;
    //     this.$store.commit("app/UPDATE_TEST_CASE_LIST", data);
    //     this.suiteKeys = Object.keys(this.testCaseList);
    //   }
    // },

    editTestCase(id) {
      this.$store.commit('app/UPDATE_TEST_CASE_ID', id)
      this.$store.state.app.pageShow = 'create'
    },
    updateScrollbarValue() {
      this.pageIndex = this.$refs.scrollContainer.scrollTop
      this.queryDataUpdate()
    },

    queryDataUpdate() {
      this.$router.replace({
        params: this.$route.params,
        query: {
          ...this.$route.query,
          pageIndex: this.pageIndex,
          pageName: this.$route.params.page_name,
        },
      }).catch(err => {
        // Ignore the NavigationDuplicated error
        if (err.name !== 'NavigationDuplicated') {
          console.log(err)
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
@import "../../../assets/scss/component-css/tesing.css";
</style>
