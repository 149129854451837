<template>
  <div>
    <div>
      <div class="test-form mt-1">
        <b-row>
          <!-- first name -->
          <b-col cols="3" class="mb-1">
            <b-skeleton class="assign"></b-skeleton>
            <b-skeleton class="suit-content1"></b-skeleton>
          </b-col>
          <b-col cols="3">
            <b-skeleton class="assign"></b-skeleton>
            <b-skeleton class="suit-content1"></b-skeleton>
          </b-col>

          <b-col cols="3" class="project-select">
            <b-skeleton class="assign"></b-skeleton>
            <b-skeleton class="suit-content1"></b-skeleton>
          </b-col>

          <b-col cols="3" class="project-select">
            <b-skeleton class="assign"></b-skeleton>
            <b-skeleton class="suit-content1"></b-skeleton>
          </b-col>
          <b-col cols="3" class="project-select">
            <b-skeleton class="assign"></b-skeleton>
            <b-skeleton class="suit-content1"></b-skeleton>
          </b-col>
        </b-row>
        <!-- <h4>No contact added yet</h4>-->
      </div>
      <div class="test-form mt-1">
        <b-row>
          <b-col cols="12">
            <b-skeleton class="assign"></b-skeleton>
            <b-skeleton class="suit-content1"></b-skeleton>
          </b-col>
        </b-row>
        <!-- <h4>No contact added yet</h4> -->
      </div>
      <div class="test-form mt-1">
        <b-row>
          <b-col>
            <div>
              <b-skeleton class="assign"></b-skeleton>
              <b-skeleton class="suit-content1"></b-skeleton>
            </div>
          </b-col>
        </b-row>
        <!-- <h4>No contact added yet</h4> -->
      </div>
      <div class="test-form mt-1">
        <b-row>
          <b-col cols="12">
            <b-skeleton class="assign"></b-skeleton>
            <b-skeleton class="suit-content1"></b-skeleton>
          </b-col>
        </b-row>
        <!-- <h4>No contact added yet</h4> -->
      </div>

      <div class="test-form mt-1">
        <div class="scenario-steps view-steps">
          <b-row>
            <b-col cols="12">
              <b-skeleton class="assign"></b-skeleton>
              <b-skeleton class="suit-content1"></b-skeleton>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SkeletonLoaderVue from "skeleton-loader-vue";
import TextShimmer from "../../../shimmer/TextShimmer.vue";
import LongTextShimmer from "../../../shimmer/LongTextShimmer.vue";
export default {
  name: "ViewSuitcase",
  components: {
    LongTextShimmer,
    SkeletonLoaderVue,
    TextShimmer,
  },
};
</script>

<style>
.board-card-shimmer {
  width: 100% !important;
}
.tag {
  width: 40px;
  margin-right: 5px;
}
.assign {
  width: 100px;
}
.board-shimmer {
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
  padding: 0;
  max-width: 100%;
}
.suit-content1 {
  width: 100%;
  height: 20px;
}
.right-content {
  width: 100%;
  height: 40px;
}
.board-card-shimmer {
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
  padding: 20px;
}
</style>
