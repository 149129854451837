<template>
  <div class="task-list">
    <b-modal :id="id" cancel-variant="outline-secondary" centered size="lg">
      <template #modal-title>
        <h3 class="sprint-from-title">Start Sprint</h3>
        <div class="sprint-underline"></div>
      </template>
      <validation-observer ref="sprintStartRules">
        <b-form>
          <b-row>
            <!-- <b-col md="6" cols="12">
              <b-form-group>
                <label for="sprinttitle">Sprint title</label>
                <b-form-input id="sprinttitle" type="text" placeholder="Title" />
              </b-form-group>
            </b-col> -->
            <b-col md="6" cols="12">
              <b-form-group label="Milestone" label-for="milestone">
                <validation-provider #default="{ errors }" name="Milestone" rules="required">
                  <v-select
                    v-model="milestone"
                    label="title"
                    :options="milestones"
                    class="select-size-lg milestone-select"
                    placeholder="Select Milestone "
                    id="milestone"
                    name="milestone"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6" cols="12">
              <b-form-group label="Sprint" label-for="sprintID">
                <validation-provider #default="{ errors }" name="Sprint" rules="required">
                  <v-select
                    v-model="sprint_id"
                    label="title"
                    :options="sprints"
                    placeholder="Select Sprint "
                    class="select-size-lg milestone-select"
                    id="sprintID"
                    name="sprint_id"
                    :reduce="(sprint) => sprint.id"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-form-group label="Sprint description" label-for="Description">
            <validation-provider #default="{ errors }" name="Description" rules="">
              <b-form-textarea
                id="Description"
                size="sm"
                rows="3"
                placeholder="Description"
                name="description"
                v-model="description"
              ></b-form-textarea>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- <b-row>
            <b-col md="6" cols="12">
              <b-form-group>
                <label for="astartdate">Actual start date</label>
                <validation-provider #default="{ errors }" name="Actual start date">
                  <b-input-group class="input-group-merge">
                      <flat-pickr
                        v-model="actualStartDate"
                        class="form-control"
                        id="astartdate"
                        :config="configActualStartDate"
                        placeholder="Actual start date"
                        name="actualStartDate"
                      />
                      <b-input-group-append is-text>
                        <feather-icon icon="CalendarIcon" />
                      </b-input-group-append>
                      
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>                
              </b-form-group>
            </b-col>
            <b-col md="6" cols="12">
              <b-form-group>
                <label for="aenddate">Actual end date</label>
                <validation-provider #default="{ errors }" name="Actual end date">
                  <b-input-group class="input-group-merge">
                      <flat-pickr
                        v-model="actualEndDate"
                        class="form-control"
                        id="astartdate"
                        :config="configActualEndDate"
                        placeholder="Actual end date"
                        name="actualEndDate"
                      />
                      <b-input-group-append is-text>
                        <feather-icon icon="CalendarIcon" />
                      </b-input-group-append>
                      
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row> -->
        </b-form>
      </validation-observer>
      <template #modal-footer>
        <b-button class="start-btn" @click="validationForm">Start</b-button>
        <b-button class="clear-btn" @click="clearForm">Clear</b-button>
      </template>
      
    </b-modal>
  </div>
</template>

<script>
import {VBModal} from "bootstrap-vue";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import flatPickr from "vue-flatpickr-component";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { eventBus } from "@/main";

export default {
  name: "StartSprint",
  components: {
    FeatherIcon,
    flatPickr,
    ValidationProvider,
    ValidationObserver
  },
  // props: ["id"],
  props: {
    projectDtl: {
      // type: Object,
      required: true,
      default: null,
    },
    id: {
      type: String,
      required: false,
      default: 'startsprint',
    },
  },
  data() {
    return {
      milestones: [],
      milestone: null,
      sprints: [],
      sprint_id: null,
      description: null,
      // actualStartDate: null,
      // actualEndDate: null,
      configActualStartDate: {
          minDate: new Date(),
          maxDate: null,
          dateFormat: "Y/m/d",
      },
      configActualEndDate: {
          //minDate: new Date(),
          minDate: null,
          dateFormat: "Y/m/d",
      },

    };
  },
  watch: {
    milestone(nv){
      if (nv && nv.sprints) {
        this.sprint_id =  null;
        this.sprints = nv.sprints.length ? nv.sprints : [];
      }
    }
  },
  directives: {
    "b-modal": VBModal,
  },
  mounted(){
    this.getProjectPlanLists();
  },
  methods: {
    async getProjectPlanLists() {
      let input = {
        project_id: this.projectDtl.id,
        sprintList: true
      };
      let response = await this.getHTTPPostResponse('project/plan/lists', input, false);
      if (response && response.data) {
        let data = response.data.project_plans && response.data.project_plans.length ? response.data.project_plans : [];
        let resNew = [];
        data.forEach(element => {
          if (element && element.status != 'complete') {
            resNew.push(element)
          }
        });
        this.milestones = resNew;
      //   // console.log('data')
      //   // console.log(data.project_plans)
      //  this.items = data.project_plans;        
      //   this.totalCount = data.count;
      }
      this.loading = false;
    },
    validationForm() {
      this.$refs.sprintStartRules.validate().then((success) => {
        if (success) {
          this.startNewSprint()
        }
      });
    },
    /**
     * start New Sprint
     */
    async startNewSprint() {
        let input = {
          milestone_id: this.milestone.id,
          sprint_id: this.sprint_id,
          description: this.description,
          // actual_start_date: this.actualStartDate,
          // actual_end_date: this.actualEndDate,
          project_id: this.projectDtl.id
        };
       
        let response = await this.getHTTPPostResponse( "project/plan/sprint-start",input,true);
        if (response && response.status == 200) {
            this.clearForm();
            this.$root.$emit("bv::toggle::modal", this.id);
            eventBus.$emit("reloadProjectDtl", true);
        }
    },
    clearForm(){
      this.milestone =  null;
      this.sprint_id =  null;
      this.description =  null;
      this.actualStartDate =  null;
      this.actualEndDate =  null;
      this.$refs.sprintStartRules.reset();
    }

  }
};
</script>

<style>
@import "../../../assets/scss/component-css/list.css";
</style>