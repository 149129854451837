<template>
  <div
    v-show="parentChildSuiteData.length && $store.state.app.projectSuiteList && $store.state.app.projectSuiteList.length"
    :class="$store.state.appConfig.layout.type == 'full' ?'left-side-full-screen' : 'left-side'"
  >
    <p class="title">
      <span><svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M3.42857 5H10.5714C10.9503 5 11.3137 5.15051 11.5816 5.41842C11.8495 5.68633 12 6.04969 12 6.42857C12 6.80745 11.8495 7.17081 11.5816 7.43872C11.3137 7.70663 10.9503 7.85714 10.5714 7.85714H3.42857C3.04969 7.85714 2.68633 7.70663 2.41842 7.43872C2.15051 7.17081 2 6.80745 2 6.42857C2 6.04969 2.15051 5.68633 2.41842 5.41842C2.68633 5.15051 3.04969 5 3.42857 5ZM13.4286 16.4286H20.5714C20.9503 16.4286 21.3137 16.5791 21.5816 16.847C21.8495 17.1149 22 17.4783 22 17.8571C22 18.236 21.8495 18.5994 21.5816 18.8673C21.3137 19.1352 20.9503 19.2857 20.5714 19.2857H13.4286C13.0497 19.2857 12.6863 19.1352 12.4184 18.8673C12.1505 18.5994 12 18.236 12 17.8571C12 17.4783 12.1505 17.1149 12.4184 16.847C12.6863 16.5791 13.0497 16.4286 13.4286 16.4286ZM3.42857 10.7143H20.5714C20.9503 10.7143 21.3137 10.8648 21.5816 11.1327C21.8495 11.4006 22 11.764 22 12.1429C22 12.5217 21.8495 12.8851 21.5816 13.153C21.3137 13.4209 20.9503 13.5714 20.5714 13.5714H3.42857C3.04969 13.5714 2.68633 13.4209 2.41842 13.153C2.15051 12.8851 2 12.5217 2 12.1429C2 11.764 2.15051 11.4006 2.41842 11.1327C2.68633 10.8648 3.04969 10.7143 3.42857 10.7143Z"
          fill="#2178FB"
        /></svg></span>Suites
    </p>
    <div class="suits-list">
      <div>
        <app-collapse accordion>
          <NestedItem
            v-for="item in parentChildSuiteData"
            :key="item.id"
            :suite-data="item"
          />
        </app-collapse>
      </div>
    </div>
  </div>
</template>
<script>
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import NestedItem from './NestedItem.vue'
import { eventBus } from '../../../main'

export default {
  name: 'TestingSidebar',
  components: {
    AppCollapse,
    AppCollapseItem,
    NestedItem,
  },
  data() {
    return {
      suiteList: [],
      search: null,
    }
  },
  computed: {
    parentSuiteList() {
      const parentSuiteList = this.suiteList.filter(
        record => record.parent_id === null,
      )
      return parentSuiteList
    },
    parentChildSuiteData() {
      const parentChildsuiteList = this.convertToParentChildData(this.suiteList)
      this.$store.commit('app/UPDATE_PROJECT_SUITE_LIST', parentChildsuiteList)
      return parentChildsuiteList
    },
  },
  mounted() {
    this.getSuiteList()
    eventBus.$on('reloadSuiteList', data => {
      if (data) {
        this.search = data[1]
        this.getSuiteList()
      }
    })

    eventBus.$on('callTestSuiteApi', data => {
      if (data) {
        this.getSuiteList()
      }
    })
  },
  destroyed() {
    eventBus.$off('reloadSuiteList')
    eventBus.$off('callTestSuiteApi')
  },
  methods: {
    async getSuiteList() {
      this.$store.state.app.testSuite.loader = true
      const input = {
        project_id: this.$route.params.id3 ? this.$route.params.id3 : null,
        search: this.search,
      }
      const response = await this.getHTTPPostResponse(
        'project/test-suite',
        input,
        false,
      )

      if (response && response.status == 200) {
        const data = response.data && response.data.test_suite
          ? response.data.test_suite
          : []
        this.suiteList = data
        this.suiteList.forEach(element => {
          element.test_cases.sort((a, b) => {
            const aValue = a.code.split('-')
            const aNum = aValue && aValue.length && aValue[1] ? Number(aValue[1]) : 0
            const bValue = b.code.split('-')
            const bNum = bValue && bValue.length && bValue[1] ? Number(bValue[1]) : 0
            return aNum - bNum
          })
        })
        this.$store.commit('app/UPDATE_SUITE_LIST', data)
      }
      this.$store.state.app.testSuite.loader = false
    },

    // /**
    //  *
    //  * @param {*} suiteList
    //  * set flatData into parent child pair
    //  */
    // convertToParentChildData(suiteList) {
    //   const parentChildPair = [];
    //   const idToObjectMap = {};

    //   suiteList.forEach((item) => {
    //     idToObjectMap[item.id] = item;
    //     item.children = [];
    //   });

    //   suiteList.forEach((item) => {
    //     if (item.parent_id !== null) {
    //       const parent = idToObjectMap[item.parent_id];
    //       if (parent) {
    //         parent.children.push(item);
    //       }
    //     } else {
    //       parentChildPair.push(item);
    //     }
    //   });
    //   this.$store.commit("app/UPDATE_PROJECT_SUITE_LIST", parentChildPair);
    //   return parentChildPair;
    // },
  },
}
</script>
<style lang="scss" scoped>
@import "../../../assets/scss/component-css/tesing.css";
</style>
