<template>
  <div>    
    <div class="noteCardinfo">        
        <div class="noteHeader pt-5"></div>
        <div class="noteBody" v-b-modal.notepopup variant="outline-primary" v-ripple.400="'rgba(113, 102, 240, 0.15)'">
          <h2><text-shimmer class="mb-25"></text-shimmer></h2>
          <p><skeleton-loader-vue type="ract" :height="20" animation="wave" class="notecard-width mb-25"/>
            <skeleton-loader-vue type="ract" :height="20" animation="wave" class="notecard-width  mb-25"/>
            <skeleton-loader-vue type="ract" :height="20" animation="wave" class="notecard-width mb-25"/>
            <skeleton-loader-vue type="ract" :height="20" animation="wave" class="notecard-width mb-25"/>
            <skeleton-loader-vue type="ract" :height="20" animation="wave" class="notecard-width mb-1"/></p>
            <a href="#"><text-shimmer class="mb-25"></text-shimmer></a>
        </div>
        <div class="noteFooter d-flex justify-content-between align-items-center">
          <div class="notDropdown">
            <skeleton-loader-vue
              type="circle"
              :width="30"
              :height="30"
              animation="wave"
            />           
          </div>
          <div class="d-flex align-items-center">
            <div class="">
              <skeleton-loader-vue
              type="circle"
              :width="30"
              :height="30"
              animation="wave"
            />
            </div>
            <div class="noteuserActivity">
              <span><skeleton-loader-vue type="ract" :width="100" :height="20" animation="wave" class="mb-25"/>
              <skeleton-loader-vue type="ract" :height="20" animation="wave" class="notecard-width"/></span>
            </div>
          </div>
        </div>
      </div>   
  </div>
</template>

<script>
import TextShimmer from '../../views/shimmer/TextShimmer.vue'
import LongTextShimmer from '../../views/shimmer/LongTextShimmer.vue'
import SkeletonLoaderVue from "skeleton-loader-vue";
export default {
    name: 'NotecardShimmer',
    components:{
      TextShimmer,
      LongTextShimmer,
      SkeletonLoaderVue,
    }
}
</script>

<style>
.notecard-width {
  max-width: 100%;
  min-width: 100%;
  width: 100%;
}
</style>